<div style="display: flex;flex-direction: column;gap: 40px;padding: 24px 32px;">
  <div style="display: flex;flex-direction: column;gap: 32px">
    <span class="sp-audience-fb-score-title">Scale of Audience Size Indicator</span>

    <div style="display: flex;flex-direction: column;gap: 8px;align-items: center">
      <span class="sp-audience-fb-score-subtitle">Very bad</span>
      <div class="sp-audience-fb-score-very-bad">
        <div>🥶 The audience size is <10k</div>
        <div>🥵 The audience size is more than 60%</div>
      </div>
      <span class="sp-audience-fb-score-subtitle">Bad</span>
      <div class="sp-audience-fb-score-bad">
        <div>😨 The audience size is >10k and <150k</div>
        <div>😥 The audience size is >40% and <60%</div>
      </div>
      <span class="sp-audience-fb-score-subtitle">Good</span>
      <div class="sp-audience-fb-score-good">
        <div>🙂 The audience size is >50k & <100k</div>
      </div>
      <span class="sp-audience-fb-score-subtitle">Perfect</span>
      <div class="sp-audience-fb-score-perfect">
        <div>😎 The audience size is >100k and <40%</div>
      </div>

      <span class="material-symbols-outlined" style="color: red">
        warning
      </span>
      <span class="text-center" style="font-size: 12px">
        If your audience size can fit into two categories, the percentage rule will decide which color of the indicator you see.<br>
        For example, if you have an audience size of 75,000 and that’s also 75% of your baseline audience, it’ll show up in the “Red” category.<br>
        <a target="_blank" href="https://intercom.help/soprism/en/articles/9717004-how-does-the-audience-size-indicator-work-when-creating-a-project">Read more</a>

      </span>
    </div>
  </div>
  <div *ngIf="!showCloseButton" class="text-center">
    <button (click)="dialogRef.close(true)" class="btn btn-secondary m-1 text-uppercase sp-btn sp-btn-secondary" mat-button>Close</button>
  </div>
</div>
