import { Component, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { SpThemingService } from '../../../../services/sp-theming/sp-theming.service';
import {Company} from "../../../../models/company";
import {ApiCompanyService} from "../../../../services/sp-api/sp-api-company/api-company.service";
import {AuthenticationService} from "../../../../services/sp-authentication/authentication.service";
import {MatSort} from '@angular/material/sort';
import {MatDialog} from '@angular/material/dialog';
import {SpModalCompanyRemoveComponent} from './sp-modal-company-remove/sp-modal-company-remove.component';
import {environment} from '../../../../../environments/environment';
import {Router} from "@angular/router";
import {LoaderService} from '../../../../services/sp-loading/loader.service';

@Component({
  selector: 'app-sp-admin-company-list',
  templateUrl: './sp-admin-company-list.component.html',
  styleUrls: ['./sp-admin-company-list.component.scss']
})
export class SpAdminCompanyListComponent implements OnInit {
  displayedColumns: string[] = ['image', 'name', 'userCount', 'payment_frequency', 'planName', 'credits', 'subscription_contract_start_at','subscription_contract_end_at','credits_left','actions'];
  dataSource = new MatTableDataSource<Company>([]);
  env = environment;

  @ViewChild(MatSort, {static: true}) sort: MatSort;
  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  // Constructor.
  constructor(
    private _spTheming: SpThemingService,
    private apiCompany: ApiCompanyService,
    private auth: AuthenticationService,
    private dialog: MatDialog,
    private router: Router,
    private loader: LoaderService
  ) {
    // Set the theme to use.
    this._spTheming.setSpThemeName('dashboard-desk');
  }

  // On init.
  ngOnInit() {
    this.loader.load();

    // Filter and paginator for company
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;

    this.apiCompany.getAll().then((companies: Company[]) => {
      this.loader.loaded();
      this.dataSource.data = companies
    });
  }

 openDeleteDialog(company: Company): void {
     const dialogRef = this.dialog.open(SpModalCompanyRemoveComponent,{autoFocus: false,
       maxHeight:'30rem',maxWidth:'40rem', data: {
         company
       }
     });

     dialogRef.afterClosed().subscribe(company => {
       if(company !== null) {
        let companies: Company[] = this.dataSource.data;
        companies = companies.filter(c => c.id !== company.id);
         this.dataSource.data = companies;
       }
     });
   }

  companyHasSubscription(company: Company): boolean {
    return company.subscription !== null;
  }

  companyHasPlan(company: Company): boolean {
    return company.subscription !== null && company.subscription.plan !== null;
  }

  navigateToCompany(company: Company) {
    this.router.navigateByUrl('admin/companies/update/' + company.id);
  }
}
