import {Role} from "./role";
import {Permission} from "./permission";
import {AdAccount} from "./ad-account";
import {Company} from "./company";
import {Project} from "./project";
import {HasPermission} from './has-permission';

export class User implements HasPermission {
  id: number;
  email: string;
  first_name: string;
  last_name: string;
  locale: string;
  password: string;
  image: string;
  role: Role;
  activated: boolean;
  permissions: Array<Permission>;
  ad_accounts: Array<AdAccount>;
  projects: Array<Project>;
  fb_picture: boolean;
  company: Company;
  companyName: string;
  credits_used: number;
  login_at: Date;
  blocked: string;
  first_connection: boolean;
  must_refresh_token: boolean;
  intercom_hash: string;
  first_project_created: boolean;
  created_at: Date;
  updated_at: Date;
  isTemporarySelected: boolean;

  constructor(email: string, first_name: string, last_name: string) {
    this.email = email;
    this.first_name = first_name;
    this.last_name = last_name;
  }
}
