<div class="card pl-3 pr-3">
  <div class="card-body text-center">
    <h4>You will be debited with 1 credit, are you sure you want to continue ?</h4>
    <h6>After that, there will be {{ creditsLeft }} credits {{ showCreditExtra ? ('and ' + creditsExtraLeft + ' extra credits') : '' }} left</h6>
    <div class="row no-gutters d-flex pt-4">
      <div class="col-12 text-center">
        <button type="button" class="btn btn-secondary sp-btn sp-btn-secondary" (click)="onNoClick()" routerLinkActive="active" matTooltip="Cancel this operation" matTooltipHideDelay="10">
          <span>Cancel</span>
        </button>
        <button (click)="dialogRef.close(true)" class="ml-3 btn btn-primary sp-btn sp-btn-primary" matTooltip="Confirm this operation" matTooltipHideDelay="10">
          <span>Confirm</span>
        </button>
      </div>
    </div>
  </div>
</div>
