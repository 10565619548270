import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {Targeting} from "../../../../classes/targeting/targeting";
import {ApiCompanyService} from "../../../../services/sp-api/sp-api-company/api-company.service";
import {Subscription} from "../../../../models/subscription";
import {AuthenticationService} from "../../../../services/sp-authentication/authentication.service";
import {Project} from "../../../../models/project";
import {ApiProjectService} from "../../../../services/sp-api/sp-api-project/api-project.service";
import {Router} from "@angular/router";

@Component({
  selector: 'app-sp-modal-project-create-facebook-import',
  templateUrl: './sp-modal-project-credits-add.component.html',
  styleUrls: ['./sp-modal-project-credits-add.component.scss']
})
export class SpModalProjectCreditsAddComponent implements OnInit {
  creditsLeft: number = 0;
  creditsExtraLeft: number = 0;
  showCreditExtra: boolean = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: Project,
    public dialogRef: MatDialogRef<SpModalProjectCreditsAddComponent>,
    public auth: AuthenticationService,) {
    const credits = auth.session.user.company.subscription.credits;
    const creditsExtra = auth.session.user.company.subscription.credits_extra;
    this.creditsLeft = Math.max(credits - 1, 0);
    this.creditsExtraLeft = credits == 0 ? (creditsExtra - 1) : (creditsExtra);
    this.showCreditExtra = creditsExtra > 0;
  }

  ngOnInit() {

  }
  onNoClick(): void {
    this.dialogRef.close(null);
  }

}
