import {Injectable} from '@angular/core';
import {StatItem} from '../../models/stats/stat-item';
import * as Highcharts from 'highcharts';
import {Chart} from 'highcharts';
import proj4 from 'proj4';
import {MapDataHandler} from '../../classes/data/map-data-handler';
import HC_more from 'highcharts/highcharts-more';
import HC_exporting from 'highcharts/modules/exporting';
import HCSoldGauge from 'highcharts/modules/solid-gauge';
import {environment} from '../../../environments/environment';
import {TagManagerService} from '../sp-tag-manager/tag-manager.service';
import HC_sunburst from 'highcharts/modules/sunburst';
import Boost from 'highcharts/modules/boost';
import Drilldown from 'highcharts/modules/drilldown';
import {LoggerService} from '../sp-logger/logger.service';
import {PathEntry} from '../../classes/data/path-entry';
import {TreeNode} from '../../classes/data/tree-node';
import {MetricType} from '../../classes/reporting/metric-type';

HC_sunburst(Highcharts);
HC_more(Highcharts);
HC_exporting(Highcharts);
Drilldown(Highcharts);
HCSoldGauge(Highcharts);
(function(Highcharts: any) {
  Highcharts.getSVG = function (charts) {
    var svgArr = [],
      top = 0,
      width = 0;

    Highcharts.each(charts, function (chart) {
      var svg = chart.getSVG(),
        // Get width/height of SVG for export
        svgWidth = +svg.match(
          /^<svg[^>]*width\s*=\s*\"?(\d+)\"?[^>]*>/
        )[1],
        svgHeight = +svg.match(
          /^<svg[^>]*height\s*=\s*\"?(\d+)\"?[^>]*>/
        )[1];

      svg = svg.replace(
        '<svg',
        '<g transform="translate(' + width + ')" '
      );
      svg = svg.replace('</svg>', '</g>');

      width += svgWidth;
      top = Math.max(top, svgHeight);
      svgArr.push(svg);
    });

    return '<svg height="' + top + '" width="' + width +
      '" version="1.1" xmlns="http://www.w3.org/2000/svg">' +
      svgArr.join('') + '</svg>';
  };

  /**
   * Create a global exportCharts method that takes an array of charts as an
   * argument, and exporting options as the second argument
   */
  Highcharts.exportCharts = function (charts, options?: any) {

    charts.forEach(function(chart) {
      const chartWidth = chart.chartWidth;
      const chartHeight = chart.chartHeight;
      chart.update({
        chart: {
          width:  chartWidth * 2,
          height:  chartHeight,
          image: chart.image
        }
      });
    });

    // Merge the options
    options = Highcharts.merge(Highcharts.getOptions().exporting, options);

    // Post to export server
    Highcharts.post(environment.config.chartExportUrl, {
      filename: options.filename || 'chart',
      type: options.type,
      width: options.width,
      svg: Highcharts.getSVG(charts)
    });
    charts.forEach(function(chart) {
      const chartWidth = chart.chartWidth;
      const chartHeight = chart.chartHeight;
      chart.update({
        chart: {
          width:  chartWidth / 2,
          height:  chartHeight,
          image: chart.image,
        }
      });
    });
  };
})(Highcharts);
(function(H: any) {
  H.Series.prototype.sunburstSelect = function(ids: number[]) {
    if (ids.length > 0) {
      const filteredData = this.data.find(d => {
        const pathIds = d.pathIds;
        const commonIds = pathIds.filter(id => ids.includes(id));
        return commonIds.length == ids.length;
      });
      if (filteredData) {
        //filteredData.select();
        this.chart.series[0].setRootNode(filteredData.id, true, {trigger: 'click'});
        /*const sunburstPath = filteredData.sunburstPath;
        this.data.filter(d => sunburstPath.includes(d.id)).forEach(d => d.update({selected: true}));*/
      }
    } else {
      this.chart.series[0].setRootNode('', true, {trigger: 'click'});
    }
  }
}(Highcharts));

Boost(Highcharts);
export enum SocioChartType {
  GENDER_MALE, GENDER_FEMALE, LOCALE, PARENTS,
  RELATION_SINGLE, RELATION_IN_RELATION, RELATION_UNSPECIFIED
}
interface Behavior {}
class BehaviorDoubleChart implements Behavior {


  imageUrl: string;

  imageWidth: number = 70;
  imageHeight: number = 30;
  imageModifier: number = 1;

  yAxeInModifier: number = 1;
  yAxeOutModifier: number = 1.5;

  xImageCalcOffset: number = 0;
  xImageModifier: number = 1;
  yImagePosition: number = 0;

  innerSize: number = 100;
  size: number = 110;

  noTitle: boolean = false;
}
export class BehaviorSmallPieChart implements Behavior {
  imageUrl: string;

  width: number;
  height: number;
}
export enum ScssVariables {
  Primary = "primary",
  Primarylight = "primarylight",
}

export enum MapLevel {
  LEVEL_1 = 1, LEVEL_2 = 2, LEVEL_3 = 3, LEVEL_4 = 4
}
export class MapGeoData {
  children: any;
  private data: Map<MapLevel, { level: any, levelName: string }> = new Map();
  maxLevel: number;
  version: number;

  constructor(children: any, maxLevel: number, version: number) {
    this.children = children;
    this.maxLevel = maxLevel;
    this.version = version;
  }
  setLevel(level: MapLevel, data: any) {
    const levelName = data.level_name ? data.level_name : "Level " + level.valueOf();
    this.data.set(level, {level: data, levelName});
  }

  /**
   * Building children from provided level until last level
   * Works only on map version 2, version 1 returns an error message
   * Final content is put in children attribute of this instance of an object
   * @param level Level to start from
   */
  buildChildrenFromLevel(level: MapLevel) {
    if (this.version < 2) console.warn("[MAP DRILLDOWN WARNING] Children building is only available since map version 2, use pregen children instead");
    else {
      const nextLevel = level + 1;
      if (this.hasLevel(level) && this.hasLevel(nextLevel)) {
        const currentLevel = this.getLevel(level);
        const nextLevelData = this.getLevel(nextLevel);
        currentLevel.features.forEach(f => {
          const id = f.properties['osm-relation-id'];
          const nextLevelDrilldown = nextLevelData.features.filter(f => {
            return f.properties['osm-parent-id'] == id;
          });

          if (nextLevelDrilldown.length > 0) {
            if (!this.children) this.children = {};
            this.children[id] = {type: "FeatureCollection", features: nextLevelDrilldown};
          }
        });
      }
      if (this.hasLevel(nextLevel)) this.buildChildrenFromLevel(nextLevel);
    }
  }
  getLevelName(level: MapLevel) {
    if (this.version >= 2) {
      const lvl = this.data.get(level);
      return lvl ? lvl.levelName : 'Level unavailable';
    }
    else {
        if (level == 1) return "Regions";
        if (level == 2) return "Provinces";
        if (level == 3) return "Districts";
        if (level == 4) return "Cities";
    }
  }
  getLevel(level: MapLevel) { return this.data.get(level).level }
  hasLevel(level: MapLevel) { return this.data.has(level) }
}

@Injectable({
  providedIn: 'root'
})

export class ChartService {
  public styles: { [key in ScssVariables]: string | null } = {
    [ScssVariables.Primary]: null,
    [ScssVariables.Primarylight]: null,
  };

  public static readonly GEO_MERCATOR: string = 'EPSG:3857';
  public static readonly GEO_DEFAULT_OSM: string = 'WGS84';

  constructor(private logger: LoggerService) {
    const styles = window.getComputedStyle(document.body);
    Object.keys(this.styles).forEach((key: ScssVariables) => {
      this.styles[key] = styles.getPropertyValue("--bs-" + key);
    })
  }

  private static loadDoubleChartBehavior(type: SocioChartType): BehaviorDoubleChart {
    if (type == SocioChartType.GENDER_MALE) {
      const behavior = new BehaviorDoubleChart();
      behavior.imageUrl = 'assets/images/socio-demo/genderMale.png';
      behavior.imageWidth = 22;
      behavior.imageHeight = 70;

      behavior.xImageModifier = 0.9;
      behavior.yImagePosition = 50;

      behavior.yAxeInModifier = 1.2;
      behavior.yAxeOutModifier = 1.7;

      behavior.innerSize = 100;
      behavior.size = 110;

      return behavior;
    } else if (type == SocioChartType.GENDER_FEMALE) {
      const behavior = new BehaviorDoubleChart();
      behavior.imageUrl = 'assets/images/socio-demo/genderFemale.png';
      behavior.imageWidth = 30;
      behavior.imageHeight = 70;

      behavior.xImageModifier = 0.8;
      behavior.yImagePosition = 50;

      behavior.yAxeInModifier = 1.2;
      behavior.yAxeOutModifier = 1.7;

      behavior.innerSize = 100;
      behavior.size = 110;

      return behavior;
    } else if (type == SocioChartType.LOCALE) {
      const behavior = new BehaviorDoubleChart();
      behavior.yAxeInModifier = 1.05;
      behavior.yAxeOutModifier = 1.7;

      behavior.innerSize = 90;
      behavior.size = 100;

      return behavior;
    } else if (type == SocioChartType.PARENTS) {
      const behavior = new BehaviorDoubleChart();
      behavior.imageUrl = 'assets/images/socio-demo/parents.png';
      behavior.imageWidth = 80;
      behavior.imageHeight = 120;

      behavior.xImageModifier = 0.8;
      behavior.yImagePosition = 70;

      behavior.xImageCalcOffset = 0.5;

      behavior.yAxeInModifier = 1.4;
      behavior.yAxeOutModifier = 1.7;

      behavior.innerSize = 160;
      behavior.size = 170;

      return behavior;
    } else if (type == SocioChartType.RELATION_SINGLE) {
      const behavior = new BehaviorDoubleChart();
      behavior.imageUrl = 'assets/images/socio-demo/single.png';
      behavior.imageWidth = 50;
      behavior.imageHeight = 120;

      behavior.xImageCalcOffset = 0.5;
      behavior.xImageModifier = 0.8;
      behavior.yImagePosition = 40;

      behavior.yAxeInModifier = 1.2;
      behavior.yAxeOutModifier = 1.7;

      behavior.innerSize = 150;
      behavior.size = 160;

      return behavior;
    } else if (type == SocioChartType.RELATION_IN_RELATION) {
      const behavior = new BehaviorDoubleChart();
      behavior.imageUrl = 'assets/images/socio-demo/inRelation.png';
      behavior.imageWidth = 80;
      behavior.imageHeight = 120;

      behavior.xImageCalcOffset = 0.5;
      behavior.xImageModifier = 0.7;
      behavior.yImagePosition = 40;

      behavior.yAxeInModifier = 1.2;
      behavior.yAxeOutModifier = 1.7;

      behavior.innerSize = 150;
      behavior.size = 160;

      return behavior;
    } else if (type == SocioChartType.RELATION_UNSPECIFIED) {
      const behavior = new BehaviorDoubleChart();
      behavior.imageUrl = 'assets/images/socio-demo/unspecified.png';
      behavior.imageWidth = 40;
      behavior.imageHeight = 120;

      behavior.xImageCalcOffset = 0.5;
      behavior.xImageModifier = 0.8;
      behavior.yImagePosition = 40;

      behavior.yAxeInModifier = 1.2;
      behavior.yAxeOutModifier = 1.7;

      behavior.innerSize = 150;
      behavior.size = 160;

      return behavior;
    }
  }
  private static generateTooltip(name: string, percent1: number, size1: number, percent2: number, size2: number, selectivity: number, score:number): string {
    let content = "";
    if (name) content += " <span> <strong>" + name + "</strong></span><br/>";
    if (percent1 && size1) content += "<span style='color:" + '#8AC192' + ";'>\u25CF</span> Target size: <strong>" + percent1 + "% - " + ChartService.separator(size1) + " <i class=\"icon-sp-tooltip-user\" > </i></strong><br/>";
    if (percent2 && size2) content += "<span style='color:" + '#6BC9E4' + ";'>\u25CF</span> Benchmark size: <strong>" + percent2 + "% - " + ChartService.separator(size2) + " <i class=\"icon-sp-tooltip-user\" > </i></strong><br/>";
    if (selectivity) content += "<span style='z-index: 9999;color:" + '#000000' + ";'>\u25CF</span> Selectivity: <strong>" + selectivity + "</strong><br/>";
    if (score) content += "<span style='color:" + '#000000' + ";'>\u25CF</span> Score: <strong>" + score + "</strong>";
    return content !== "" ? content : "Data is missing";
  }

  createSpiderwebChart(items: StatItem[], metrics: MetricType[] = [MetricType.SELECTIVITY], title?: string, subtitle?: string): any {
    const metr = metrics;
    const data = metrics.map(m => {
      return {
        name: "",
        type: 'area',
        fillOpacity: 0.3,
        data: items.map(i => {
          switch (m) {
            case MetricType.SCORE:
              return i.score;
            case MetricType.SELECTIVITY:
              return ChartService.round(i.selectivity * 100);
            case MetricType.TARGET_PEN:
              return ChartService.round(i.percent1 * 100);
            case MetricType.BENCHMARK_PEN:
              return ChartService.round(i.percent2 * 100);
            case MetricType.TARGET_SIZE:
              return i.size1;
            case MetricType.BENCHMARK_SIZE:
              return i.size2;
          }
        }),
        pointPlacement: "on"
      };
    });

    return {
      chart: {
        polar: true,
        style: {
          fontFamily: "Roboto Condensed",
          fontWeight: 300,
        },
        events: {
          load: function() {
            metrics.map( m => {
              switch (m) {
                case MetricType.SCORE:
                  this.update({
                    colors: [
                      "#8AC192",
                      "#6BC9E4",
                    ],
                    yAxis: {
                      max : 100,
                      tickInterval: 20,
                    },
                  })
                  break;
                case MetricType.SELECTIVITY:
                  this.update({
                    colors: [
                      "#8AC192",
                      "#6BC9E4",
                    ],
                  })
                break;
                case MetricType.TARGET_SIZE:
                  this.update({
                    colors: [
                      "#8AC192",
                      "#6BC9E4",
                    ],
                  })
                  break;
                case MetricType.TARGET_PEN:
                  this.update({
                    colors: [
                      "#8AC192",
                      "#6BC9E4",
                    ],
                  })
                  break;
                case MetricType.BENCHMARK_SIZE:
                  this.update({
                    colors: [
                      "#6BC9E4",
                      "#8AC192",
                    ],
                  })
                  break;
                case MetricType.BENCHMARK_PEN:
                  this.update({
                    colors: [
                      "#6BC9E4",
                      "#8AC192",
                    ],
                  })
                  break;
              }
            })
          }
        },
      },

      title: {
        text: title,
        align: 'left',
        margin: 30,
        style: {
          fontSize: '1.2rem',
          fontFamily: 'Roboto Condensed',
          fontWeight: 400,
          color: '#484848'
        }
      },
      subtitle: {
        text: subtitle,
        align: 'left',
        margin: 10,
        style: {

          fontFamily: "Roboto Condensed",
          fontSize: '0.9rem',
          fontWeight: 200,
          color: '#8E8E8E'
        }
      },
      pane: {
        size: '80%'
      },
      xAxis: {
        categories: items.map(i => i.name),
        tickmarkPlacement: 'on',
        lineWidth: 0
      },

      yAxis: {
        gridLineInterpolation: 'polygon',
        lineWidth: 0,
        min: 0,
      },

      tooltip: {
        shared: true,
        pointFormat: '<span style="color:{series.color}">{series.name}: <b>{point.y:,.0f}</b><br/>'
      },
      exporting: {
        enabled: false,
      },
      legend: {
        enabled: false,
      },
      credits: {
        enabled: false
      },
      series: data,
      responsive: {
        rules: [{
          condition: {
            maxWidth: 500
          },
          chartOptions: {
            legend: {
              align: 'center',
              verticalAlign: 'bottom',
              layout: 'horizontal'
            },
            pane: {
              size: '70%'
            }
          }
        }]
      }
    };
  }
  createSmallPieChart(item: StatItem, behavior: BehaviorSmallPieChart, title?: string): any {
    const tooltip = document.createElement('div');
    const selectivity = ChartService.round(item.selectivity * 100);

    tooltip.classList.add('sp-tooltip');
    tooltip.innerText = "Selectivity : " + selectivity;
    tooltip.hidden = true;

    document.body.appendChild(tooltip);

    return {
      chart: {
        style: {
          fontFamily: "Roboto Condensed",
          fontWeight: 300,
        },
        type: 'pie',
        events: {
          load: function() {
            const x = (this.plotWidth / 2 + this.plotLeft) - behavior.width / 2;
            const y = (this.plotHeight / 2 + this.plotTop) - behavior.height / 2;

            const newXOutside = this.plotWidth / 2 + this.plotLeft,
              newYOutside = this.plotHeight / 2 + this.plotTop;

            this.customImgTextOutside = this.renderer.text(selectivity, newXOutside, newYOutside * 2)
              .attr({
                align: 'center'
              }).on('mousemove', function(e) {
                const x = e.pageX;
                const y = e.pageY;

                tooltip.style.left = (x + 10) + 'px';
                tooltip.style.top = (y + 10) + 'px';

                if (tooltip.hidden) tooltip.hidden = false;
              }).on('mouseout', function() {
                tooltip.hidden = true;
              })
              .css({
                fontSize: '12pt',
                fontFamily: 'Roboto Condensed',
              }).add();

              if(this.customImg) {
                this.customImg.destroy();
              }

            this.customImg = this.renderer.image(behavior.imageUrl, x, y, behavior.width, behavior.height)
              .css({
              })
              .add();
          },
          redraw: function() {
            const x = (this.plotWidth / 2 + this.plotLeft) - behavior.width / 2;
            const y = (this.plotHeight / 2 + this.plotTop) - behavior.height / 2;

            const newXOutside = this.plotWidth / 2 + this.plotLeft,
              newYOutside = this.plotHeight / 2 + this.plotTop;

            const textOut = selectivity;
            this.customImgTextOutside.destroy();
            this.customImgTextOutside = this.renderer.text(textOut, newXOutside, newYOutside * 2)
              .attr({
                align: 'center'
              }).on('mousemove', function(e) {
                const x = e.pageX;
                const y = e.pageY;

                tooltip.style.left = (x + 10) + 'px';
                tooltip.style.top = (y + 10) + 'px';

                if (tooltip.hidden) tooltip.hidden = false;
              }).on('mouseout', function() {
                tooltip.hidden = true;
              })
              .css({
                fontSize: '12pt',
                fontFamily: 'Roboto Condensed',
              }).add();

            if(this.customImg) {
              this.customImg.destroy();
            }

            this.customImg = this.renderer.image(behavior.imageUrl, x, y, behavior.width, behavior.height)
              .css({
              })
              .add();

          },
        },
        backgroundColor: 'rgba(0,0,0,0)',
        y: 100,
      },
      exporting: {
        enabled: false,
      },
      boost: { enabled: true },
      title: {
        text: title
      },
      yAxis: {
        title: {
          text: ' '
        }
      },
      credits: {
        enabled: false
      },

      colors: [
        "#6BC9E4",
        "#8AC192",
      ],

      plotOptions: {
        pie: {
          //                        y:1,
          shadow: false,
          //                        center: ['50%', '50%'],
          borderWidth: 0,
          showInLegend: false,
          size: '80%',
          innerSize: '70%',
          data: [
            ['Benchmark', ChartService.round(item.percent2 * 100)],
            ['Target', ChartService.round(item.percent1 * 100)],
          ]
        }
      },
      tooltip: {
        valueSuffix: '%',
        outside: true,
        shared: true,
        split: false,
        enabled: true,
        style: {
          width: 210
        },

        formatter: function() {
          let content = "";
          content += "<strong>" + item.name +"</strong><br/>";
          content += "<span style='color:" + '#8AC192' + ";'>\u25CF</span> " + "Target size: " +" <strong>" + ChartService.round(item.percent1 * 100) + "%" + " - " + ChartService.separator(item.size1) + "<i class='icon-sp-tooltip-user' > </i> </strong><br/>";
          content += "<span style='color:" + '#6BC9E4' + ";'>\u25CF</span> " + "Benchmark size:" + " <strong>" + ChartService.round(item.percent2 * 100) + "%" + " - " + ChartService.separator(item.size2) + "<i class='icon-sp-tooltip-user' > </i></strong><br/>";

          return content !== "" ? content : "Data is missing";
        },
        useHTML: true
      },
      series: [{
        type: 'pie',
        name: 'Target',

        dataLabels: {
          enabled: false,
          color: 'white',
          connectorColor: 'grey',
          connectorWidth: 1,
          zIndex: 2,
          distance: -100,
          formatter: function() {
            if (this.percentage >= 20) return Math.round(this.percentage) + '%';

          }
        }
      }, {
        type: 'pie',
        name: '',

        dataLabels: {
          enabled: false,
          connectorColor: 'grey',
          color: 'black',
          //                            y:-10,
          softConnector: true,
          connectorWidth: 1,
          verticalAlign: 'top',
          distance: 20,
          formatter: function() {
            if (this.percentage != 0) return this.point.name;

          }
        }
      }]
    };
  }
  createDualPieChart(a: StatItem, b: StatItem, title?: string): any {
    const primaryColor = this.styles.primary;
    const primaryLightColor = this.styles.primarylight;
    return {
      chart: {
        style: {
          fontFamily: "Roboto Condensed",
          fontWeight: 300,
        },
        type: 'pie',
        backgroundColor: 'rgba(0,0,0,0)',
        y: 100,

        events: {
          load: function() {
              if (ChartService.round(b.percent1 * 100) > ChartService.round(a.percent1 * 100)) {
                this.update({
                  colors: [
                    primaryColor,
                    primaryLightColor,
                  ],
                });
              }
                if (ChartService.round(b.percent1 * 100) < ChartService.round(a.percent1 * 100)) {
                  this.update({
                    colors: [
                      primaryColor,
                      primaryLightColor,
                    ],
                  });
              }
            if (ChartService.round(b.percent1 * 100) === ChartService.round(a.percent1 * 100)) {
              this.update({
                colors: [
                  primaryColor,
                  primaryLightColor,
                ],
              });
            }
          }
        }
      },
      title: {
        text: title
      },
      yAxis: {
        title: {
          text: ' '
        }
      },
      credits: {
        enabled: false
      },
      exporting: {
        enabled: false
      },
      tooltip: {
        valueSuffix: '%',
        outside: true,
        shared: true,
        split: false,
        enabled: true,
        style: {
          width: 200,
            fontFamily: "Roboto Condensed",
            fontWeight: 300,
        },

        formatter: function() {
          let content = "";
          content += "<span style='color:" + 'this.styles.primaryLight' + ";'>\u25CF</span> " + a.name + ": <strong>" + ChartService.round(a.percent1 * 100) + "%" + " - " + ChartService.separator(a.size1) + "<i class='icon-sp-tooltip-user' > </i> </strong><br/>";
          content += "<span style='color:" + 'this.styles.primary' + ";'>\u25CF</span> " + b.name + ": <strong>" + ChartService.round(b.percent1 * 100) + "%" + " - " + ChartService.separator(b.size1) + "<i class='icon-sp-tooltip-user' > </i></strong><br/>";

          return content !== "" ? content : "Data is missing";
        },
        useHTML: true
      },
      plotOptions: {
        pie: {
          //                        y:1,
          shadow: false,
          //                        center: ['50%', '50%'],
          borderWidth: 0,
          showInLegend: false,
          size: '110%',
          innerSize: '80%',
          data: [
            [b.name, ChartService.round(b.percent1 * 100)],
            [a.name, ChartService.round(a.percent1 * 100)],
          ]
        }
      },
      series: [{
        type: 'pie',
        name: 'Target',
        dataLabels: {
          enabled: false,
          color: 'white',
          connectorColor: 'grey',
          connectorWidth: 1,
          zIndex: 2,
          distance: -100,
          formatter: function() {
            if (this.percentage >= 20) return Math.round(this.percentage) + '%';
          }
        }
      }, {
        type: 'pie',
        name: '',
        dataLabels: {
          enabled: false,
          connectorColor: 'grey',
          color: 'black',
          //y:-10,
          softConnector: true,
          connectorWidth: 1,
          verticalAlign: 'top',
          distance: 20,
          formatter: function() {
            if (this.percentage != 0) return this.point.name;
          }
        }
      }]
    };
  }

  createUserCreditsPieChart(creditsLeft: number, totalCredits: number,title?: string, subtitle?: string,isUser?: boolean ):any{
    let creditsUsed = totalCredits - creditsLeft;
    return {
      chart: {
        plotBackgroundColor: null,
        plotBorderWidth: null,
        plotShadow: false,
        type: 'pie',
        style: {
          fontFamily: "Roboto Condensed",
          fontWeight: 300,
        },
      },
      title: {
        text: title,
        floating: true,
        widthAdjust: 200,
        style: {
          fontWeight: 'bold',
          fontFamily: "Roboto Condensed",
          fontSize: "20px",
          color: "#484848"
        }
      },
      subtitle: {
        text: subtitle,
        floating: true,
        verticalAlign: "bottom",
        widthAdjust: 200,
        style: {
          color: "#484848",
          fontSize: "20px",
          //fontWeight: 'bold'
        }
      },
      tooltip: {
        formatter: function () {
          if(isUser) {
            return '<b>' + creditsUsed + '</b> user out of  <b> ' + totalCredits + '</b>';
          } else {
            return '<b>' + creditsLeft + ' /</b> <b> ' + totalCredits + '</b> credits';
          }
        },
        shared: true,
        //pointFormat: '<b>this.point[0]</b> credits used on <b>this.point[1]</b> monthly credits</b>'
      },
      accessibility: {
        point: {
          valueSuffix: '%'
        }
      },
      plotOptions: {
        pie: {
          allowPointSelect: false,
          cursor: 'pointer',
          dataLabels: {
            enabled: false,
            format: '<b>{point.name}</b> credits used on <b></b> monthly credits'
          },
          borderWidth: 0,
          showInLegend: false,
          size: '100%',
          innerSize: '80%',
        }
      },
      exporting: {
        enabled: false,
        allowHTML: true,
      },
      credits: {
        enabled: false
      },
      colors: [
        "#8AC192",
        "#EBEBEB",
      ],
      series: [{
        name: 'Credits',
        colorByPoint: true,
        data: [{
          name: 'Credits left',
          y: creditsLeft,
          sliced: false,
          selected: false
        }, {
          name: 'Credits used',
          y: creditsUsed
        }]
      }],
    }
  }
  createPieChart(item: StatItem, metrics: MetricType = MetricType.TARGET_PEN, img?: string, title?: string, subtitle?: string): any {
    const imgWidth = 70;
    const imgHeight = 70;
    const selectivity = ChartService.round(item.selectivity * 100);
    const score = item.score;
    const percent1 = ChartService.round(item.percent1 * 100);
    const percent2 = ChartService.round(item.percent2 * 100);
    const env = environment;
    let data = [];

    if (metrics == MetricType.TARGET_PEN) {
      data = [
        ['Benchmark', percent2],
        ['Target', percent1]
      ]
    } else if (metrics == MetricType.BENCHMARK_PEN) {
      data = [
        ['Target', percent1],
        ['Benchmark', percent2]
      ]
    }

    return {
      chart: {
        type: 'pie',
        style: {
          fontFamily: "Roboto Condensed",
          fontWeight: 300,
        },
        events: {



          /*
          redraw:function(){
            const chart = this;
            const xPos = chart.plotWidth / 2 + chart.plotLeft;
            const yPos = chart.plotHeight / 2 + chart.plotTop;
            if (chart.customText){
              chart.customText.destroy();
            }

            chart.customText = this.renderer.text(ChartService.round(item.score * 100), xPos * 1.2, yPos /2, 30, 30)
              .css({
                color: '#8AC192',
                fontSize: '12pt',
                fontFamily: 'Roboto Condensed',
                fontWeight: 800,
                zindex: 30,
                background: '#ffffff',
                'border': '1px solid',
                'border-radius': '50%',
                'padding-top': '0.5rem',
                'padding-bottom': '0.5rem',
                'padding-left': '0.6rem',
                'padding-right': '0.6rem',
                borderColor: '#8AC192',
              })
              .add();

            if(chart.customImg){
              chart.customImg.destroy();
            }

            if (img) {
              chart.customImg = this.renderer.image(img, xPos - imgWidth / 2, yPos - imgHeight / 2, imgWidth, imgHeight)
                .css({
                  'margin-left':'auto',
                  'margin-right':'auto',
                })
                .add();
            }
          },

           */
        },
        backgroundColor: 'rgba(0,0,0,0)',
        y: 100,
      },
      title: {
        text: title,
        margin: 30,
        align: 'left',
        style: {
          fontSize: '1.2rem',
          fontFamily: 'Roboto Condensed',
          fontWeight: 400,
          color: '#484848'
        }
      },
      subtitle: {
        text: subtitle,
        align: 'left',
        margin: 10,
        style: {
          fontFamily: "Roboto Condensed",
          fontSize: '0.9rem',
          fontWeight: 200,
          color: '#8E8E8E'
        }
      },
      yAxis: {
        title: {
          text: ' '
        }
      },
      exporting: {
        enabled: false,
        allowHTML: true,
      },
      credits: {
        enabled: false
      },
      colors: [
        "#6BC9E4",
        "#8AC192",
      ],
      plotOptions: {
        pie: {
          //                        y:1,
          shadow: false,
          //                        center: ['50%', '50%'],
          borderWidth: 0,
          showInLegend: false,
          size: '100%',
          innerSize: '80%',
          data: data
        }
      },
      tooltip: {
        valueSuffix: '%',
        outside: true,
        shared: true,
        split: false,
        enabled: true,
        style: {
          width: 200
        },

        formatter: function() {
          let content = "";
          content += "<span> <strong>" + item.name + "</strong></span><br/>";
          if (percent1) content += "<span style='color:" + '#8AC192' + ";'>\u25CF</span> Target: <strong>" + percent1 + "%</strong><br/>";
          if (percent2) content += "<span style='color:" + '#6BC9E4' + ";'>\u25CF</span> Benchmark: <strong>" + percent2 + "%</strong><br/>";
          if (selectivity) content += "<span style='color:" + '#000000' + ";'>\u25CF</span> Selectivity: <strong>" + selectivity + "</strong><br>";
          if (score && env.config.dev) content += "<span style='color:" + '#000000' + ";'>\u25CF</span> Score: <strong>" + score + "</strong>";
          return content !== "" ? content : "Data is missing";
        },
        useHTML: true
      },
      series: [{
        type: 'pie',
        name: 'Target',

        dataLabels: {
          enabled: false,
          color: 'white',
          connectorColor: 'grey',
          connectorWidth: 1,
          zIndex: 2,
          distance: -100,
          formatter: function() {
            if (this.percentage >= 20) return Math.round(this.percentage) + '%';
          }
        }
      }, {
        type: 'pie',
        name: '',

        dataLabels: {
          enabled: false,
          connectorColor: 'grey',
          color: 'black',
          softConnector: true,
          connectorWidth: 1,
          verticalAlign: 'top',
          distance: 20,
          formatter: function() {
            if (this.percentage != 0) return this.point.name;
          }
        }
      }]
    };
  }
  createSmallHistogramChart(item: StatItem, title?: string): any {
    const per1 = ChartService.round(item.percent1 * 100);
    const per2 = ChartService.round(item.percent2 * 100);
    const selectivity = ChartService.round(item.selectivity * 100);
    const score = ChartService.round(item.score);
    const env = environment;
    return {
      chart: {
        style: {
          fontFamily: "Roboto Condensed",
            fontWeight: 300,
        },
        backgroundColor: 'transparent',
          type: 'column',
      },
      boost: { enabled: true },
      title: {
        text: title
      },
      exporting: {
        enabled: false
      },

      subtitle: {
        text: ''
      },

      legend: {
        enabled: false,
          visible: false,
          align: 'right',
          verticalAlign: 'middle',
          layout: 'vertical'
      },
      tooltip: {
        valueSuffix: '%',
        outside: true,
        shared: true,
        split: false,
        enabled: true,
        style: {
          width: 200,
        },
        formatter: function() {
          let content = "";
          content += "<span> <strong>" + item.name + "</strong></span><br/>";
          if (per1) content += "<span style='color:" + '#8AC192' + ";'>\u25CF</span> Target: <strong>" + per1 + "%</strong><br/>";
          if (per2) content += "<span style='color:" + '#6BC9E4' + ";'>\u25CF</span> Benchmark: <strong>" + per2 + "%</strong><br/>";
          if (selectivity) content += "<span style='color:" + '#000000' + ";'>\u25CF</span> Selectivity: <strong>" + selectivity + "</strong><br>";
          if (score && env.config.dev) content += "<span style='color:" + '#000000' + ";'>\u25CF</span> Score: <strong>" + score + "</strong>";
          return content !== "" ? content : "Data is missing";
        },
        useHTML: true
      },
      credits: {
        enabled: false
      },

      plotOptions: {
        series: {
          pointWidth: 50,
            pointPadding: 0,
            groupPadding: 1
        }
      },

      colors: [
        "#8AC192",
        "#6BC9E4",
      ],

        xAxis: {

      visible: true,
        enabled: false,
        categories: ['Target', 'Benchmark'],
    },

      yAxis: {
        opposite: true,
          visible: false,
          allowDecimals: false,
          title: {
          text: ''
        }
      },
      series: [{
        dataLabels: {
          style: {
            fontFamily: "Roboto Condensed",
            fontWeight: 300,
            color: "black",
            fontSize: "16px",
            stroke: "black",
            textOutline: false
          },
          enabled: true,
          align: 'center',
          inside: true,
          formatter: function() {
            return this.y;
          }
        },
        type: 'column',
        colorByPoint: true,
        data: [per1, per2],

        showInLegend: false
      }]
    }
  }
  createDoubleChart(statItem?: StatItem, type?: SocioChartType, title?: string, withImage: boolean = true): any {
    const behavior = ChartService.loadDoubleChartBehavior(type);
    //MALE
    const percent1 = statItem ? ChartService.round(statItem.percent1 * 100) : 0;
    const percent2 = statItem ? ChartService.round(statItem.percent2 * 100) : 0;
    const selectivity = statItem ? ChartService.round(statItem.selectivity * 100) : 0;
    const score = statItem ? statItem.score : 0;
    const size1 = statItem ? statItem.size1 : 0;
    const size2 = statItem ? statItem.size2 : 0;
    const audience1 = statItem ? statItem.audience1 : 0;
    const audience2 = statItem ? statItem.audience2 : 0;
    const name = title ? title : (statItem ? statItem.name : "N/A");
    const tooltip = document.createElement('div');

    tooltip.classList.add('sp-tooltip');
    tooltip.innerText = "Selectivity : " + selectivity;
    tooltip.hidden = true;
    document.body.appendChild(tooltip);

    return {
      boost: { enabled: true },
      title: {
        text: name,
      },
      chart: {
        style: {
          fontFamily: 'Roboto Condensed',
          fontWeight: 300
        },
        type: 'pie',
          events: {

          render: function() {
            const chart = this,
              height_image = behavior.imageHeight * behavior.imageModifier,
              width_image = behavior.imageWidth * behavior.imageModifier,
              newX = chart.plotWidth / 2 + (behavior.xImageCalcOffset) + chart.plotLeft;

            if (behavior.imageUrl && withImage) {
              if (chart.customImg) {
                chart.customImg.destroy();
              }

              chart.customImg = chart.renderer.image(behavior.imageUrl,chart.plotLeft + chart.plotWidth * 0.35 , behavior.yImagePosition, width_image, height_image)
                .attr({
                  zIndex: 4,
                  'margin-left': 'auto',
                  'margin-right': 'auto',
                  'pointer-events': 'none'
                })
                .add();
            }
            //For the Inside Text
            if (chart.customImgTextInside) {
              chart.customImgTextInside.destroy();
            }
            const axeXInside = chart.plotWidth / 2 + chart.plotLeft;
            const axeYInside = (chart.plotHeight / 2 + chart.plotTop);
            if(withImage) {
              chart.customImgTextInside = chart.renderer.text(percent1 + "%", axeXInside, axeYInside  * behavior.yAxeInModifier -5)
                .attr({
                  align: 'center'
                })
                .css({
                  color: '#8AC192',
                  fontSize: '12pt',
                  fontFamily: 'Roboto Condensed',
                  fontWeight: 800
                }).add();
            } else {
              chart.customImgTextInside = chart.renderer.text(percent1 + "%", axeXInside, axeYInside)
                .attr({
                  align: 'center'
                })
                .css({
                  color: '#8AC192',
                  fontSize: '12pt',
                  fontFamily: 'Roboto Condensed',
                  fontWeight: 800
                }).add();
            }


            const newXOutside = chart.plotWidth / 2 + chart.plotLeft,
              newYOutside = chart.plotHeight / 2 + chart.plotTop;

            if (chart.customImgTextOutside) {
              chart.customImgTextOutside.destroy();
            }
            chart.customImgTextOutside = chart.renderer.text(selectivity, newXOutside, newYOutside * behavior.yAxeOutModifier)
              .attr({
                align: 'center'
              }).on('mousemove', function(e) {
                const x = e.pageX;
                const y = e.pageY;

                tooltip.style.left = (x + 10) + 'px';
                tooltip.style.top = (y + 10) + 'px';

                if (tooltip.hidden) tooltip.hidden = false;
              }).on('mouseout', function() {
                tooltip.hidden = true;
              })
              .css({
                fontSize: '12pt',
                fontFamily: 'Roboto Condensed',
              }).add();
          },

        }
      },

      series: [{
        //MALE
        colors: [
          "#6BC9E4",
          "#EBEBEB",
        ],
        innerSize: behavior.innerSize,
        size: behavior.size,
        showInLegend:false,
        dataLabels: {
          enabled: false,
          softConnector: false
        },
        data: [
          ['Benchmark', percent2],
          ['Left', 100 - percent2],
        ],
        absolute: audience1
      }, {
        colors: [
          "#8AC192",
          "#EBEBEB",
        ],
        innerSize: behavior.innerSize - 25,
        size: behavior.size - 20,
        showInLegend:false,
        dataLabels: {
          enabled: false,
          softConnector: false
        },
        data: [
          ['Target', percent1],
          ['Left', 100 - percent1],
        ],
        absolute: audience2
      },
      ],


      tooltip: {
        formatter: () => {
          return ChartService.generateTooltip(name, percent1, size1, percent2, size2, selectivity, score);
        },
        useHTML: true,
        outside: true,
        style: {
          width: 500
        },
        hideDelay: 10
      },

      exporting: {
        enabled: false
      },
      credits:{
        enabled:false
      }
    };
  }

  createGenderChart(statItemMale?: StatItem,statItemFemale?: StatItem,type?: SocioChartType, title?: string, subtitle?: string, caption?: string, withImage: boolean = true,chartWidth?: HTMLElement): any {
    const behavior = ChartService.loadDoubleChartBehavior(type);
    //MALE
    const malePercent1 = statItemMale ? ChartService.round(statItemMale.percent1 * 100) : 0;
    const malePercent2 = statItemMale ? ChartService.round(statItemMale.percent2 * 100) : 0;
    const maleSelectivity = statItemMale ? ChartService.round(statItemMale.selectivity * 100) : 0;
    const maleScore = statItemMale ? statItemMale.score : 0;
    const maleSize1 = statItemMale ? statItemMale.size1 : 0;
    const maleSize2 = statItemMale ? statItemMale.size2 : 0;
    const maleAudience1 = statItemMale ? statItemMale.audience1 : 0;
    const maleAudience2 = statItemMale ? statItemMale.audience2 : 0;

    //FEMALE
    const femalePercent1 = statItemFemale ? ChartService.round(statItemFemale.percent1 * 100) : 0;
    const femalePercent2 = statItemFemale ? ChartService.round(statItemFemale.percent2 * 100) : 0;
    const femaleSelectivity = statItemFemale ? ChartService.round(statItemFemale.selectivity * 100) : 0;
    const femaleScore = statItemFemale ? statItemFemale.score : 0;
    const femaleSize1 = statItemFemale ? statItemFemale.size1 : 0;
    const femaleSize2 = statItemFemale ? statItemFemale.size2 : 0;
    const femaleAudience1 = statItemFemale ? statItemFemale.audience1 : 0;
    const femaleAudience2 = statItemFemale ? statItemFemale.audience2 : 0;

    //const name = title ? title : (statItem ? statItem.name : "N/A");
    const tooltipMale = document.createElement('div');

    tooltipMale.classList.add('sp-tooltip');
    tooltipMale.innerText = "Selectivity : " + maleSelectivity;
    tooltipMale.hidden = true;
    document.body.appendChild(tooltipMale);

    const tooltipFemale = document.createElement('div');

    tooltipFemale.classList.add('sp-tooltip');
    tooltipFemale.innerText = "Selectivity : " + femaleSelectivity;
    tooltipFemale.hidden = true;
    document.body.appendChild(tooltipFemale);

    return {
      boost: { enabled: true },
      title: {
        text: title,
        align: 'left',
        margin: 30,
        style: {
          fontSize: '1.2rem',
          fontFamily: 'Roboto Condensed',
          fontWeight: 400,
          color: '#484848'
        }
      },
      subtitle: {
        text: subtitle,
        margin: 10,
        align: 'left',
        style: {
          fontFamily: "Roboto Condensed",
          fontSize: '0.9rem',
          fontWeight: 200,
          color: '#8E8E8E'
        }
      },
      caption: {
        text: caption,
        verticalAlign: 'top',
        useHTML: true,
        style: {
          paddingTop:'1rem',
          textAlign: 'center',
          fontFamily: "Roboto Condensed",
          fontSize: '1rem',
          fontWeight: 200,
        }
      },
      chart: {
        style: {
          width: '100%',
          fontFamily: 'Roboto Condensed',
          fontWeight: 300
        },
        type: 'pie',
          events: {
            render: function() {
              let chart = this;
              let chartParentWidth = chartWidth.querySelector('div').offsetWidth;
              this.setCaption({
                text: '<span style="display: flex;justify-content: space-around;width: '+chartParentWidth+'px">' +
                  '<span style="position: relative;margin-left: 20px">Male</span>' +
                  '<span style="position: relative;margin-right: 30px">Female</span>' +
                  '</span>',
              })
              /*
              if (!chart.title1) {
                chart.title1 = chart.renderer.text('Men', chart.plotLeft + chart.plotWidth * 0.25, chart.plotTop + 20).add()
                chart.title2 = chart.renderer.text('Women',  chart.plotWidth * 0.75 + chart.plotLeft, chart.plotTop + 20).add();

              } else {
                chart.title1.attr({
                  x: chart.plotLeft + chart.plotWidth * 0.25
                })
                chart.title2.attr({
                  x: chart.plotWidth * 0.72 + chart.plotLeft -10
                })
              }

               */

            //MALE
              let height_image = behavior.imageHeight * behavior.imageModifier,
                width_image = behavior.imageWidth * behavior.imageModifier,
                newX = chart.plotWidth / 2 + (behavior.xImageCalcOffset) + chart.plotLeft;

              if (behavior.imageUrl && withImage) {
                if (chart.customImg) {
                  chart.customImg.destroy();
                }

                chart.customImg = chart.renderer.image(behavior.imageUrl, '45%', behavior.yImagePosition, width_image, height_image)
                  .attr({
                    zIndex: 4,
                    'margin-left': 'auto',
                    'margin-right': 'auto',
                    'pointer-events': 'none'
                  })
                  .add();
              }
              //For the Inside Text
              if (chart.customImgTextInsideMale) {
                chart.customImgTextInsideMale.destroy();
              }
              const axeXInside = chart.plotWidth * 0.28 + chart.plotLeft;
              const axeYInside = (chart.plotHeight / 2 + chart.plotTop);
              if(withImage) {
                chart.customImgTextInsideMale = chart.renderer.text(malePercent1 + "%", axeXInside, axeYInside  * behavior.yAxeInModifier)
                  .attr({
                    align: 'center'
                  })
                  .css({
                    color: '#8AC192',
                    fontSize: '12pt',
                    fontFamily: 'Roboto Condensed',
                    fontWeight: 800
                  }).add();
              } else {
                chart.customImgTextInsideMale = chart.renderer.text(malePercent1 + "%", axeXInside, axeYInside + 9)
                  .attr({
                    align: 'center'
                  })
                  .css({
                    color: '#8AC192',
                    fontSize: '12pt',
                    fontFamily: 'Roboto Condensed',
                    fontWeight: 800
                  }).add();
              }


              const newXOutside = chart.plotWidth * 0.27 + chart.plotLeft,
                newYOutside = chart.plotHeight + chart.plotTop;

              if (chart.customImgTextOutsideMale) {
                chart.customImgTextOutsideMale.destroy();
              }
              chart.customImgTextOutsideMale = chart.renderer.text(maleSelectivity, newXOutside, newYOutside + 10)
                .attr({
                  align: 'center'
                }).on('mousemove', function(e) {
                  const x = e.pageX;
                  const y = e.pageY;

                  tooltipMale.style.left = (x + 10) + 'px';
                  tooltipMale.style.top = (y + 10) + 'px';

                  if (tooltipMale.hidden) tooltipMale.hidden = false;
                }).on('mouseout', function() {
                  tooltipMale.hidden = true;
                })
                .css({
                  fontSize: '12pt',
                  fontFamily: 'Roboto Condensed',
                }).add();

              //FEMALE
              //For the Inside Text
              if (chart.customImgTextInsideFemale) {
                chart.customImgTextInsideFemale.destroy();
              }
              const axeXInsideFemale = chart.plotWidth * 0.74 + chart.plotLeft;

              if(withImage) {
                chart.customImgTextInsideFemale = chart.renderer.text(femalePercent1 + "%", axeXInsideFemale, axeYInside  * behavior.yAxeInModifier)
                  .attr({
                    align: 'center'
                  })
                  .css({
                    color: '#8AC192',
                    fontSize: '12pt',
                    fontFamily: 'Roboto Condensed',
                    fontWeight: 800
                  }).add();
              } else {
                chart.customImgTextInsideFemale = chart.renderer.text(femalePercent1 + "%", axeXInsideFemale, axeYInside + 9)
                  .attr({
                    align: 'center'
                  })
                  .css({
                    color: '#8AC192',
                    fontSize: '12pt',
                    fontFamily: 'Roboto Condensed',
                    fontWeight: 800
                  }).add();
              }

              const newXOutsideFemale = chart.plotWidth * 0.75 + chart.plotLeft

              if (chart.customImgTextOutsideFemale) {
                chart.customImgTextOutsideFemale.destroy();
              }
              chart.customImgTextOutsideFemale = chart.renderer.text(femaleSelectivity, newXOutsideFemale, newYOutside + 10 )
                .attr({
                  align: 'center'
                }).on('mousemove', function(e) {
                  const x = e.pageX;
                  const y = e.pageY;

                  tooltipFemale.style.left = (x + 10) + 'px';
                  tooltipFemale.style.top = (y + 10) + 'px';

                  if (tooltipFemale.hidden) tooltipFemale.hidden = false;
                }).on('mouseout', function() {
                  tooltipFemale.hidden = true;
                })
                .css({
                  fontSize: '12pt',
                  fontFamily: 'Roboto Condensed',
                }).add();

            },
        }
      },
      responsive: {
        rules: [{
          condition: {
            minHeight: 400
          },
          chartOptions: {
            chart: {
              height: 399,
            },
          }
        }]
      },
      series: [{
        //MALE
        colors: [
          "#6BC9E4",
          "#EBEBEB",
        ],
        innerSize: "90%",
        size: "80%",
        showInLegend:false,
        dataLabels: {
          enabled: false,
          softConnector: false
        },
        options: {
          gender: 'male'
        },
        data: [
          ['Benchmark', malePercent2],
          ['Left', 100 - malePercent2],
        ],
        /*
        tooltip: {
          shared: true,
          useHTML: true,
          headerFormat: '<table><tr><th colspan="2"></th></tr>',
          valueDecimals: 2,
          pointFormatter: function() {
            return ChartService.generateTooltip("Men", malePercent1, maleSize1, malePercent2, maleSize2, maleSelectivity, maleScore)
          }
        },

         */
        center: ['25%', '50%'],
        absolute: maleAudience1
      }, {
        colors: [
          "#8AC192",
          "#EBEBEB",
        ],
        innerSize: "90%",
        size: "70%",
        showInLegend:false,
        dataLabels: {
          enabled: false,
          softConnector: false
        },
        options: {
          gender: 'male'
        },
        data: [
          ['Target', malePercent1],
          ['Left', 100 - malePercent1],
        ],
        /*
        tooltip: {
          shared: true,
          useHTML: true,
          headerFormat: '<table><tr><th colspan="2"></th></tr>',
          valueDecimals: 2,
          pointFormatter: function() {
            return  ChartService.generateTooltip("Men", malePercent1, maleSize1, malePercent2, maleSize2, maleSelectivity, maleScore)
          },
        },

         */
        center: ['25%', '50%'],
        absolute: maleAudience2
      },
        //FEMALE
        {
          colors: [
            "#6BC9E4",
            "#EBEBEB",
          ],
          innerSize: "90%",
          size: "80%",
          showInLegend:false,
          dataLabels: {
            enabled: false,
            softConnector: false
          },
          options: {
            gender: 'female'
          },
          data: [
            ['Benchmark', femalePercent2],
            ['Left', 100 - femalePercent2],
          ],
/*
          tooltip: {
            shared: true,
            useHTML: true,
            headerFormat: '<table><tr><th colspan="2"></th></tr>',
            valueDecimals: 2,
            pointFormatter: function() {
              return  ChartService.generateTooltip("Women", femalePercent1, femaleSize1, femalePercent2, femaleSize2, femaleSelectivity, femaleScore)
            },
          },

 */
          center: ['75%', '50%'],
          absolute: femaleAudience1
        }, {
          colors: [
            "#8AC192",
            "#EBEBEB",
          ],
          innerSize: "90%",
          size: "70%",
          showInLegend:false,
          dataLabels: {
            enabled: false,
            softConnector: false
          },
          options: {
            gender: 'female'
          },
          data: [
            ['Target', femalePercent1],
            ['Left', 100 - femalePercent1],
          ],
          /*
          tooltip: {
            shared: true,
            useHTML: true,
            headerFormat: '<table><tr><th colspan="2"></th></tr>',
            valueDecimals: 2,
            pointFormatter: function() {
              return  ChartService.generateTooltip("Women", femalePercent1, femaleSize1, femalePercent2, femaleSize2, femaleSelectivity, femaleScore)
            },
          },

           */
          center: ['75%', '50%'],
          absolute: femaleAudience2
        },
      ],

      tooltip: {
        formatter: function() {
         if(this.series.options.options.gender == "male") {
           return ChartService.generateTooltip("Male", malePercent1, maleSize1, malePercent2, maleSize2, maleSelectivity, maleScore);
         }else {
           return  ChartService.generateTooltip("Women", femalePercent1, femaleSize1, femalePercent2, femaleSize2, femaleSelectivity, femaleScore)
         }
        },
        useHTML: true,
        outside: true,
        style: {
          width: 500
        },
        hideDelay: 10
      },


      exporting: {
        enabled: false
      },
      credits:{
        enabled:false
      }
    };
  }
  createParentsChart(statItem?: StatItem,type?: SocioChartType, title?: string, subtitle?: string, caption?: string, withImage: boolean = true,chartWidth?: HTMLElement): any {
    const behavior = ChartService.loadDoubleChartBehavior(type);
    //MALE
    const malePercent1 = statItem ? ChartService.round(statItem.percent1 * 100) : 0;
    const malePercent2 = statItem ? ChartService.round(statItem.percent2 * 100) : 0;
    const maleSelectivity = statItem ? ChartService.round(statItem.selectivity * 100) : 0;
    const maleScore = statItem ? statItem.score : 0;
    const maleSize1 = statItem ? statItem.size1 : 0;
    const maleSize2 = statItem ? statItem.size2 : 0;
    const maleAudience1 = statItem ? statItem.audience1 : 0;
    const maleAudience2 = statItem ? statItem.audience2 : 0;

    //const name = title ? title : (statItem ? statItem.name : "N/A");
    const tooltipMale = document.createElement('div');

    tooltipMale.classList.add('sp-tooltip');
    tooltipMale.innerText = "Selectivity : " + maleSelectivity;
    tooltipMale.hidden = true;
    document.body.appendChild(tooltipMale);

    return {
      boost: { enabled: true },
      title: {
        text: title,
        align: 'left',
        margin: 30,
        style: {
          fontSize: '1.2rem',
          fontFamily: 'Roboto Condensed',
          fontWeight: 400,
          color: '#484848'
        }
      },
      subtitle: {
        text: subtitle,
        margin: 10,
        align: 'left',
        style: {
          fontFamily: "Roboto Condensed",
          fontSize: '0.9rem',
          fontWeight: 200,
          color: '#8E8E8E'
        }
      },

      caption: {

        //text: maleSelectivity ,
         // '<div><span style="transform:translateX(10%)">Test 1</span> <span style="transform:translateX(10%)">Test 2</span> <span style="transform:translateX(10%)">Test 3 </span>',
        verticalAlign: 'top',
        useHTML: true,
        style: {
          paddingTop:'1rem',
          textAlign: 'center',
          fontFamily: "Roboto Condensed",
          fontSize: '1rem',
          fontWeight: 200,
        }
      },


      credits:{
        enabled: false
      },
      chart: {
        style: {
          width: '100%',
          fontFamily: 'Roboto Condensed',
          fontWeight: 300
        },
        type: 'pie',
          events: {
            render: function() {
              let chart = this;
              let chartParentWidth = chartWidth.querySelector('div').offsetWidth - 20;
              this.setCaption({
                text: '<span style="display: flex;justify-content: space-around;width: '+chartParentWidth+'px">' +
                  '<span style="position: relative;">Parents</span>' +
                  '</span>',
              })
              /*
              if (!chart.title1) {
                chart.title1 = chart.renderer.text('Parents', chart.plotLeft + chart.plotWidth * 0.50 -10, chart.plotTop + 20 ).add()


              } else {
                chart.title1.attr({
                  x: chart.plotLeft + chart.plotWidth * 0.50 -10
                })
              }

               */

            //MALE
              let height_image = behavior.imageHeight * behavior.imageModifier,
                width_image = behavior.imageWidth * behavior.imageModifier,
                newX = chart.plotWidth / 2 + (behavior.xImageCalcOffset) + chart.plotLeft;

              if (behavior.imageUrl && withImage) {
                if (chart.customImg) {
                  chart.customImg.destroy();
                }

                chart.customImg = chart.renderer.image(behavior.imageUrl, '45%', behavior.yImagePosition, width_image, height_image)
                  .attr({
                    zIndex: 4,
                    'margin-left': 'auto',
                    'margin-right': 'auto',
                    'pointer-events': 'none'
                  })
                  .add();
              }
              //For the Inside Text
              if (chart.customImgTextInsideMale) {
                chart.customImgTextInsideMale.destroy();
              }
              const axeXInside = chart.plotWidth * 0.50 + chart.plotLeft;
              const axeYInside = (chart.plotHeight / 2 + chart.plotTop);
              if(withImage) {
                chart.customImgTextInsideMale = chart.renderer.text(malePercent1 + "%", axeXInside, axeYInside  * behavior.yAxeInModifier)
                  .attr({
                    align: 'center'
                  })
                  .css({
                    color: '#8AC192',
                    fontSize: '12pt',
                    fontFamily: 'Roboto Condensed',
                    fontWeight: 800
                  }).add();
              } else {
                chart.customImgTextInsideMale = chart.renderer.text(malePercent1 + "%", axeXInside, axeYInside + 9)
                  .attr({
                    align: 'center'
                  })
                  .css({
                    color: '#8AC192',
                    fontSize: '12pt',
                    fontFamily: 'Roboto Condensed',
                    fontWeight: 800
                  }).add();
              }


              const newXOutside = chart.plotWidth * 0.50 + chart.plotLeft,
                newYOutside = chart.plotHeight + chart.plotTop ;

              if (chart.customImgTextOutsideMale) {
                chart.customImgTextOutsideMale.destroy();
              }
              chart.customImgTextOutsideMale = chart.renderer.text(maleSelectivity, newXOutside, newYOutside + 10)
                .attr({
                  align: 'center'
                }).on('mousemove', function(e) {
                  const x = e.pageX;
                  const y = e.pageY;

                  tooltipMale.style.left = (x + 10) + 'px';
                  tooltipMale.style.top = (y + 10) + 'px';

                  if (tooltipMale.hidden) tooltipMale.hidden = false;
                }).on('mouseout', function() {
                  tooltipMale.hidden = true;
                })
                .css({
                  fontSize: '12pt',
                  fontFamily: 'Roboto Condensed',
                }).add();


            },
        }
      },
      responsive: {
        rules: [{
          condition: {
            minHeight: 400
          },
          chartOptions: {
            chart: {
              height: 399,
            },
          }
        }]
      },
      series: [{

        colors: [
          "#6BC9E4",
          "#EBEBEB",
        ],
        innerSize: "90%",
        size: "80%",
        showInLegend:false,
        dataLabels: {
          enabled: false,

          color: '#ffffff',

          //distance: -30
          softConnector: false
        },

        data: [
          ['Benchmark', malePercent2],
          ['Left', 100 - malePercent2],
        ],
        /*
        tooltip: {
          shared: true,
          useHTML: true,
          headerFormat: '<table><tr><th colspan="2"></th></tr>',
          valueDecimals: 2,
          pointFormatter: function() {
            return  ChartService.generateTooltip("Men", malePercent1, maleSize1, malePercent2, maleSize2, maleSelectivity, maleScore)
          }
        },

         */
        center: ['50%', '50%'],
        absolute: maleAudience1
      }, {
        colors: [
          "#8AC192",
          "#EBEBEB",
        ],
        innerSize: "90%",
        size: "70%",
        showInLegend:false,
        dataLabels: {
          enabled: false,
          softConnector: false
        },
        data: [
          ['Target', malePercent1],
          ['Left', 100 - malePercent1],
        ],
        /*
        tooltip: {
          shared: true,
          useHTML: true,
          headerFormat: '<table><tr><th colspan="2"></th></tr>',
          valueDecimals: 2,
          pointFormatter: function() {
            return  ChartService.generateTooltip("Men", malePercent1, maleSize1, malePercent2, maleSize2, maleSelectivity, maleScore)
          },
        },

         */
        center: ['50%', '50%'],
        absolute: maleAudience2
      },
      ],

      tooltip: {
        formatter: function() {

          return ChartService.generateTooltip("Parents", malePercent1, maleSize1, malePercent2, maleSize2, maleSelectivity, maleScore);
        },
        useHTML: true,
        outside: true,
        style: {
          width: 500
        },
        hideDelay: 10
      },


      exporting: {
        enabled: false
      },
    };
  }
  createLanguageChart(statItemVar1?: StatItem, statItemVar2?: StatItem, statItemVar3?: StatItem, type?: SocioChartType, title?: string, subtitle?: string, caption?: string, withImage: boolean = true, chartWidth?: HTMLElement): any {
    const behavior = ChartService.loadDoubleChartBehavior(type);
    //Variable 1
    const malePercent1 = statItemVar1 ? ChartService.round(statItemVar1.percent1 * 100) : 0;
    const malePercent2 = statItemVar1 ? ChartService.round(statItemVar1.percent2 * 100) : 0;
    const maleSelectivity = statItemVar1 ? ChartService.round(statItemVar1.selectivity * 100) : 0;
    const maleScore = statItemVar1 ? statItemVar1.score : 0;
    const maleSize1 = statItemVar1 ? statItemVar1.size1 : 0;
    const maleSize2 = statItemVar1 ? statItemVar1.size2 : 0;
    const maleAudience1 = statItemVar1 ? statItemVar1.audience1 : 0;
    const maleAudience2 = statItemVar1 ? statItemVar1.audience2 : 0;
    const var1Name = statItemVar1.name;
    //Variable 2
    const femalePercent1 = statItemVar2 ? ChartService.round(statItemVar2.percent1 * 100) : 0;
    const femalePercent2 = statItemVar2 ? ChartService.round(statItemVar2.percent2 * 100) : 0;
    const femaleSelectivity = statItemVar2 ? ChartService.round(statItemVar2.selectivity * 100) : 0;
    const femaleScore = statItemVar2 ? statItemVar2.score : 0;
    const femaleSize1 = statItemVar2 ? statItemVar2.size1 : 0;
    const femaleSize2 = statItemVar2 ? statItemVar2.size2 : 0;
    const femaleAudience1 = statItemVar2 ? statItemVar2.audience1 : 0;
    const femaleAudience2 = statItemVar2 ? statItemVar2.audience2 : 0;
    const var2Name = statItemVar2.name;
    //Variable 3
    const var3Percent1 = statItemVar3 ? ChartService.round(statItemVar3.percent1 * 100) : 0;
    const var3Percent2 = statItemVar3 ? ChartService.round(statItemVar3.percent2 * 100) : 0;
    const var3Selectivity = statItemVar3 ? ChartService.round(statItemVar3.selectivity * 100) : 0;
    const var3Score = statItemVar3 ? statItemVar3.score : 0;
    const var3Size1 = statItemVar3 ? statItemVar3.size1 : 0;
    const var3Size2 = statItemVar3 ? statItemVar3.size2 : 0;
    const var3Audience1 = statItemVar3 ? statItemVar3.audience1 : 0;
    const var3Audience2 = statItemVar3 ? statItemVar3.audience2 : 0;
    const var3Name = statItemVar3.name;
    //const name = title ? title : (statItem ? statItem.name : "N/A");
    const tooltipMale = document.createElement('div');

    tooltipMale.classList.add('sp-tooltip');
    tooltipMale.innerText = "Selectivity : " + maleSelectivity;
    tooltipMale.hidden = true;
    document.body.appendChild(tooltipMale);

    const tooltipFemale = document.createElement('div');
    tooltipFemale.classList.add('sp-tooltip');
    tooltipFemale.innerText = "Selectivity : " + femaleSelectivity;
    tooltipFemale.hidden = true;
    document.body.appendChild(tooltipFemale);

    const tooltipVar3 = document.createElement('div');
    tooltipVar3.classList.add('sp-tooltip');
    tooltipVar3.innerText = "Selectivity : " + var3Selectivity;
    tooltipVar3.hidden = true;
    document.body.appendChild(tooltipVar3);

    return {
      boost: { enabled: true },
      title: {
        text: '<span style=""><span style="font-size: 1.2rem;font-weight: 400;color: #484848">' + title + '</span>',
        align: 'left',
        margin: 30,
        style: {
          paddingBottom: 10,
          fontSize: '1.2rem',
          fontFamily: 'Roboto Condensed',
          fontWeight: 400,
          color: '#484848'
        }
      },
      subtitle: {
        text: subtitle,
        margin: 10,
        align: 'left',
        style: {
          fontFamily: "Roboto Condensed",
          fontSize: '0.9rem',
          fontWeight: 200,
          color: '#8E8E8E'
        }
      },

      caption: {
        text: caption,
        verticalAlign: 'top',
        useHTML: true,
        style: {
          paddingTop:'1rem',
          textAlign: 'center',
          fontFamily: "Roboto Condensed",
          fontSize: '1rem',
          fontWeight: 200,
        }
      },
      chart: {
        style: {
          fontFamily: 'Roboto Condensed',
          fontWeight: 300
        },
        type: 'pie',
          events: {
            render: function() {
              let chart = this;
              let chartParentWidth = chartWidth.querySelector('div').offsetWidth - 20;
              this.setCaption({
                text: '<span style="display: flex;justify-content: space-around;width: '+chartParentWidth+'px">' +
                  '<span style="position: relative;"> '+var1Name+' </span>' +
                  '<span style="position: relative;"> '+var2Name+' </span>' +
                  '<span style="position: relative;"> '+var3Name+' </span>' +
                  '</span>',
              })
              /*
              if (!chart.title1) {
                chart.title1 = chart.renderer.text(var1Name, chart.plotWidth * 0.15 + chart.plotLeft, chart.plotTop + 40)
                  .attr({
                    align: 'center'
                  }).add()
                chart.title2 = chart.renderer.text(var2Name,  chart.plotWidth * 0.50 + chart.plotLeft , chart.plotTop + 40)
                  .attr({
                    align: 'center'
                  }).add();
                chart.title3 = chart.renderer.text(var3Name,  chart.plotWidth * 0.85 + chart.plotLeft , chart.plotTop + 40)
                  .attr({
                  align: 'center'
                }).add();

              } else {
                chart.title1.attr({
                  align: 'center',
                  x: chart.plotWidth * 0.15  + chart.plotLeft + 12
                })
                chart.title2.attr({
                  align: 'center',
                  x: chart.plotWidth * 0.50  + chart.plotLeft
                })
                chart.title3.attr({
                  align: 'center',
                  x: chart.plotWidth * 0.85  + chart.plotLeft -10
                })
              }

               */


              let height_image = behavior.imageHeight * behavior.imageModifier,
                width_image = behavior.imageWidth * behavior.imageModifier,
                newX = chart.plotWidth / 2 + (behavior.xImageCalcOffset) + chart.plotLeft;

              if (behavior.imageUrl && withImage) {
                if (chart.customImg) {
                  chart.customImg.destroy();
                }

                chart.customImg = chart.renderer.image(behavior.imageUrl, '45%', behavior.yImagePosition, width_image, height_image)
                  .attr({
                    zIndex: 4,
                    'margin-left': 'auto',
                    'margin-right': 'auto',
                    'pointer-events': 'none'
                  })
                  .add();
              }
              //For the Inside Text
              if (chart.customImgTextInsideMale) {
                chart.customImgTextInsideMale.destroy();
              }
              const axeXInside = chart.plotWidth * 0.15 + chart.plotLeft +15;
              const axeYInside = (chart.plotHeight / 2 + chart.plotTop);
              if(withImage) {
                chart.customImgTextInsideMale = chart.renderer.text(malePercent1 + "%", axeXInside, axeYInside  * behavior.yAxeInModifier)
                  .attr({
                    align: 'center'
                  })
                  .css({
                    color: '#8AC192',
                    fontSize: '12pt',
                    fontFamily: 'Roboto Condensed',
                    fontWeight: 800
                  }).add();
              } else {
                chart.customImgTextInsideMale = chart.renderer.text(malePercent1 + "%", axeXInside, axeYInside + 9)
                  .attr({
                    align: 'center'
                  })
                  .css({
                    color: '#8AC192',
                    fontSize: '12pt',
                    fontFamily: 'Roboto Condensed',
                    fontWeight: 800
                  }).add();
              }


              const newXOutside = chart.plotWidth * 0.15 + chart.plotLeft +15,
                newYOutside = chart.plotHeight + chart.plotTop;

              if (chart.customImgTextOutsideMale) {
                chart.customImgTextOutsideMale.destroy();
              }
              chart.customImgTextOutsideMale = chart.renderer.text(maleSelectivity, newXOutside, newYOutside + 10)
                .attr({
                  align: 'center'
                }).on('mousemove', function(e) {
                  const x = e.pageX;
                  const y = e.pageY;

                  tooltipMale.style.left = (x + 10) + 'px';
                  tooltipMale.style.top = (y + 10) + 'px';

                  if (tooltipMale.hidden) tooltipMale.hidden = false;
                }).on('mouseout', function() {
                  tooltipMale.hidden = true;
                })
                .css({
                  fontSize: '12pt',
                  fontFamily: 'Roboto Condensed',
                }).add();


              //For the Inside Text
              if (chart.customImgTextInsideFemale) {
                chart.customImgTextInsideFemale.destroy();
              }
              const axeXInsideFemale = chart.plotWidth * 0.50 + chart.plotLeft;

              if(withImage) {
                chart.customImgTextInsideFemale = chart.renderer.text(femalePercent1 + "%", axeXInsideFemale, axeYInside  * behavior.yAxeInModifier)
                  .attr({
                    align: 'center'
                  })
                  .css({
                    color: '#8AC192',
                    fontSize: '12pt',
                    fontFamily: 'Roboto Condensed',
                    fontWeight: 800
                  }).add();
              } else {
                chart.customImgTextInsideFemale = chart.renderer.text(femalePercent1 + "%", axeXInsideFemale, axeYInside + 9)
                  .attr({
                    align: 'center'
                  })
                  .css({
                    color: '#8AC192',
                    fontSize: '12pt',
                    fontFamily: 'Roboto Condensed',
                    fontWeight: 800
                  }).add();
              }

              const newXOutsideFemale = chart.plotWidth * 0.50 + chart.plotLeft

              if (chart.customImgTextOutsideFemale) {
                chart.customImgTextOutsideFemale.destroy();
              }
              chart.customImgTextOutsideFemale = chart.renderer.text(femaleSelectivity, newXOutsideFemale, newYOutside + 10 )
                .attr({
                  align: 'center'
                }).on('mousemove', function(e) {
                  const x = e.pageX;
                  const y = e.pageY;

                  tooltipFemale.style.left = (x + 10) + 'px';
                  tooltipFemale.style.top = (y + 10) + 'px';

                  if (tooltipFemale.hidden) tooltipFemale.hidden = false;
                }).on('mouseout', function() {
                  tooltipFemale.hidden = true;
                })
                .css({
                  fontSize: '12pt',
                  fontFamily: 'Roboto Condensed',
                }).add();

              //Variable 3
              //For the Inside Text
              if (chart.customImgTextInsideVar3) {
                chart.customImgTextInsideVar3.destroy();
              }
              const axeXInsideVar3 = chart.plotWidth * 0.85 + chart.plotLeft -10;

              if(withImage) {
                chart.customImgTextInsideVar3 = chart.renderer.text(var3Percent1 + "%", axeXInsideVar3, axeYInside  * behavior.yAxeInModifier)
                  .attr({
                    align: 'center'
                  })
                  .css({
                    color: '#8AC192',
                    fontSize: '12pt',
                    fontFamily: 'Roboto Condensed',
                    fontWeight: 800
                  }).add();
              } else {
                chart.customImgTextInsideVar3 = chart.renderer.text(var3Percent1 + "%", axeXInsideVar3, axeYInside + 9)
                  .attr({
                    align: 'center'
                  })
                  .css({
                    color: '#8AC192',
                    fontSize: '12pt',
                    fontFamily: 'Roboto Condensed',
                    fontWeight: 800
                  }).add();
              }

              const newXOutsideVar3 = chart.plotWidth * 0.85 + chart.plotLeft-10

              if (chart.customImgTextOutsideVar3) {
                chart.customImgTextOutsideVar3.destroy();
              }
              chart.customImgTextOutsideVar3 = chart.renderer.text(var3Selectivity, newXOutsideVar3, newYOutside + 10 )
                .attr({
                  align: 'center'
                }).on('mousemove', function(e) {
                  const x = e.pageX;
                  const y = e.pageY;

                  tooltipVar3.style.left = (x + 10) + 'px';
                  tooltipVar3.style.top = (y + 10) + 'px';

                  if (tooltipVar3.hidden) tooltipVar3.hidden = false;
                }).on('mouseout', function() {
                  tooltipVar3.hidden = true;
                })
                .css({
                  fontSize: '12pt',
                  fontFamily: 'Roboto Condensed',
                }).add();
            },
        }
      },

      series: [{
       // First language

        colors: [
          "#6BC9E4",
          "#EBEBEB",
        ],
        innerSize: "90%",
        size: "60%",
        showInLegend:false,
        dataLabels: {
          enabled: false,
          softConnector: false
        },
        options: {
          dataVar: 'var1'
        },
        data: [
          ['Benchmark', malePercent2],
          ['Left', 100 - malePercent2],
        ],
        tooltip: {
          shared: true,
          useHTML: true,
          headerFormat: '<table><tr><th colspan="2"></th></tr>',
          valueDecimals: 2,
          pointFormatter: function() {
            return  ChartService.generateTooltip(var1Name, malePercent1, maleSize1, malePercent2, maleSize2, maleSelectivity, maleScore)
          }
        },
        center: ['15%', '50%'],
        absolute: maleAudience1
      }, {
        colors: [
          "#8AC192",
          "#EBEBEB",
        ],
        innerSize: "90%",
        size: "52%",
        showInLegend:false,
        dataLabels: {
          enabled: false,
          softConnector: false
        },
        options: {
          dataVar: 'var1'
        },
        data: [
          ['Target', malePercent1],
          ['Left', 100 - malePercent1],
        ],
        tooltip: {
          shared: true,
          useHTML: true,
          headerFormat: '<table><tr><th colspan="2"></th></tr>',
          valueDecimals: 2,
          pointFormatter: function() {
            return  ChartService.generateTooltip(var1Name, malePercent1, maleSize1, malePercent2, maleSize2, maleSelectivity, maleScore)
          },
        },
        center: ['15%', '50%'],
        absolute: maleAudience2
      },
        // Second language
        {

          colors: [
            "#6BC9E4",
            "#EBEBEB",
          ],
          innerSize: "90%",
          size: "60%",
          showInLegend:false,
          dataLabels: {
            enabled: false,
            softConnector: false
          },
          options: {
            dataVar: 'var2'
          },
          data: [
            ['Benchmark', femalePercent2],
            ['Left', 100 - femalePercent2],
          ],
/*
          tooltip: {
            shared: true,
            useHTML: true,
            headerFormat: '<table><tr><th colspan="2"></th></tr>',
            valueDecimals: 2,
            pointFormatter: function() {
              return  ChartService.generateTooltip(var2Name, femalePercent1, femaleSize1, femalePercent2, femaleSize2, femaleSelectivity, femaleScore)
            },
          },

 */
          center: ['50%', '50%'],
          absolute: femaleAudience1
        }, {
          colors: [
            "#8AC192",
            "#EBEBEB",
          ],
          innerSize: "90%",
          size: "52%",
          showInLegend:false,
          dataLabels: {
            enabled: false,
            softConnector: false
          },
          options: {
            dataVar: 'var2'
          },
          data: [
            ['Target', femalePercent1],
            ['Left', 100 - femalePercent1],
          ],
          /*
          tooltip: {
            shared: true,
            useHTML: true,
            headerFormat: '<table><tr><th colspan="2"></th></tr>',
            valueDecimals: 2,
            pointFormatter: function() {
              return  ChartService.generateTooltip(var2Name, femalePercent1, femaleSize1, femalePercent2, femaleSize2, femaleSelectivity, femaleScore)
            },
          },

           */
          center: ['50%', '50%'],
          absolute: femaleAudience2
        },
        // Third language
        {
          colors: [
            "#6BC9E4",
            "#EBEBEB",
          ],
          innerSize: "90%",
          size: "60%",
          showInLegend:false,
          dataLabels: {
            enabled: false,
            softConnector: false
          },
          options: {
            dataVar: 'var3'
          },
          data: [
            ['Benchmark', var3Percent2],
            ['Left', 100 - var3Percent2],
          ],
/*
          tooltip: {
            shared: true,
            useHTML: true,
            headerFormat: '<table><tr><th colspan="2"></th></tr>',
            valueDecimals: 2,
            pointFormatter: function() {
              return  ChartService.generateTooltip(var3Name, var3Percent1, var3Size1, var3Percent2, var3Size2, var3Selectivity, var3Score)
            },
          },

 */
          center: ['85%', '50%'],
          absolute: var3Audience1
        }, {
          colors: [
            "#8AC192",
            "#EBEBEB",
          ],
          innerSize: "90%",
          size: "52%",
          showInLegend:false,
          dataLabels: {
            enabled: false,
            softConnector: false
          },
          options: {
            dataVar: 'var3'
          },
          data: [
            ['Target', var3Percent1],
            ['Left', 100 - var3Percent1],
          ],
          /*
          tooltip: {
            shared: true,
            useHTML: true,
            headerFormat: '<table><tr><th colspan="2"></th></tr>',
            valueDecimals: 2,
            pointFormatter: function() {
              return  ChartService.generateTooltip(var3Name, var3Percent1, var3Size1, var3Percent2, var3Size2, var3Selectivity, var3Score)
            },
          },

           */
          center: ['85%', '50%'],
          absolute: var3Audience2
        },
      ],

      tooltip: {
        formatter: function() {

          if(this.series.options.options.dataVar == "var1") {
            return ChartService.generateTooltip(var1Name, malePercent1, maleSize1, malePercent2, maleSize2, maleSelectivity, maleScore);
          }else if (this.series.options.options.dataVar == "var2") {
            return  ChartService.generateTooltip(var2Name, femalePercent1, femaleSize1, femalePercent2, femaleSize2, femaleSelectivity, femaleScore)
          } else {
            return  ChartService.generateTooltip(var3Name, var3Percent1, var3Size1, var3Percent2, var3Size2, var3Selectivity, var3Score)
          }
        },
        useHTML: true,
        outside: true,
        style: {
          width: 500
        },
        hideDelay: 10
      },

      responsive: {
        rules: [{
          condition: {
            minHeight: 400
          },
          chartOptions: {
            chart: {
              height: 399,
            },
          }
        }]
      },

      exporting: {
        enabled: false
      },
      credits:{
        enabled:false
      }
    };
  }
  createRelationChart(statItemMale?: StatItem, statItemFemale?: StatItem, type?: SocioChartType, title?: string, subtitle?: string, caption?: string, withImage: boolean = false, chartWidth?: HTMLElement): any {
    const behavior = ChartService.loadDoubleChartBehavior(type);
    //MALE
    const malePercent1 = statItemMale ? ChartService.round(statItemMale.percent1 * 100) : 0;
    const malePercent2 = statItemMale ? ChartService.round(statItemMale.percent2 * 100) : 0;
    const maleSelectivity = statItemMale ? ChartService.round(statItemMale.selectivity * 100) : 0;
    const maleScore = statItemMale ? statItemMale.score : 0;
    const maleSize1 = statItemMale ? statItemMale.size1 : 0;
    const maleSize2 = statItemMale ? statItemMale.size2 : 0;
    const maleAudience1 = statItemMale ? statItemMale.audience1 : 0;
    const maleAudience2 = statItemMale ? statItemMale.audience2 : 0;

    //FEMALE
    const femalePercent1 = statItemFemale ? ChartService.round(statItemFemale.percent1 * 100) : 0;
    const femalePercent2 = statItemFemale ? ChartService.round(statItemFemale.percent2 * 100) : 0;
    const femaleSelectivity = statItemFemale ? ChartService.round(statItemFemale.selectivity * 100) : 0;
    const femaleScore = statItemFemale ? statItemFemale.score : 0;
    const femaleSize1 = statItemFemale ? statItemFemale.size1 : 0;
    const femaleSize2 = statItemFemale ? statItemFemale.size2 : 0;
    const femaleAudience1 = statItemFemale ? statItemFemale.audience1 : 0;
    const femaleAudience2 = statItemFemale ? statItemFemale.audience2 : 0;

    //const name = title ? title : (statItem ? statItem.name : "N/A");
    const tooltipMale = document.createElement('div');

    tooltipMale.classList.add('sp-tooltip');
    tooltipMale.innerText = "Selectivity : " + maleSelectivity;
    tooltipMale.hidden = true;
    document.body.appendChild(tooltipMale);


    const tooltipFemale = document.createElement('div');

    tooltipFemale.classList.add('sp-tooltip');
    tooltipFemale.innerText = "Selectivity : " + femaleSelectivity;
    tooltipFemale.hidden = true;
    document.body.appendChild(tooltipFemale);

      return {
        boost: {enabled: true},
        title: {
          text: '<span style=""><span style="font-size: 1.2rem;font-weight: 400;color: #484848">' + title + '</span><br><span style="font-size: 0.9rem;font-weight: 200;color: #8E8E8E">' + subtitle + '</span></span>',
          align: 'left',
          margin: 30,
          useHTML: true,
          style: {
            fontFamily: 'Roboto Condensed',
          }
        },
        subtitle: {
          text: caption,
          margin: 10,
          align: 'center',
          verticalAlign: 'top',
          useHTML: true,

          style: {
            paddingTop: '1rem',
            widthAdjust: 100,
            fontFamily: "Roboto Condensed",
            fontSize: '1rem',
            fontWeight: 200,
            textAlign: 'center'
          }
        },
        caption: {
          text: '<span style="display: flex;justify-content: space-around;width:  1506px">' +
            '<span style="position: relative;">Single</span>' +
            '<span style="">In relationship</span>' +
            '</span>',
          verticalAlign: 'top',
          align: 'center',
          useHTML: true,
          style: {
            paddingTop: '1rem',
            fontFamily: "Roboto Condensed",
            fontSize: '1rem',
            fontWeight: 200,
          }
        },
        chart: {
          style: {
            fontFamily: 'Roboto Condensed',
            fontWeight: 300
          },
          type: 'pie',
          events: {
            render: function() {
              let chart = this;

              let chartParentWidth = chartWidth.querySelector('div').offsetWidth - 20;
              this.setCaption({
                text: '<span style="display: flex;justify-content: space-around;width: '+chartParentWidth+'px">' +
                  '<span style="position: relative;margin-left: 30px">Single</span>' +
                  '<span style="position:relative">In relationship</span>' +
                  '</span>',
              })
              /*
              if (!chart.title1) {
                chart.title1 = chart.renderer.text('Single', chart.plotLeft + chart.plotWidth * 0.25, chart.plotHeight - chart.plotTop / 2 - 10).add()
                chart.title2 = chart.renderer.text('In relationship', chart.plotWidth * 0.75 + chart.plotLeft, chart.plotHeight - chart.plotTop / 2 - 10).add();

              } else {
                chart.title1.attr({
                  x: chart.plotLeft + chart.plotWidth * 0.25,

                })
                chart.title2.attr({
                  x: chart.plotWidth * 0.70 + chart.plotLeft - 10,
                })
              }

               */

              //MALE
              let height_image = behavior.imageHeight * behavior.imageModifier,
                width_image = behavior.imageWidth * behavior.imageModifier,
                newX = chart.plotWidth / 2 + (behavior.xImageCalcOffset) + chart.plotLeft;

              if (behavior.imageUrl && withImage) {
                if (chart.customImg) {
                  chart.customImg.destroy();
                }

                chart.customImg = chart.renderer.image(behavior.imageUrl, '45%', behavior.yImagePosition, width_image, height_image)
                  .attr({
                    zIndex: 4,
                    'margin-left': 'auto',
                    'margin-right': 'auto',
                    'pointer-events': 'none'
                  })
                  .add();
              }
              //For the Inside Text
              if (chart.customImgTextInsideMale) {
                chart.customImgTextInsideMale.destroy();
              }
              const axeXInside = chart.plotWidth * 0.27 + chart.plotLeft;
              const axeYInside = (chart.plotHeight / 2 + chart.plotTop);
              if (withImage) {
                chart.customImgTextInsideMale = chart.renderer.text(malePercent1 + "%", axeXInside, axeYInside * behavior.yAxeInModifier)
                  .attr({
                    align: 'center'
                  })
                  .css({
                    color: '#8AC192',
                    fontSize: '12pt',
                    fontFamily: 'Roboto Condensed',
                    fontWeight: 800
                  }).add();
              } else {
                chart.customImgTextInsideMale = chart.renderer.text(malePercent1 + "%", axeXInside, axeYInside + 9)
                  .attr({
                    align: 'center'
                  })
                  .css({
                    color: '#8AC192',
                    fontSize: '12pt',
                    fontFamily: 'Roboto Condensed',
                    fontWeight: 800
                  }).add();
              }

              const newXOutside = chart.plotWidth * 0.27 + chart.plotLeft,
                newYOutside = chart.plotHeight + chart.plotTop;

              if (chart.customImgTextOutsideMale) {
                chart.customImgTextOutsideMale.destroy();
              }
              chart.customImgTextOutsideMale = chart.renderer.text(maleSelectivity, newXOutside, newYOutside + 10)
                .attr({
                  align: 'center'
                }).on('mousemove', function(e) {
                  const x = e.pageX;
                  const y = e.pageY;

                  tooltipMale.style.left = (x + 10) + 'px';
                  tooltipMale.style.top = (y + 10) + 'px';

                  if (tooltipMale.hidden) tooltipMale.hidden = false;
                }).on('mouseout', function() {
                  tooltipMale.hidden = true;
                })
                .css({
                  fontSize: '12pt',
                  fontFamily: 'Roboto Condensed',
                }).add();

              //FEMALE
              //For the Inside Text
              if (chart.customImgTextInsideFemale) {
                chart.customImgTextInsideFemale.destroy();
              }
              const axeXInsideFemale = chart.plotWidth * 0.74 + chart.plotLeft;

              if (withImage) {
                chart.customImgTextInsideFemale = chart.renderer.text(femalePercent1 + "%", axeXInsideFemale, axeYInside * behavior.yAxeInModifier)
                  .attr({
                    align: 'center'
                  })
                  .css({
                    color: '#8AC192',
                    fontSize: '12pt',
                    fontFamily: 'Roboto Condensed',
                    fontWeight: 800
                  }).add();
              } else {
                chart.customImgTextInsideFemale = chart.renderer.text(femalePercent1 + "%", axeXInsideFemale, axeYInside + 9)
                  .attr({
                    align: 'center'
                  })
                  .css({
                    color: '#8AC192',
                    fontSize: '12pt',
                    fontFamily: 'Roboto Condensed',
                    fontWeight: 800
                  }).add();
              }

              const newXOutsideFemale = chart.plotWidth * 0.75 + chart.plotLeft

              if (chart.customImgTextOutsideFemale) {
                chart.customImgTextOutsideFemale.destroy();
              }
              chart.customImgTextOutsideFemale = chart.renderer.text(femaleSelectivity, newXOutsideFemale, newYOutside + 10)
                .attr({
                  align: 'center'
                }).on('mousemove', function(e) {
                  const x = e.pageX;
                  const y = e.pageY;

                  tooltipFemale.style.left = (x + 10) + 'px';
                  tooltipFemale.style.top = (y + 10) + 'px';

                  if (tooltipFemale.hidden) tooltipFemale.hidden = false;
                }).on('mouseout', function() {
                  tooltipFemale.hidden = true;
                })
                .css({
                  fontSize: '12pt',
                  fontFamily: 'Roboto Condensed',
                }).add();

            },
          }
        },
        responsive: {
          rules: [{
            condition: {
              minHeight: 400
            },
            chartOptions: {
              chart: {
                height: 399,
              },
            }
          }]
        },
        series: [{
          colors: [
            "#6BC9E4",
            "#EBEBEB",
          ],
          innerSize: "90%",
          size: "100%",
          dataLabels: {
            enabled: false,
            softConnector: false
          },
          options: {
            dataVar: 'var1'
          },
          data: [
            ['Benchmark', malePercent2],
            ['Left', 100 - malePercent2],
          ],
          /*
          tooltip: {
            shared: true,
            useHTML: true,
            headerFormat: '<table><tr><th colspan="2"></th></tr>',
            valueDecimals: 2,
            pointFormatter: function() {
              return ChartService.generateTooltip("Single", malePercent1, maleSize1, malePercent2, maleSize2, maleSelectivity, maleScore)
            }
          },

           */
          center: ['25%', '50%'],
          absolute: maleAudience1
        }, {
          colors: [
            "#8AC192",
            "#EBEBEB",
          ],
          innerSize: "90%",
          size: "90%",

          showInLegend: false,
          dataLabels: {
            enabled: false,
            softConnector: false
          },
          options: {
            dataVar: 'var1'
          },
          data: [
            ['Target', malePercent1],
            ['Left', 100 - malePercent1],
          ],
          /*
          tooltip: {
            shared: true,
            useHTML: true,
            headerFormat: '<table><tr><th colspan="2"></th></tr>',
            valueDecimals: 2,
            pointFormatter: function() {
              return ChartService.generateTooltip("Single", malePercent1, maleSize1, malePercent2, maleSize2, maleSelectivity, maleScore)
            },
          },

           */
          center: ['25%', '50%'],
          absolute: maleAudience2
        },
          //FEMALE
          {
            colors: [
              "#6BC9E4",
              "#EBEBEB",
            ],
            innerSize: "90%",
            size: "100%",
            showInLegend: false,
            dataLabels: {
              enabled: false,
              softConnector: false
            },
            options: {
              dataVar: 'var2'
            },
            data: [
              ['Benchmark', femalePercent2],
              ['Left', 100 - femalePercent2],
            ],
/*
            tooltip: {
              shared: true,
              useHTML: true,
              headerFormat: '<table><tr><th colspan="2"></th></tr>',
              valueDecimals: 2,
              pointFormatter: function() {
                return ChartService.generateTooltip("In relationship", femalePercent1, femaleSize1, femalePercent2, femaleSize2, femaleSelectivity, femaleScore)
              },
            },

 */
            center: ['75%', '50%'],
            absolute: femaleAudience1
          }, {
            colors: [
              "#8AC192",
              "#EBEBEB",
            ],
            innerSize: "90%",
            size: "90%",
            showInLegend: false,
            dataLabels: {
              enabled: false,
              softConnector: false
            },
            options: {
              dataVar: 'var2'
            },
            data: [
              ['Target', femalePercent1],
              ['Left', 100 - femalePercent1],
            ],
            /*
            tooltip: {
              shared: true,
              useHTML: true,
              headerFormat: '<table><tr><th colspan="2"></th></tr>',
              valueDecimals: 2,
              pointFormatter: function() {
                return ChartService.generateTooltip("In relationship", femalePercent1, femaleSize1, femalePercent2, femaleSize2, femaleSelectivity, femaleScore)
              },
            },

             */
            center: ['75%', '50%'],
            absolute: femaleAudience2
          },
        ],

        tooltip: {
          formatter: function() {

            if(this.series.options.options.dataVar == "var1") {
              return ChartService.generateTooltip("Single", malePercent1, maleSize1, malePercent2, maleSize2, maleSelectivity, maleScore);
            }else if (this.series.options.options.dataVar == "var2") {
              return  ChartService.generateTooltip("In relationship", femalePercent1, femaleSize1, femalePercent2, femaleSize2, femaleSelectivity, femaleScore)
            }
          },
          useHTML: true,
          outside: true,
          style: {
            width: 500
          },
          hideDelay: 10
        },


        exporting: {
          enabled: false
        },
        credits: {
          enabled: false
        }
      };

  }

  createBubbleChart(stats: StatItem[],vMetric: MetricType = MetricType.SELECTIVITY, clickCallback = undefined, title ?:string, subtitle?: string): any {
    let vMetricName = vMetric.toString();
    let scoreLine = 100;
    let chartStatData = [];
    stats.forEach(s => {
      //const tags = this.tagManager.getTagsNameFromCriteria(s);
      let sortingScore = 0;
      switch (vMetric) {
        case MetricType.SCORE:
          sortingScore = s.score;
          scoreLine = 60;
          break;
        case MetricType.SELECTIVITY:
          sortingScore = ChartService.round(s.selectivity * 100);
          break;
        case MetricType.TARGET_PEN:
          sortingScore = ChartService.round(s.percent1 * 100);
          break;
        case MetricType.BENCHMARK_PEN:
          sortingScore = ChartService.round(s.percent2 * 100);
          break;
      }
      chartStatData.push({x: s.size1, y: sortingScore, z: 50, name: s.name, id: s.id });
    });

    return {
      boost: { enabled: true,
        allowForce: true
      },
      title: {
        text: title,
        align: 'left',
        margin: 30,
        style: {
          fontSize: '1.2rem',
          fontFamily: 'Roboto Condensed',
          fontWeight: 400,
          color: '#484848'
        }
      },
      subtitle: {
        text: subtitle,
        margin: 10,
        align: 'left',
        style: {
          fontFamily: "Roboto Condensed",
          fontSize: '0.9rem',
          fontWeight: 200,
          color: '#8E8E8E'
        }
      },
      exporting: {
        enabled: false,
      },
      plotOptions: {
        series: {
          stickyTracking: false,
          dataLabels: {
            enabled: true,
            format: '{point.name}'
          },
          events: {
            click: (event) => {
              const id = event.point.options.id;
              if (clickCallback) clickCallback(id);
            }
          }
        }
      },
      legend: {
        enabled:false
      },
      credits: {
        enabled: false
      },
      series: [{
        turboThreshold: 0,
        _colorIndex: 0,
        _symbolIndex: 0,
        type: "bubble",
        marker: {
          symbol: "circle",
          enabled: true
        },
        colorByPoint: true,
        dataLabels: {
          style: {
            color: "#484848",
            textOutline: "",
            fontSize: "10px"
          }
        },
        data: chartStatData
      }],
      chart: {
        style: {
          fontFamily: 'Roboto Condensed',
          fontWeight: 300
        },
        type: 'bubble',
        plotBorderWidth: 1,
        zoomType: 'xy',
        events: {
          load: function() {
            switch (vMetric) {
              case MetricType.SCORE:
                this.update({
                  yAxis: {
                    min: 0,
                    max : 100,
                  },
                })
                break;
            }
          }
        }
      },
      xAxis: {
        index: 0,
      },
      yAxis: {
        plotLines: [{
          value: scoreLine,
          color: '#EF6521',
          zIndex: 1.67
        }],
        plotBands: [{ // mark the Min periods
          color: '#f2f5fd',
          from: -10000,
          to: scoreLine//minperiods //- 1
        }, {
          color: '#f7fff5',
          from: scoreLine, //insightsStore.getCount() - maxperiods - 1,
          to: 10000 //insightsStore.getCount() - 1
        }],
        index: 0,
        title: {
          text: vMetricName
        },
      },
      colors: [
        "#EF6521",
        "#434348",
        "#90ed7d",
        "#f7a35c",
        "#8085e9",
        "#f15c80",
        "#e4d354",
        "#2b908f",
        "#f45b5b",
        "#91e8e1"
      ],
      /*
      legend: {
        labelFormat: "Size"
      },

       */
      accessibility: {
        point: {
          valueDescriptionFormat: '{index}. {point.name}, Affinity: {point.y}, Size: {point.x}, Score: {point.z}.'
        }
      },
      tooltip: {
        shared: true,
        split: false,
        enabled: true,

        formatter: function() {
          if (this.point) {
            const index = this.point.index;
            const name = this.point.name;
            const percent1 = ChartService.round(stats[index].percent1 * 100);
            const percent2 = ChartService.round(stats[index].percent2 * 100);
            const size1 = stats[index].size1;
            const size2 = stats[index].size2;
            const selectivity = ChartService.round(stats[index].selectivity * 100);
            const score = stats[index].score;

            //let tagsCrit = "<br><span style='color:" + '#EF6521' + ";'>\u25CF</span> Related tags <strong>" + this.point.tags + "</strong>";
            /*if(this.point.tags) {
             // "<br><span style='color:" + '#000000' + ;' + " Related tags: "  + tagsCrit;
             // if (score && env.values.dev) content += "<span style='color:" + '#000000' + ";'>\u25CF</span> Score: <strong>" + score + "</strong>";
              return ChartService.generateTooltip(name, percent1, size1, percent2, size2, selectivity, score) + tagsCrit ;
            } else {
              return ChartService.generateTooltip(name, percent1, size1, percent2, size2, selectivity, score);
            }*/
            return ChartService.generateTooltip(name, percent1, size1, percent2, size2, selectivity, score);
          } else {
            return "N/A";
          }
        },
        useHTML: true
      },
    };
  }

  createMapChart(mapGeoData: MapGeoData, mapDataHandler: MapDataHandler, level: MapLevel, valueToShow: string, extraDrillCallback = undefined, canDrillDown: boolean = true) {
    const mapVersion: number = mapGeoData.version;
    const map: any = (Highcharts as any).geojson(mapGeoData.getLevel(level));
    const childGeo: any = mapGeoData.children;
    const drilldownData = [];
    const idKey = mapVersion < 2 ? "ins" : "osm-relation-id";
    map.forEach(m => {
      const id = m.properties[idKey];
      const data = mapDataHandler.getData(id);
      m.drilldown = id;
      if (data) {
        m.signedPer1= ChartService.round(data.signedPer1);
        m.targetSize = data.targetSize;
        m.targetPer = ChartService.round(data.targetPer);
        m.relativeDiff = ChartService.round(data.relativeDiff);
        m.benchmarkSize = data.benchmarkSize;
        m.benchmarkPer = ChartService.round(data.benchmarkPer);
        m.selectivity = ChartService.round(data.selectivity);
        m.score = data.score;

        if (valueToShow == "diff") {
          m.value = data.relativeDiff;
        } else if (valueToShow == "percent") {
          m.value = data.targetPer;
        }
      }
    });
    const drillUpDownCallBack = function(e) {
      if(e.seriesOptions) {
        let rawData = e.seriesOptions.data
        const chart = e.target;
        if (chart) {
          chart.colorAxis[0].update(setupColorAxis(rawData));
          chart.redraw();
        }
      }
    };
    const setupColorAxis = function(rawData) {
      const values = rawData
        .filter(data=> {return data.value})
        .map(data=> {return data.value});
      let minValue = values.length > 0 ? Math.min.apply(null,values) : 0;
      let maxValue = values.length > 0 ? Math.max.apply(null,values) : 0;

      let scaleMin;
      let scaleMax;
      let stops;

      if (minValue < 0) {
        if (maxValue > 0) {
          scaleMax = Math.max(-minValue, maxValue);
          scaleMin = -scaleMax;
          stops = [
            [0, '#25a6bf'],
            [0.5, '#ffffff'],
            [1, '#83c795']
          ];
        } else {
          scaleMin = minValue;
          scaleMax = 0;
          stops = [
            [0.,'#25a6bf'],
            [1 ,'#ffffff']
          ];
        }
      } else if(minValue == 0 && maxValue == 0) {
        scaleMax = 1;
        scaleMin = 0;
        stops = [
          [0, '#a4a4a4'],
          [1, '#83c795']
        ];
      } else {
        scaleMax = maxValue;
        scaleMin = 0;
        stops = [
          [0, '#a4a4a4'],
          [1, '#83c795']
        ];
      }

      return {
        stops : stops,
        min : scaleMin,
        max: scaleMax,
        type: 'linear',
        startOnTick: false,
        endOnTick:false
      };

    };
    if (childGeo) {
      Object.entries(childGeo).forEach(([key, value]) => {
        const geoJson = (Highcharts as any).geojson(<any>value);
        geoJson.forEach(g => {
          const id = g.properties[idKey];
          const data = mapDataHandler.getData(id);
          g.drilldown = id;
          if (data) {
            g.signedPer1 = ChartService.round(data.signedPer1);
            g.relativeDiff = ChartService.round(data.relativeDiff);
            g.targetSize = data.targetSize;
            g.targetPer = ChartService.round(data.targetPer);
            g.benchmarkSize = data.benchmarkSize;
            g.benchmarkPer = ChartService.round(data.benchmarkPer);
            g.selectivity = ChartService.round(data.selectivity);
            g.score = data.score;

            if (valueToShow == "diff") {
              g.value = data.relativeDiff;
            } else if (valueToShow == "percent") {
              g.value = data.targetPer;
            }
          }
        });

        drilldownData.push({
          id: key,
          name: mapDataHandler.getData(key) ? mapDataHandler.getData(key).name : "NOT_FOUND!",
          data: geoJson
        });
      });
    }
    const mapChart: any = {
      boost: { enabled: true },
      title: {
        text: ''
      },
      chart: {
        spacing: [30, 50, 35, 30],
        events: {
          drilldown: (e) => {
            if (e.seriesOptions) {
              drillUpDownCallBack(e);
              if (extraDrillCallback) extraDrillCallback(e);
            }
          },
          drillup: (e) => {
            drillUpDownCallBack(e);
            if (extraDrillCallback) extraDrillCallback(e);
          }
        }
      },
      colorAxis: setupColorAxis(map),
      chartType: 'map',
      credits: false,

      exporting: {
        enabled: false,
        buttons: {
          contextButton: {
            align: 'left',
            x: 0,
            y: 0,
            verticalAlign: 'top'
          }
        },

        sourceWidth: 1000,
        sourceHeight: 600,
        scale: 1,
        chartOptions: {
          subtitle: null
        }
      },
      mapNavigation: {
        enabled: true,
        enableMouseWheelZoom: false,
        buttonOptions: {
          verticalAlign: 'bottom'
        }
      },
      tooltip: {
        shared: true,
        split: false,
        enabled: true,

        formatter: function() {
          const point = this.point;

          let content = ""

          if (point) {
            const score = point.score;
            content = ChartService.generateTooltip(point.name, point.targetPer, point.targetSize, point.benchmarkPer, point.benchmarkSize, point.selectivity, score)
          } else {
            content = "Data is missing"
          }

          return content;
        },
        useHTML: true,
        hideDelay: 10
      },
      series: [{
        data: map,
        name: "All"
      }],
      plotOptions: {
        map: {
          dataLabels: {
            format:  '{point.name}',
            allowOverlap: true,
            enabled: level != MapLevel.LEVEL_4,
          },
          states: {
            hover: {
              color: '#D3D4D3'
            }
          }
        }
      },
    };
    if (canDrillDown) {
      mapChart.drilldown = {
        activeDataLabelStyle: {
          color: '#FFFFFF',
            textDecoration: 'none',
            textShadow: '0 0 3px #000000'
        },
        drillUpButton: {
          style: {
            fontSize: '12pt',
              fontFamily: 'Roboto Condensed',
              fontWeight: 300
          },
          relativeTo: 'plotBox',
            position: {
              x: 0,
              y: 0
          },
          theme: {
            fill: '#f06622',
              'stroke-width': 0,
              r: 3,
              padding: 10,
              style: {
              color: '#fff',
                cursor: 'pointer'
            },
            states: {
              hover: {
                fill: '#f48c59'
              },
              select: {
                fill: '#f48c59'
              }
            }
          }
        },
        series: drilldownData
      };
    } else {
      mapChart.drilldown = {
        activeDataLabelStyle: {
          color: '#FFFFFF',
          textDecoration: 'none',
          textShadow: '0 0 3px #000000'
        }
      };
    }
    return mapChart;
  }

  createColumnChart(stats: StatItem[], metric: MetricType = MetricType.SELECTIVITY, title?: string): any {
    const primaryColor = this.styles.primary;
    const primaryLightColor = this.styles.primarylight;
    return {
      chart: {
        renderTo: 'container',
        type: 'bar',
        style: {
          fontFamily: 'Roboto Condensed',
          fontWeight: 300
        }
      },
      boost: { enabled: true,
        allowForce: true},
      title: {
        text: title,
        margin: 30,
        style: {
          fontSize: '12pt',
          fontFamily: 'Roboto Condensed',
          fontWeight: 300
        }
      },
      xAxis: {
        categories: stats.map(s => s.name)
      },
      yAxis: {
        plotLines: [{
          value: 60,
          color: primaryColor,
          zIndex: 1.67
        }],
        min: 0,
        title: {
          text: ''
        },
      },
      plotOptions: {
        series: {
          color: primaryColor,
          pointPadding: 0.2
        },
        bar: {
          dataLabels: {
            enabled: true
          }
        }
      },
      credits: false,
      legend: {
        backgroundColor: '#FFFFFF',
        reversed: true,
        enabled:false,
      },
      exporting: {
        enabled: false
      },
      tooltip: {
        shared: true,
        split: false,
        enabled: true,

        formatter: function() {
          const index = this.points[0].point.index;

          const percent1 = ChartService.round(stats[index].percent1 * 100);
          const percent2 = ChartService.round(stats[index].percent2 * 100);
          const size1 = stats[index].size1;
          const size2 = stats[index].size2;
          const selectivity = ChartService.round(stats[index].selectivity * 100);
          const score = stats[index].score;
          const name = this.x;

          return ChartService.generateTooltip(name, percent1, size1, percent2, size2, selectivity, score);
        },
        useHTML: true
      },
      series: [{
        name: 'Persona',
        data: stats.map(s => {
          switch(metric) {
            case MetricType.SCORE:
              return s.score;
            case MetricType.SELECTIVITY:
            default:
              return ChartService.round(s.selectivity * 100);
          }
        })
      }]
    };
  }

  createHistogramChart(stats: StatItem[], metrics: MetricType[] = [MetricType.TARGET_PEN, MetricType.BENCHMARK_PEN], title?: string, subtitle?: string, caption?: string, chartType?: string): any {
    const categories = stats.map(d => d.name);

    let chartToUse ;
    if(chartType) {
      chartToUse = "bar"
    } else {
      chartToUse = "column"
    }
    const data = metrics.map(m => {
      return {
        //name: "",
        data: stats.map(s => {
          switch (m) {
            case MetricType.TARGET_PEN:
              return ChartService.round(s.percent1 * 100);
            case MetricType.BENCHMARK_PEN:
              return ChartService.round(s.percent2 * 100);
            case MetricType.TARGET_SIZE:
              return s.size1;
            case MetricType.BENCHMARK_SIZE:
              return s.size2;
            case MetricType.SELECTIVITY:
              return s.selectivity * 100;
            case MetricType.SCORE:
              return s.score;
            default:
              this.logger.logWarning("Default metrics applied to histogram chart data");
              return s.size1;
          }
        }),
        showInLegend: false
      }
    });

    return {
      chart: {
        type: chartToUse,
        style: {
          fontFamily: 'Roboto Condensed',
          fontWeight: 300
        },
        zoomType: false,
        events: {
          load: function() {
            metrics.map( m => {
              switch (m) {
                case MetricType.SCORE:
                  this.update({
                    yAxis: {
                      max : 100,
                    },
                  })
                  break;
              }
            })
          }
        },
      },

      responsive: {
        rules: [{
          condition: {
            maxWidth: 500
          },
          chartOptions: {
            legend: {
              align: 'center',
              verticalAlign: 'bottom',
              layout: 'horizontal'
            },
            yAxis: {
              labels: {
                align: 'left',
                x: 0,
              },
            },
            credits: {
              enabled: false
            }
          }
        }]
      },
      exporting: {
        enabled: false
      },
      tooltip: {
        shared: true,
        split: false,
        enabled: true,

        formatter: function() {
          const index = this.points[0].point.index;

          const name = this.x;
          const percent1 = ChartService.round(stats[index].percent1 * 100);
          const percent2 = ChartService.round(stats[index].percent2 * 100);
          const size1 = stats[index].size1;
          const size2 = stats[index].size2;
          const selectivity = ChartService.round(stats[index].selectivity * 100);
          const score = stats[index].score;

          return ChartService.generateTooltip(name, percent1, size1, percent2, size2, selectivity, score)
        },
        useHTML: true
      },
      credits: {
        enabled: false
      },
      boost: { enabled: true },
      title: {
        text: title,
        align: 'left',
        //margin: 30,
        style: {
          fontSize: '1.2rem',
          fontFamily: 'Roboto Condensed',
          fontWeight: 400,
          color: '#484848'
        }
      },
      subtitle: {
        text: subtitle,
        //margin: 50,
        align: 'left',
        style: {
          fontFamily: "Roboto Condensed",
          fontSize: '0.9rem',
          fontWeight: 200,
          color: '#8E8E8E'
        }
      },
      caption: {
        text: caption,
        //margin: 10,
        verticalAlign: 'top',
        useHTML: true,
        style: {
          paddingTop:'1rem',
          textAlign: 'center',
          fontFamily: "Roboto Condensed",
          fontSize: '1rem',
          fontWeight: 200,
        }
      },
      xAxis: {
        categories: categories,
      },
      yAxis: {
        allowDecimals: false,
        title: {
          text: ''
        },
      },
      series: data,
      colors: [
        "#8AC192",
        "#6BC9E4",
      ]
    };
  }

  /**
   * Create highcharts sunburst options with extra optional callback for when an item is selected
   * @param criterion
   * @param selectCallback
   * @param drilldownCallback
   */
  createSunburstChart(criterion: TreeNode<PathEntry>[], selectCallback: (ids: number[]) => void, drilldownCallback?: (prev: string, curr: string, currData: any, prevData: any) => void) {
    const colors = [
      '#9e0142',
      '#d53e4f',
      '#f46d43',
      '#fdae61',
      '#fee08b',
      '#e6f598',
      '#abdda4',
      '#66c2a5',
      '#3288bd',
      '#5e4fa2',
      '#7f6dd2',
      '#FFEAC9'
    ];

    const legends: {id: string, type: string, name: string, color: string}[] = [];

    const sunburstData: {path: string[], sunburstPath: string[], pathIds: number[], id: string, entryId: number, parent: string, name: string, visible: boolean, value?: number, color?: string}[] = [];
    sunburstData.push({path: [], sunburstPath: ['0.0'], pathIds: [], id: '0.0', entryId: undefined, parent: '', name: 'Select a category', visible: true})
    const levelCountMap: Map<number, number> = new Map();

    const processElems = (elems: TreeNode<PathEntry>[], level: number, sunburstPath: string[], pathIds: number[], parentId: string, color?: string) => {
      if (!levelCountMap.has(level)) levelCountMap.set(level, 1);
      let counter = levelCountMap.get(level);
      elems.forEach((e, idx) => {
        const eLvl = level + '.' + counter++;
        const pickedColor = colors.length -1 >= idx ? colors[idx] : 'black';

        if (level == 1) {
          legends.push({
            id: eLvl,
            type: 'area',
            name: e.value.name,
            color: pickedColor
          });
        }

        const realColor = level == 1 ? pickedColor : (color ? color : undefined);
        if (e.value.id < 0) sunburstData.push({path: e.value.path, sunburstPath: sunburstPath.concat([eLvl]), pathIds: pathIds.concat([e.value.id]), id: eLvl, entryId: e.value.id, parent: parentId, name: e.value.name, visible: true, value: 1, color: realColor});
        if (e.children && e.children.length > 0) processElems(e.children, level + 1, sunburstPath.concat([eLvl]), pathIds.concat([e.value.id]), eLvl, realColor);
      });
      levelCountMap.set(level, counter);
    };
    processElems(criterion, 1, ['0.0'], [], '0.0'); // levelCountMap, colors, legends, sunburstData);

    // Last selected ID track disabled because of incompatibilities
    // TODO: find a better way ?
    //let lastSelectedId: string;

    //let lastSelectedRootId = '0.0';
    const thisRef = this;
    //let lastRootId: string;
    const selectFct = function(e) {
      e.preventDefault();
      if (e.target.id == '0.0') {
        thisRef.logger.debug("Selection over root, ignoring");
      } else if (e.target.node.children.length == 0) {
        if (e.target.sunburstPath.length >= 2) {
          const backId = e.target.sunburstPath[e.target.sunburstPath.length - 2];
          //lastRootId = backId;
          this.series.chart.series[0].setRootNode(backId);
          selectCallback(e.target.pathIds);
          //if (lastRootId != backId) this.series.chart.series[0].setRootNode(backId);
        }
      }
    };

    const chartu = {
      chart: {
        renderTo: 'sp-sunburst-div',
        backgroundColor: 'transparent'
       //height: '80%'

      },

      xAxis: {
        visible: false,
        title: {
          enabled: false
        },
        labels: {
          enabled: false
        }
      },
      yAxis: {
        title: {
          enabled: false
        },
        labels: {
          enabled: false
        }
      },
      boost: {
        enabled: true
      },
      title: {
        text: ''
      },
      credits: {
        enabled: false
      },
      legend: {
        enabled: false
      },
      series: [{
        breadcrumbs: {
          style: {
            display:"none",
          },
          showFullPath: false,
          enabled: false
        },
        allowPointSelect: true,
        type: 'sunburst',
        data: sunburstData,
        //allowDrillToNode: true,
        cursor: 'pointer',
        levels: [
          {
            level: 1,
            color: 'white',
            levelIsConstant: false,
            levelSize: {
              unit: 'percentage',
              value: 50
            },
            dataLabels: {
              style: {
                color: 'black',
                textOverflow: 'clip'
              },

              enabled: true,
              filter: {
                property: 'outerArcLength',
                operator: '>',
                value: 25
              }
            }
          },
          {
            level: 2,
            levelIsConstant: false,
            levelSize: {
              unit: 'percentage',
              value: 20
            },
            dataLabels: {
              enabled: false,
              style: {
                textOverflow: 'clip'
              }
            },
            colorByPoint: true,

          },
          {
            level: 3,

            dataLabels: {
              enabled: false,
              style: {
                textOverflow: 'clip'
              },
            },

          },
          {
            level: 4,

            dataLabels: {
              style: {
                textOverflow: 'clip'
              },
              enabled: false
            },

          },
          {
            level: 5,

            dataLabels: {
              style: {
                textOverflow: 'clip'
              },
              enabled: false
            },

          },
          {
            level: 6,

            dataLabels: {
              style: {
                textOverflow: 'clip'
              },
              enabled: false
            },

          }
        ],
        events: {
          setRootNode: function(e) {
            if (e.newRootId == '0.0' && !e.previousRootId || e.previousRootId == '0.0' && !e.newRootId) e.preventDefault();
            else if (e.newRooId != e.previousRootId) {
              const oldRoot = e.previousRootId != "" ? e.target.data.find(d => d.id == e.previousRootId) : undefined;
              const newRoot = e.target.data.find(d => d.id == e.newRootId);
              if (drilldownCallback) drilldownCallback(e.previousRootId, e.newRootId, newRoot, oldRoot);

              if (oldRoot && e.previousRootId != '0.0') {
                oldRoot.update({
                  color: oldRoot.baseColor ? oldRoot.baseColor : oldRoot.color,
                  dataLabels: {
                    enabled: false
                  }
                });
              }
              if (newRoot) {
                const baseColor = newRoot.color;
                newRoot.update({
                  baseColor: baseColor,
                  color: 'white',
                  dataLabels: {
                    enabled: true
                  }
                });
              } else {
                thisRef.logger.logWarning("Could not find new root id to set label");
              }

              selectCallback(newRoot ? newRoot.pathIds : []);
            }
          }
        },
      }],
      exporting: {
        enabled: false
      },
      plotOptions: {
        sunburst: {
          allowTraversingTree: true,
        },
        series: {
          events: {
            legendItemClick: function(e) {
              e.preventDefault();
            }
          },
          //turboThreshold: 0,
          dataLabels: {
            overflow: 'allow',
            crop: false,
            enabled: true
          },
          point: {
            events: {
              select: selectFct,
              unselect: selectFct
            }
          }
        }
      },
      tooltip: {
        headerFormat: '',
        //backgroundColor: 'transparent',
        pointFormat: environment.config.enableDebugFeatures ? '[D] {point.id} - {point.name}' : '{point.name}',
        useHTML: true,
        formatter: function () {
          if (this.point.node.level == 1) {
            return legends.map(l => {
              return '<div style="background-color: ' + l.color + ' ;width:15px;height:15px;border-radius: 50%;display: inline-flex;transform: translateY(3px)"> </div> <b style="margin-bottom: 5px">' +  l.name + '</b>';
            }).join('<br><br>');

            //return  legends.map(l => l.name).join(', ');
          } else {
            return environment.config.enableDebugFeatures ?  this.point.id + ' - ' + this.point.name : this.point.name;
          }
        }


      }
    }
    legends.forEach(l => {
      (chartu.series as any).push(l);
    });
    return chartu;
  }

  createMapChartFromGeoJson(geoJson: any, clickCallback: (e: any) => void, highlightParentId?: number): any {
    if (!geoJson.projection) geoJson.projection = ChartService.GEO_DEFAULT_OSM;
    const dataWithKeys = {"type": "FeatureCollection", "features": geoJson.features.filter(f => {
        const fbObj = f.properties['fb-keys'];
        const disabled = f.properties.hasOwnProperty('disabled') && f.properties['disabled'];
        return !(fbObj && Object.keys(fbObj).length === 0 && fbObj.constructor === Object) && !disabled;
    })};
    const dataWithoutKeys = {"type": "FeatureCollection", "features": geoJson.features.filter(f => {
        const fbObj = f.properties['fb-keys'];
        const disabled = f.properties.hasOwnProperty('disabled') && f.properties['disabled'];
        return fbObj && Object.keys(fbObj).length === 0 && fbObj.constructor === Object && !disabled;
    })};
    const dataDisabled = {"type": "FeatureCollection", "features": geoJson.features.filter(f => {
      if (!f.properties.hasOwnProperty('disabled')) return false;
      return f.properties['disabled'];
    })};
    const dataWithoutParents = {"type": "FeatureCollection", "features": geoJson.features.filter(f => f.properties['osm-parent-id'] == "-1")};
    const dataHighlighted = highlightParentId ? {type: "FeatureCollection", features: geoJson.features.filter(f => f.properties['osm-parent-id'] == highlightParentId.toString(10))} : undefined;
    const addedGeoJson = geoJson.addedCollection;

    const series: any[] = [{
      data: (Highcharts as any).geojson(dataWithKeys),
      color: '#81b775',
      name: 'FB Keys'
    }, {
      data: (Highcharts as any).geojson(dataWithoutKeys),
      color: '#e0b799',
      name: 'No FB Keys'
    }, {
      data: (Highcharts as any).geojson(dataWithoutParents),
      color: '#faf25c',
      name: 'No parent ID'
    }, {
      data: (Highcharts as any).geojson(dataDisabled),
      color: 'grey',
      name: 'Disabled'
    }];

    if (addedGeoJson) {
      series.push({
        data: (Highcharts as any).geojson(addedGeoJson),
        color: 'red',
        type: 'mapline',
        name: 'Selected zone in other map'
      });
    }

    if (dataHighlighted) {
      series.push({
        data: (Highcharts as any).geojson(dataHighlighted),
        color: 'orange',
        name: 'Highlighted'
      })
    }

    return {
      boost: {enabled: true},
      title: {
        text: 'Imported map editor'
      },
      mapNavigation: {
        enabled: true,
        enableButtons: false
      },
      tooltip: {
        formatter: function() {
          if (this.point) {
            return this.point.name + " | Parent ID " + this.point.properties['osm-parent-id'] + " | Self ID " + this.point.properties['osm-relation-id'];
          } else {
            return "No data available";
          }
        },
        shared: true
      },
      chart: {
        type: 'map',
        proj4: proj4
      },
      series: series,
      plotOptions: {
        map: {
          dataLabels: {
            formatter: function() {
              return this.point?.name ?? "No label";
            },
            allowOverlap: true,
            enabled: geoJson.features.length < 100
          },
          states: {
            hover: {
              color: '#D3D4D3'
            }
          },
          allAreas: false,
          events: {
            click: (e) => clickCallback(e)
          }
        }
      }
    };
  }

  // Utils functions/methods

  /**
   * Decimal based round
   * @param value
   * @param decimals Default to 1. Adds x decimals after comma. 0 is the default Math.round format, 1 is X.X, 2 is X.XX, ...
   */
  static round(value: number, decimals: number = 1): number {
    const decimalPow = Math.pow(10, decimals)
    return Math.round(value * decimalPow) / decimalPow
  }

  /**
   * Decimals separator
   * @param value
   * @param separator
   */
  static separator(value: number, separator: string = ",") {
    return String(value).replace(/(.)(?=(\d{3})+$)/g,'$1' + separator)
  }

  /**
   * Change GeoJSON projection to another using proj4 library
   * @param geoJson source GeoJSON starting from FeatureCollection type
   * @param from must be a valid proj4 tag
   * @param to must be a valid proj4 tag
   */
  static changeGeoJsonProjection(geoJson: any, from: string, to: string) {
    geoJson.projection = to;
    geoJson.features.forEach(f => {
      ChartService.modifyFeatureProjection(f, from, to);
    });
  }
  static modifyFeatureProjection(feature: any, from: string, to: string) {
    const geometry = feature.geometry;
    //console.log(geometry.coordinates);
    geometry.coordinates = geometry.coordinates.map(c => c.map(cc => {
      if (Array.isArray(cc) && cc.length > 0) {
        if (Array.isArray(cc[0])) {
          return cc.map(coords => {
            if (Array.isArray(coords) && coords.length == 2) {
              return proj4(from, to, coords)
            } else {
              return coords;
            }
          });
        } else if (cc.length == 2) {
          return proj4(from, to, cc);
        }
      } else {
        return cc;
      }
    }));
  }
  export2Png(charts: Chart[]) {
    (Highcharts as any).exportCharts(charts)
  }
  export2Pdf(charts: Chart[]) {
    (Highcharts as any).exportCharts(charts, {
      type: "application/pdf"
    });
  }
}
