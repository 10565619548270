<div class="card">
  <div class="card-header">
    <span>Confirm your project</span>
  </div>
  <div class="card-body">
    <div class="row">
     <div class="col-5">
       <div class="sp-card">
         <div class="card">
           <div class="card-header">
             <span class="sp-target-audience">{{ project.audience_target.name }}</span>
           </div>
           <div class="card-body">
             <span>Universes : <span class="sp-element">{{ selectedUniversesToString() }}</span></span><br>
             <span>Age:
               <span *ngIf="audienceTargetSpec.ageMax <65" class="sp-element">{{ audienceTargetSpec.ageMin }}-{{ audienceTargetSpec.ageMax }}</span>
               <span *ngIf="audienceTargetSpec.ageMax >=65" class="sp-element">{{ audienceTargetSpec.ageMin }}-{{ audienceTargetSpec.ageMax }}+</span>
             </span><br>
             <span>Gender: <span class="sp-element">{{ audienceTargetSpec.gendersString() }}</span></span><br>
             <span *ngIf="audienceTargetSpec.geolocations">Geolocation: <span class="sp-element">{{ audienceTargetSpec.geolocString() }}</span></span><br>
             <div *ngFor="let flex of audienceTargetSpec.flex; let i = index"><span>{{ i == 0 ? 'Interests' : 'And' }}: <span [innerHTML]="boldOr(audienceTargetSpec.flexOrString(flex))" class="sp-element"></span></span></div>
             <span *ngIf="audienceTargetSpec.exclusions">Exclude: <span [innerHTML]="boldOr(audienceTargetSpec.flexOrString(audienceTargetSpec.exclusions))" class="sp-element"></span></span><br>
           </div>
           <div class="card-footer">
             <div class="row no-gutters pt-4">
               <div class="col-12">
                 <span class="sp-target-people"><span class="sp-target-number">{{ toNumberFormat(project.audience_target.fb_size) }}</span> people</span>
               </div>
             </div>
             <app-sp-audience-score [audienceTarget]="project.audience_target" [useNewIndicator]="dialogData.useNewIndicator"></app-sp-audience-score>
          </div>
         </div>
       </div>
     </div>
      <div class="col-2 d-flex align-self-center justify-content-center">
       <div class="wrapper">
         <div class="line"></div>
            <div class="wordwrapper">
              <span class="word sp-project-vs">VS</span>
            </div>
         </div>
      </div>
      <div class="col-5">
        <div class="sp-card">
          <div class="card">
            <div class="card-header">
              <span class="sp-benchmark-audience">{{ project.audience_bench.name }}</span>
            </div>
            <div class="card-body">
              <span>Universes : <span class="sp-element">{{ selectedUniversesToString() }}</span></span><br>
              <span>Age:
                <span *ngIf="audienceBenchmarkSpec.ageMax <65" class="sp-element">{{ audienceBenchmarkSpec.ageMin }}-{{ audienceBenchmarkSpec.ageMax }}</span>
                <span *ngIf="audienceBenchmarkSpec.ageMax >=65" class="sp-element">{{ audienceBenchmarkSpec.ageMin }}-{{ audienceBenchmarkSpec.ageMax }}+</span>
              </span><br>
              <span>Gender: <span class="sp-element">{{ audienceBenchmarkSpec.gendersString() }}</span></span><br>
              <span *ngIf="audienceBenchmarkSpec.geolocations">Geolocation: <span class="sp-element">{{ audienceBenchmarkSpec.geolocString() }}</span></span><br>
              <div *ngFor="let flex of audienceBenchmarkSpec.flex; let i = index"><span>{{ i == 0 ? 'Interests' : 'And' }}: <span [innerHTML]="boldOr(audienceBenchmarkSpec.flexOrString(flex))" class="sp-element"></span></span></div>
              <span *ngIf="audienceBenchmarkSpec.exclusions">Exclude: <span [innerHTML]="boldOr(audienceBenchmarkSpec.flexOrString(audienceBenchmarkSpec.exclusions))" class="sp-element"></span></span><br>
            </div>
            <div class="card-footer">
              <div class="row no-gutters pt-4">
                <div class="col-10 d-flex justify-content-start">
                  <span class="sp-benchmark-people"><span class="sp-target-number">{{ toNumberFormat(project.audience_bench.fb_size) }}</span> people</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!--<div *ngIf="(environment.config.dev || !environment.online) && environment.config.enableExperimentalFeatures" class="row no-gutters d-flex pb-4">
    <div class="col-10">
      <mat-form-field class="w-100 sp-form-field" appearance="outline">
        <mat-label class="sp-input-label">Source Type</mat-label>
        <mat-select [(ngModel)]="sourceType">
          <mat-option value="default">System default</mat-option>
          <mat-option value="original">Original size</mat-option>
          <mat-option value="lower">Lower bound</mat-option>
          <mat-option value="upper">Upper bound</mat-option>
          <mat-option value="average">Average of lower and upper</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div class="col-10">
      <mat-form-field class="w-100 sp-form-field" appearance="outline">
        <mat-label class="sp-input-label">Segment Merge Mode</mat-label>
        <mat-select [(ngModel)]="personaMergeMode">
          <mat-option value="include">Include Segment</mat-option>
          <mat-option value="exclude">Exclude Segment</mat-option>
          <mat-option value="do-nothing">Remove Segment</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </div>-->
  <div class="row no-gutters d-flex pb-4">
    <div class="col-12 text-center">
      <h3>Credits for this analysis: 1</h3><br>
      <h6 class="sp-text-secondary">Number of credits left: {{ companySub ? companySub.credits : 'Loading...' }}</h6>
      <h6 class="sp-text-secondary" *ngIf="companySub && companySub.credits_extra > 0">Number of extra credits left: {{ companySub ? companySub.credits_extra : 'Loading...' }}</h6><br>
      <button (click)="onNoClick()" [disabled]="actionPending" class="btn btn-secondary sp-btn sp-btn-secondary" matTooltip="Return to the project creation" matTooltipHideDelay="10" routerLinkActive="active" type="button">
        <span>Cancel</span>
      </button>
      <button (click)="createProject()" [disabled]="actionPending" class="ml-3 btn btn-primary sp-btn sp-btn-primary" matTooltip="Create your project" matTooltipHideDelay="10">
        <span>Confirm</span>
      </button>
    </div>
  </div>

</div>
