import {Component, Inject, OnInit} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {SpModalProjectListFolderCreateComponent} from '../sp-modal-project-list-folder-create/sp-modal-project-list-folder-create.component';
import {MatDialog} from '@angular/material/dialog';
import {Folder} from '../../../../models/folder';
import {Project} from '../../../../models/project';
import {ApiFolderService} from '../../../../services/sp-api/sp-api-folder/api-folder.service';
import {UntypedFormControl} from '@angular/forms';
import {environment} from '../../../../../environments/environment';
import {AlertService} from '../../../../services/sp-alert/alert.service';
import {ProjectManagerService} from "../../../../services/sp-project/project-manager.service";

@Component({
  selector: 'app-sp-modal-project-list-add-to-folder',
  templateUrl: './sp-modal-project-list-add-to-folder.component.html',
  styleUrls: ['./sp-modal-project-list-add-to-folder.component.scss']
})
export class SpModalProjectListAddToFolderComponent implements OnInit {

  selected: Folder = null;
  folders: Folder[];
  myControl = new UntypedFormControl();

  constructor(
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<SpModalProjectListFolderCreateComponent>,
    private alert: AlertService,
    @Inject(MAT_DIALOG_DATA) private data: {project: Project, folders: Folder[]},
    private apiFolder: ApiFolderService,
    private projectManager: ProjectManagerService) {
    this.folders = data.folders
  }

  ngOnInit() {}

  // TODO: Refresh folders and project with api ?
  addToFolder(folder: Folder) {
    if (this.projectManager.openedFolder) {
      const actualFolder = this.projectManager.openedFolder;
      if (actualFolder.projects.find(p => p.id == this.data.project.id)) {
        this.apiFolder.projectRemove(actualFolder, this.data.project).then(() => {
          actualFolder.projects = actualFolder.projects.filter(p => p.id != this.data.project.id);
        });
      }
    }
    this.apiFolder.projectAdd(folder, this.data.project).then(() => {
      folder.projects.push(this.data.project);
      this.projectManager.removeLocalProject(this.data.project);
      this.dialogRef.close()
    }).catch(error => {
      if (environment.config.showErrorModal) {
        this.alert.notify("Error", error.error.message, "error");
      }

      this.dialogRef.close();
    })
  }

  filteredFolders(): Folder[] {
    let filtered = this.data.folders;

    if (this.projectManager.openedFolder) {
      filtered = this.data.folders.filter(f => f.id !== this.projectManager.openedFolder.id)
    }

    return filtered.filter(f => this.selected ? f.id !== this.selected.id : true);
  }

  createFolder() {
    const name = this.myControl.value;

    if (name !== "") {
      const folder = new Folder(name);
      this.apiFolder.postOne(folder).then(folder => {
        this.folders.push(folder);
        this.addToFolder(folder);
      })
    }
  }

  onNoClick(): void {
    this.dialogRef.close();
  }
}
