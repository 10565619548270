import {AfterViewInit, Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {LoaderService} from '../../services/sp-loading/loader.service';
import {CrawlTrackerService} from '../../services/sp-crawl-tracker/crawl-tracker.service';
import {environment} from '../../../environments/environment';
import {SpCustomThemeService} from '../../services/sp-custom-logo/sp-custom-theme.service';

@Component({
  selector: 'app-sp-tools',
  templateUrl: './sp-tools.component.html',
  styleUrls: ['./sp-tools.component.scss']
})
export class SpToolsComponent implements OnInit, AfterViewInit {

  private readonly defaultLogo: string = "assets/images/logos/soprism/new-logo.svg";
  logo: string = this.defaultLogo;

  constructor(public router: Router,
              public loader: LoaderService,
              private crawlTracker: CrawlTrackerService,
              private customTheme: SpCustomThemeService) { }

  ngOnInit() {
    this.customTheme.onLogoChange(img => {
      this.logo = img ? img.image : this.defaultLogo;
    });

    const logoLoaderEl = document.getElementById('sp-logo-loader');
    if (logoLoaderEl) {
      if(this.logo == this.defaultLogo) {
        logoLoaderEl.style.marginLeft = "5px";
      } else {
        logoLoaderEl.style.marginLeft = "0px";
      }
    }
  }
  ngAfterViewInit(){}

  personaProgressBar() {
    if (this.loader.personaLoadingData) {
      return this.crawlTracker.progress(this.loader.personaLoadingData);
    } else {

      return 0;
    }
  }

  test(){

  }



  personaProgress() {

    if (this.loader.personaLoadingData) {
      const progress = this.crawlTracker.progress(this.loader.personaLoadingData);
      return progress > 0 ? progress + " %" : "Loading...";
    } else {
      return "Loading..."
    }
  }

  get env() { return environment; }

}
