import {AfterViewInit, Component, HostListener, OnInit, ViewChild} from '@angular/core';
import {SpThemingService} from '../../../services/sp-theming/sp-theming.service';
import {AuthenticationService} from '../../../services/sp-authentication/authentication.service';
import {Router} from '@angular/router';
import {ApiUserService} from '../../../services/sp-api/sp-api-user/api-user.service';
import {User} from '../../../models/user';
import {UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {ValidationService} from '../../../services/sp-validation/validation.service';
import {ApiRoleService} from '../../../services/sp-api/sp-api-role/api-role.service';
import {ApiCompanyService} from '../../../services/sp-api/sp-api-company/api-company.service';
import {AdAccount} from '../../../models/ad-account';
import {FacebookLinkService} from '../../../services/sp-facebook/facebook-link.service';
import {environment} from '../../../../environments/environment';
import {MatDialog} from '@angular/material/dialog';
import {SpModalFacebookAccountAddComponent} from './sp-modal-facebook-account-add/sp-modal-facebook-account-add.component';
import {NavigationService} from '../../../services/sp-navigation/navigation.service';
import {LoaderService} from '../../../services/sp-loading/loader.service';
import introJs from 'intro.js';
import {SpIntroductionComponent} from '../../sp-project/sp-project-list/sp-introduction/sp-introduction.component';
import {IntroService} from '../../../services/sp-intro/intro.service';
import {Plan} from '../../../models/plan';
import {Company} from '../../../models/company';
import {MatTableDataSource} from '@angular/material/table';
import {Role} from '../../../models/role';
import {RoleUtils} from '../../../utils/role/role-utils';
import {SpModalCompanyRemoveUsersComponent} from '../../sp-admin/sp-admin-company/sp-admin-company-create/sp-modal-company-remove-users/sp-modal-company-remove-users.component';
import {ApiPlanService} from '../../../services/sp-api/sp-api-plan/api-plan.service';
import {MatPaginator} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import {AlertService} from '../../../services/sp-alert/alert.service';
import {SpModalCompanyAddUsersComponent} from '../../sp-admin/sp-admin-company/sp-admin-company-create/sp-modal-company-add-users/sp-modal-company-add-users.component';
import {LoggerService} from '../../../services/sp-logger/logger.service';
import {SpAccountUserEditImageComponent} from './sp-account-user-edit-image/sp-account-user-edit-image.component';
import {Subscription} from "rxjs";
import * as Highcharts from 'highcharts';
import {ChartService} from '../../../services/sp-chart/chart.service';
import {SocketService} from '../../../services/sp-ws/socket.service';
import {ProjectManagerService} from '../../../services/sp-project/project-manager.service';

@Component({
  selector: 'app-sp-user-account-edit',
  templateUrl: './sp-account-user-edit.component.html',
  styleUrls: ['./sp-account-user-edit.component.scss']
})

export class SpAccountUserEditComponent implements OnInit, AfterViewInit {
  first_name: any;
  dataHasChanged = false;
  introJS = introJs();
  neverOpen = true;
  loadedUser: User = undefined;
  // Facebook connect related
  adAccounts: Array<AdAccount> = [];
  fbConnectionPending: boolean = false;
  // fbConnection & fbBusinessConnection => null = default, false = error, true = success
  fbConnection: boolean = null;
  fbBusinessConnection: boolean = null;
  fbAccountImage: string = null;
  fbAccountName: string = null;
  //. ---
  selectedImage: any = null;
  env = environment;
  customPlan: Plan = null;
  plans: Array<Plan> = [];
  subscriptionStartDate: UntypedFormControl;
  subDuration = 12;
  createdCompany: Company = null;
  userForm: UntypedFormGroup;
  subscription: any;
  introIsStarted = false;
  switchRolePending = false;
  selectedPlan: Plan = null;
  monthlyCredits = 0;
  nbrUsers = 0;
  extraCredits = 0;
  searchText= '';
  roles: Array<Role> = [];
  dataSource = new MatTableDataSource<User>([]);
  isNotCreated = true;
  displayedColumns: string[] = [
    'image',
    'last_name',
    'email'
  ];
  editMode = false;

  updatingInfo = false;

  // Permissions
  isCompanySuperAdmin: boolean = false;
  canManageUsers: boolean = false;
  //. ----

  private subscriptions: Subscription[] = [];

  // User IDs of already resent invitation
  invitationSent: number[] = [];
  invitationPending: number[] = [];


  freemium = false;
  trial = false;
  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  @ViewChild(MatSort, {static: true}) sort: MatSort;

  // Charts
  private chartCredits: Highcharts.Chart;
  private chartCreditsExtra: Highcharts.Chart;
  private chartUsers: Highcharts.Chart;

  // Seed
  seedSelection: number[] = [8247, 4712, 6569, 5153, 5165];
  seedSelected?: number;

  // Constructor.
  constructor(private _spTheming: SpThemingService,
              private router: Router,
              public auth: AuthenticationService,
              private apiUser: ApiUserService,
              private apiRole: ApiRoleService,
              private apiCompany: ApiCompanyService,
              private validation: ValidationService,
              private dialog: MatDialog,
              private formBuilder: UntypedFormBuilder,
              private navigation: NavigationService,
              private loader: LoaderService,
              private fb: FacebookLinkService,
              private chart: ChartService,
              private introService: IntroService,
              private apiPlan: ApiPlanService,
              private alert: AlertService,
              private logger: LoggerService,
              private socket: SocketService,
              private pm: ProjectManagerService) {
    // Set the theme to use.
    this._spTheming.setSpThemeName('dashboard-desk');
    //this.introService.setCurrentArea(IntroArea.ACCOUNT_EDIT);

    this.subscription = this.introService.introStarted$.subscribe((introStarted) => {
      if (introStarted) {
        this.introJS.start();
      }
    });

    this.introJS.setOptions({
      steps: [
        {
          element: '#step1',
          intro: ' This section allows you to <strong> update </strong> your <strong> personal information </strong> and <strong> your password </strong>. To update your <strong> company information </strong>, contact our <strong>support team. </strong>',
          position: 'bottom',
        },
        {
          element: '#step2',
          intro: ' Here, you can <strong> update </strong> the <strong> ads accounts </strong> linked to SOPRISM in order to <strong> retrieve the related audiences. </strong>',
          position: 'bottom'
        },
        {
          element: '#step3',
          intro: ' Thanks to this section, you can <strong> track </strong> the <strong> credits left </strong> to your <strong>company. </strong>',
          position: 'bottom'
        },
        {
          element: '#step4',
          intro: 'Here you can <strong> manage your team.</strong> You can <strong> add or remove </strong> a new member to your team and <strong> assign </strong> different <strong>roles. </strong>',
          position: 'bottom'
        },
      ],
      showProgress: false,
      disableInteraction: true,
      hideNext: true
    });
    const mwa = this;
    this.introJS.onexit(function() {
      const currentStep = this._currentStep + 1;
      if (currentStep < this._options.steps.length) {
        mwa.introService.autoStart = false;
      }
    });

    // Loading actual normalization seed if any
    this.seedSelected = this.pm.seed;
  }
  // On init.
  ngOnInit() {
    this.loader.load();
    this.navigation.adBlockDetectionLaunch().then();

    this.auth.permissionCheck("company.admin").then(authorization => {
      this.isCompanySuperAdmin = authorization;
    });
    if (this.auth.session.authenticated) {
      this.freemium = this.auth.session.user.role.level <= RoleUtils.freemiumLevel;
      this.trial = this.auth.session.user.role.level == RoleUtils.trialLevel
    }

    this.auth.permissionCheck("company.self.user.manage").then(authorization => {
      this.canManageUsers = authorization;
      if (authorization) this.displayedColumns.push("type", "actions");
      else this.displayedColumns.push("type-user");
    });

    this.userForm = this.formBuilder.group({
      first_name: ['', [
        Validators.required,
        Validators.maxLength(50)
      ]],
      last_name: ['', [
        Validators.required,
        Validators.maxLength(50)
      ]],
      email: ['', {
        validators: [
          Validators.required
        ],
        updateOn: "blur"
      }],
      passwordChange: ['', [
        Validators.minLength(8),
        Validators.pattern(/^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{8,}$/)
      ]],
      passwordConfirm: [''],
      company: ['']
    }, {
      validators: this.validation.passwordMatchValidator('passwordChange', 'passwordConfirm')
    });

    this.userForm.controls['passwordConfirm'].disable();

    this.subscriptionStartDate = new UntypedFormControl(new Date(), [
      Validators.required
    ]);

    const loginSub = this.fb.onLogin(() => {
      this.checkFacebookLink();
    });

    const logoutSub = this.fb.onLogout(() => {
      this.fbAccountImage = null;
      this.fbAccountName = null;
      this.checkFacebookLink();
    })

    this.subscriptions.push(loginSub, logoutSub);
  }

  ngAfterViewInit() {
    this.apiUser.getOne(this.auth.session.user.id).then(user => {
      this.loadedUser = user;

      this.apiCompany.getOne(this.loadedUser.company.id).then(company => {
        //Loading details of the company
        this.adAccounts = user.ad_accounts;
        this.checkFacebookLink();
        this.loadedUser.company = company;

        let userLeft = this.loadedUser.company.subscription.users - this.loadedUser.company.users.length;
        this.chartCredits = Highcharts.chart('container',this.chart.createUserCreditsPieChart(this.loadedUser.company.subscription.credits,this.loadedUser.company.subscription.plan.credits,"Credits",this.loadedUser.company.subscription.credits + " credits left"))
        this.chartCreditsExtra = Highcharts.chart('container-2',this.chart.createUserCreditsPieChart(this.loadedUser.company.subscription.credits_extra,this.loadedUser.company.subscription.credits_extra_total,"Extra credits",this.loadedUser.company.subscription.credits_extra + " credits left"))
        this.chartUsers = Highcharts.chart('container-3',this.chart.createUserCreditsPieChart(userLeft,this.loadedUser.company.subscription.users,"Users",userLeft + " users left",true));

        this.initUserForm(this.loadedUser);

        this.loader.loaded();

        this.apiPlan.getAll().then((plans: Plan[]) => {
          this.plans = plans;
          if (this.canManageUsers) {
            this.apiRole.getAll().then((roles: Role[]) => {
              this.roles = roles;
              this.loadCompanyInfo(user.company);
            });
          } else {
            this.loadCompanyInfo(user.company);
          }
        });
      })
    });
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
    this.subscriptions.forEach(s => s.unsubscribe())
  }

  @HostListener('window:resize')
  onResize() {
    this.chartCredits.reflow();
    this.chartCreditsExtra.reflow();
    this.chartUsers.reflow();
  }

  onBusinessLinkClick() {
    if (this.fbConnection) {
      if (this.fbBusinessConnection === false) {
        this.facebookBusinessLogin().then(resp => {
          if (resp.status === 'connected') {
            this.updateFacebookToken();
          }
        });
      }
      else if (this.fbBusinessConnection === null) {
        this.fb.getLoginStatus().then(status => {
          const scopeOk = status.authResponse &&
            status.authResponse.grantedScopes &&
            //status.authResponse.grantedScopes.includes('business_management') &&
            status.authResponse.grantedScopes.includes('ads_management');
          if (status.status === 'connected' && scopeOk) {
            this.openAdAccountDialog();
          } else {
            this.facebookBusinessLogin().then(resp => {
              if (resp.status === 'connected') {
                this.openAdAccountDialog();
              }
            });
          }
        });
      }
      else if (this.fbBusinessConnection === true) {
        this.openAdAccountDialog();
      }
    }
  }

  onSeedSelectionChange(seed: number) {
    this.seedSelected = seed;
    this.pm.seed = this.seedSelected;
  }

  navigateToNewProject(){
    if (this.freemium == true || this.trial) {
      this.router.navigateByUrl('/projects/create/select').then();
    } else if(this.fbConnection && this.fbBusinessConnection && this.auth.session.user.ad_accounts.length > 0){
      this.router.navigateByUrl('/projects/create/select').then();
    }
  }

  private checkFacebookLink() {
    this.fb.isLogged().then(logged => {
      if (logged) {
        this.fbConnection = logged;
        /*this.fb.getLoginStatus().then(resp => {
          if (resp.status === 'connected') {
            const scopes = resp.authResponse.grantedScopes;
            const scopesOk = scopes.includes('business_management') && scopes.includes('ads_management');
            const adAccountsOk = this.adAccounts.length > 0;
            const mustRefresh = this.auth.session.user.must_refresh_token
            this.fbBusinessConnection = mustRefresh ? false : (scopesOk && adAccountsOk ? true : null);
          }
        });*/

        this.fb.getPermissions().then(resp => {
          const perms: any[] = resp.data.filter(d => d.status === 'granted').map(d => d.permission);
          const scopesOk = /*perms.includes('business_management') && */perms.includes('ads_management');
          const adAccountsOk = this.adAccounts.length > 0;
          const mustRefresh = this.auth.session.user.must_refresh_token;
          this.fbBusinessConnection = mustRefresh ? false : (scopesOk && adAccountsOk ? true : null);
        });

        this.loadFacebookInfo().then();
      } else {
        this.fbBusinessConnection = logged;
        this.fbConnection = localStorage.getItem(FacebookLinkService.LOCAL_CHECK_KEY) === 'disconnected' ? false : null;
      }
    });
  }

  facebookGetStatus(): void {
    this.logger.debug("Local storage FB info: ", localStorage.getItem(FacebookLinkService.LOCAL_KEY));
    this.fb.getLoginStatus().then(resp => this.logger.debug(resp));
  }

  facebookSimpleLogin(): void {
    if (this.fbConnectionPending) return;
    this.fbConnectionPending = true;
    this.fb.login(false, this.fbConnection === false).then(resp => {
      this.logger.debug(resp)
    }).finally(() => {
      this.fbConnectionPending = false;
    });
  }

  async facebookBusinessLogin() {
    if (this.fbConnectionPending) return;
    this.fbConnectionPending = true;
    try {
      const resp = await this.fb.login(true);
      this.logger.debug(resp);
      return resp;
    } catch (err) {
      throw err;
    } finally {
      this.fbConnectionPending = false;
    }
  }

  facebookLogout(): void {
    this.fb.logout().then();
  }

  facebookUnlink(): void {
    this.fb.unlink().then(resp => {
      // Token must be refresh when session is invalidated
      this.auth.session.user.must_refresh_token = true;
      this.logger.debug(resp)
    });
  }

  facebookApiTestCall(): void {
    this.fb.getUserInfo().then(resp => this.logger.debug(resp)).catch(err => this.logger.logError(err, 1, err));
  }

  updateFacebookToken(): void {
    this.fb.getAdAccounts().then(accounts => {
      const usedAccounts = this.loadedUser.ad_accounts.filter(a => a.selected).map(a => a.facebook_id);
      this.adAccounts = accounts.filter(a => usedAccounts.includes(a.facebook_id));
      if (!this.passwordChanged) this.userForm.controls['passwordConfirm'].setValue(this.userForm.controls['passwordChange'].value);
      this.updateUserInfo();
      this.fbBusinessConnection = true;
      this.userForm.controls['passwordConfirm'].setValue(null);
    }).catch(error => {
      this.logger.logError("Unable to get ad accounts from FB", 3, error);
    });/*.catch(error => {
      if (error.code === 100) this.facebookBusinessLogin().finally(() => this.updateFacebookToken());
    });*/
  }

  async loadFacebookInfo() {
    this.fbAccountImage = await this.fb.getUserPicture();
    //this.fbAccountName = await this.fb.getAccountName();
  }

  resetData() {
    this.initUserForm(this.loadedUser);
    this.dataHasChanged = false;
  }


  loadCompanyInfo(company: Company) {
    this.subscriptionStartDate.setValue(company.subscription.contract_start_at);

    this.monthlyCredits = company.subscription.credits;
    this.extraCredits = company.subscription.credits_extra;
    this.nbrUsers = company.subscription.users;

    let startAt = new Date(company.subscription.contract_start_at);
    let endAt = new Date(company.subscription.contract_expire_at);

    let months: number;
    months = (endAt.getFullYear() - startAt.getFullYear()) * 12;
    months -= startAt.getMonth();
    months += endAt.getMonth();
    months = months <= 0 ? 0 : months;
    this.subDuration = months;

    if(company.subscription.plan.custom) {
      this.customPlan = company.subscription.plan;
      this.selectedPlan = this.customPlan;
    } else {
      this.customPlan = null;
      this.selectedPlan = this.plans.find(p => p.id == company.subscription.plan.id);
    }

    //this.selectedImage = company.image === null ? null : this.env.values.api.storageUrl + company.image;

    this.createdCompany = company;
    this.dataSource.data = this.createdCompany.users;
    this.isNotCreated = false;
    this.editMode = true;
  }
  openIntroDialog(): void {
    this.dialog.open(SpIntroductionComponent,{
      autoFocus: false,
      minHeight:'30rem',
      minWidth:'40rem',
      maxHeight:'60rem',
      maxWidth:'60rem',
    }).afterClosed().subscribe(result => {
      if(result) {
        this.introJS.start();
        this.neverOpen = false;
        this.introIsStarted = true;
      } else {
        this.introIsStarted = false;
      }
    });
  }
  imageChanged = false;
  openUserImageDialog(): void {
    this.dialog.open(SpAccountUserEditImageComponent,{
      autoFocus: false,
      minHeight:'10rem',
      minWidth:'40rem',
      maxHeight:'60rem',
      maxWidth:'60rem',
    }).afterClosed().subscribe(result => {
      if (result) {
        if (typeof result === 'boolean') {
          if (!this.loadedUser.fb_picture || this.loadedUser.image !== this.fbAccountImage) {
            this.loadedUser.fb_picture = true;
            this.loadedUser.image = this.fbAccountImage;
            this.selectedImage = this.fbAccountImage;
            this.dataHasChanged = true;
          }
        } else {
          this.loadedUser.fb_picture = false;
          this.onImageChange(result);
          this.dataHasChanged = true;
        }
      }
    });
  }

  openUserAddDialog() {
      const dialogRef = this.dialog.open(SpModalCompanyAddUsersComponent,{
        maxHeight:'30rem',maxWidth:'40rem', minWidth:'40rem', data: {
          company: this.createdCompany
        }
      });

    dialogRef.afterClosed().subscribe(company => {
      if(company !== null && company !== undefined) {
        this.createdCompany = company;
        this.dataSource.data = this.createdCompany.users;
      }
    });
  }
  firstNameChanged = false;
  lastNameChanged = false;

  updateUserInfo() {
    //this.companyUpdate();
    if(!this.userForm.valid) {
      return;
    }
    this.updatingInfo = true;
    let user = this.buildUserFromForm();
    this.apiUser.updateOne(user)
      .then(user => {
        this.auth.sessionLoad().then();
        this.adAccounts = user.ad_accounts;

        const companyDetails = this.loadedUser.company;
        this.loadedUser = user;
        this.loadedUser.company = companyDetails;
        this.alert.notify("Success", "Your profile has been updated", "success");
        //this.companyUpdate();
        /*setTimeout(() => {
          location.reload();
        }, 1000);*/

      })
      .finally(() =>
        this.updatingInfo = false
      );
    this.dataHasChanged = false;
    this.userForm.get('passwordChange').setValue('');
    this.userForm.get('passwordConfirm').setValue('');
    this.userForm.get('passwordConfirm').disable();
    if (this.createdCompany) this.dataSource.data = this.createdCompany.users;
  }

  subscriptionUpdateDate(): Date {
    if (this.loadedUser) {
      const startSubDate = new Date(this.loadedUser.company.subscription.contract_start_at);
      return new Date(startSubDate.setMonth(startSubDate.getMonth()+1));
    } else {
      return undefined;
    }
  }

  subscriptionEndDate(): Date {
    return this.loadedUser ? new Date(this.loadedUser.company.subscription.contract_expire_at) : undefined;
  }
  emailChanged = false;
  passwordChanged = false;

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  goToUserUpdate(user: User) {
    this.router.navigate(["/admin/users/update/" + user.id], {
      queryParams: {
        'from': 'user-account'
      }
    });
  }
  switchUserCompanyRole(user: User, company: Company, roleId: number) {
    this.switchRolePending = true;

    const role = this.roles.find(r => r.id == roleId);

    this.apiUser.switchCompanyRole(user, company, role)
      .then(() => {
        user.role = role;
        this.dataSource.data = this.createdCompany.users;
      })
      .catch(error => {
        if(this.env.config.showErrorModal) this.alert.notify("Error", error.error.message, "error");
      })
      .finally(() => this.switchRolePending = false);
  }
  onRoleSelectionChange(user: User, roleId: number) {
    this.switchUserCompanyRole(user, this.createdCompany, roleId);
  }

  isRoleDisabled(user: User, role: Role): boolean {
    if(this.createdCompany) {
      if(user.role.level >= RoleUtils.teamAdminLevel && this.createdCompany.users.filter(u => u.role.level >= RoleUtils.teamAdminLevel).length <= 1) {
        return role.level < RoleUtils.teamAdminLevel || role.level > this.auth.session.user.role.level;
      }
      if (this.loadedUser.role.level < RoleUtils.superAdminLevel && role.level <= RoleUtils.trialLevel) {
        return true;
      }

      return role.level > this.auth.session.user.role.level;
    }
  }

  openDeleteDialog(user: User): void {
    const dialogRef = this.dialog.open(SpModalCompanyRemoveUsersComponent,{
      maxHeight:'30rem',maxWidth:'40rem', data: {
        user,
        company: this.createdCompany
      }
    });

    dialogRef.afterClosed().subscribe(company => {
      if(company !== null && company !== undefined) {
        this.createdCompany = company;
        this.dataSource.data = this.createdCompany.users;
      }
    });
  }

  /*companyUpdate() {
    if (this.createdCompany) {
      let company = this.buildCompanyFromInfo(this.createdCompany.id);

      this.apiCompany.updateOne(company).then(company => {
        this.loadCompanyInfo(company);
      });
    }
  }*/

  //TODO: Optimize with model instead of variables ?
  /*buildCompanyFromInfo(companyId?: number) {
    let buildCompany: Company = new Company("");
    if(companyId) buildCompany.id = companyId;

    let startDate: Date = new Date(this.subscriptionStartDate.value);
    startDate.setHours(2); // Adaptation for database | TODO : See for better with timezone manipulation
    let endDate = new Date(startDate);

    endDate.setMonth(endDate.getMonth() + this.subDuration);
    buildCompany.name = this.auth.session.user.company.name.charAt(0).toUpperCase() + this.auth.session.user.company.name.slice(1);
    let subscription = new CompanySubscription(
      this.monthlyCredits,
      this.nbrUsers,
      this.auth.session.user.company.subscription.payment_frequency,
      startDate,
      endDate
    );
    subscription.credits_extra = this.extraCredits;

    if(this.selectedPlan !== null) {
      subscription.plan = _.cloneDeep(this.selectedPlan); // Avoiding by ref value view artifacts (numbers blinking)
      if(this.selectedPlan.custom) {
        subscription.plan.credits = this.monthlyCredits;
        subscription.plan.users = this.nbrUsers;
      }
    } else {
      subscription.plan = new Plan("Custom plan", this.monthlyCredits, this.nbrUsers);
    }

    buildCompany.subscription = subscription;

    return buildCompany;
  }*/

  /*permissionCheck(perm: string) {
    return this.auth.permissionCheck(perm);
  }*/

  openAdAccountDialog(): void {
    const dialogRef = this.dialog.open(SpModalFacebookAccountAddComponent,{
      maxHeight:'32rem',maxWidth:'40rem', minWidth:'40rem',
      data: this.adAccounts
    });

    dialogRef.afterClosed().subscribe(adAccounts => {
      if (adAccounts) {
        if (adAccounts.updated) {
          this.adAccounts = adAccounts.accounts === undefined ? this.adAccounts : adAccounts.accounts;
          this.auth.session.user.ad_accounts = this.adAccounts;
          if (this.adAccounts.length > 0) this.fbBusinessConnection = true;
          else this.fbBusinessConnection = null;
          this.updateAdAccounts();
        }
      }
    });
  }

  initUserForm(user: User) {
    let laravelEmailParams: Map<string, string> = new Map();
    laravelEmailParams.set('own_email', user.email);

    this.userForm.get('first_name').setValue(user.first_name);
    this.userForm.get('last_name').setValue(user.last_name);
    this.userForm.get('email').setValue(user.email);
    this.userForm.get('company').setValue(user.company.name);

    this.userForm.get('email')
      .setAsyncValidators(this.validation.laravelSideValidator("email", "validation/user/email", laravelEmailParams));

    this.userForm.get('company').disable();

    this.adAccounts.forEach(ad => ad.selected = true);

    if (this.loadedUser.image) {
      this.selectedImage = this.env.config.api.storageUrl + this.loadedUser.image;
    }
  }

  buildUserFromForm() {
    let email = this.userForm.get('email').value;
    let firstName = this.userForm.get('first_name').value;
    let lastName = this.userForm.get('last_name').value;
    let password = this.userForm.get('passwordChange').value;

    let user = new User(email, firstName, lastName);
    if (password !== "") {
      user.password = password;
    }
    user.id = this.loadedUser.id;
    user.ad_accounts = this.adAccounts;
    user.image = this.selectedImage;
    user.fb_picture = this.loadedUser.fb_picture;
    return user;
  }

  updateAdAccounts() {
    this.apiUser.updateAdAccounts(this.loadedUser, this.adAccounts)
      .then(ads => {
        this.socket.sendMessageType('user_ad_account_update', {count: ads.length});
        this.adAccounts = ads;
        this.alert.notify("Success", "Your ad accounts have been updated successfully", "success");
      })
      .catch(err => {
        this.logger.logError("Error while updating ad accounts", 3, err);
        this.alert.notify("Error", "An error occurred while updating ad accounts, this has been reported", "error");
      });
  }

  onImageChange(imageInput: HTMLInputElement) {
    const file: File = imageInput.files[0];
    const reader = new FileReader();

    reader.addEventListener('load', (event: any) => {
      this.selectedImage = event.target.result;
      this.imageChanged = true;
      this.dataChangeCheck();
    });

    reader.readAsDataURL(file);
  }

  dataChangeCheck():void{
    if (this.firstNameChanged || this.lastNameChanged || this.emailChanged || this.passwordChanged || this.imageChanged) {
      this.dataHasChanged = true;
    } else {
      this.dataHasChanged = false;
    }
  }
  onFirstNameChange(searchValue: string): void {
    if (this.auth.session.user.first_name === searchValue) {
      this.firstNameChanged = false;
    } else {
      this.firstNameChanged = true;
    }
    this.dataChangeCheck();
  }

  onLastNameChange(searchValue: string): void {
    if(this.auth.session.user.last_name === searchValue) {
      this.lastNameChanged = false;
    } else {
      this.lastNameChanged = true;
    }
    this.dataChangeCheck();
  }

  onEmailChange(searchValue: string): void {
    if(this.auth.session.user.email === searchValue) {
      this.emailChanged = false;
    } else {
      this.emailChanged = true;
    }
    this.dataChangeCheck();
  }

  onPasswordConfirmChange(password: any, confirmPassword: any): void {
    this.passwordChanged = password === confirmPassword;
    //this.passwordChanged = password !== this.loadedUser.password;
    this.dataChangeCheck();
  }

  onPasswordChange(password: string) {
    if (password && password !== '') this.userForm.get('passwordConfirm').enable();
    else this.userForm.get('passwordConfirm').disable();
  }

  sendInvitation(user: User) {
    this.invitationPending.push(user.id);
    this.apiUser.sendInvitation(user).then(() => {
      this.alert.notify('OK!', 'The invitation has been sent successfully', 'success');
      this.invitationSent.push(user.id);
    }).catch(err => {
      this.alert.notify('Error', err.error.message, 'error');
    }).finally(() => {
      this.invitationPending.splice(this.invitationPending.indexOf(user.id), 1);
    })
  }


  get session() {
    return this.auth.session
  }

  protected readonly undefined = undefined;
}
