<div class="">
  <div class="card-header">
    <span>Name your project</span>
  </div>
  <div class="mt-3 ">
    <div class="row">
        <mat-form-field appearance="outline" class="w-100 m-3 sp-form-field">
          <mat-label class="sp-input-label">
            <span>Project Name</span>
          </mat-label>
          <input #projectNameInput [formControl]="projectNameControl" (change)="onProjectNameChange(projectNameInput.value)" class="sp-input-content"  matInput maxlength="25" placeholder="Type project’s name..."/>
          <span class="sp-information-tooltip sp-transform-icon" matSuffix matTooltip="To create you project, please define the name of it." matTooltipHideDelay="10" style="transform: translateY(-3px)">
                    <span class="">i</span>
                 </span>
        </mat-form-field>
    </div>
    <div class="d-flex justify-content-center w-100">
      <p>Credits for this analysis : {{ nbCredits }}</p>
      <p *ngIf="nbCreditsExtra > 0">Extra credits for this analysis : {{ nbCredits }}</p>
    </div>
    <div class="d-flex justify-content-center w-100">
       <p>Number of credits left : {{ nbCredits > 0 ? nbCredits - 1 : nbCredits }}</p>
       <p *ngIf="nbCreditsExtra > 0">Number of credits left : {{ nbCredits <= 0 ? nbCreditsExtra - 1 : nbCreditsExtra }}</p>
    </div>
    <div class="row justify-content-center w-100 mb-3">
      <button (click)="close(undefined)" class="btn btn-secondary sp-btn sp-btn-secondary" matTooltip="Back to projects" matTooltipHideDelay="10"  routerLinkActive="active" type="button">
        <span>Cancel</span>
      </button>
      <button (click)="createProject()" class="ml-3 btn btn-primary sp-btn sp-btn-primary" matTooltip="Update your account" matTooltipHideDelay="10" type="button">
        <span>Confirm your analysis</span>
      </button>
    </div>
  </div>
</div>
