<div *ngIf="useNewIndicator === true" class="row d-flex no-gutters sp-audience-score-container">
  <div class="sp-audience-fb-score" [ngClass]="scoreConfig.get(audienceScoreIndicator)?.containerClass">

    <!-- Size very low or very high -->
    <div class="sp-audience-fb-score-ladder">
      <span *ngIf="audienceScoreIndicator === 'size_too_low'" style="font-size: 18px">🥶</span>
      <span *ngIf="audienceScoreIndicator === 'size_too_high'" style="font-size: 18px">🥵</span>
      <span *ngIf="audienceScoreIndicator !== 'size_too_low' && audienceScoreIndicator !== 'size_too_high'" class="sp-audience-fb-score-dot" [ngClass]="scoreConfig.get(audienceScoreIndicator)?.dotClass"></span>
    </div>

    <!-- Size low or high -->
    <div class="sp-audience-fb-score-ladder">
      <span *ngIf="audienceScoreIndicator === 'size_low'" style="font-size: 18px">😨</span>
      <span *ngIf="audienceScoreIndicator == 'size_high'" style="font-size: 18px">😥</span>
      <span *ngIf="audienceScoreIndicator !== 'size_low' && audienceScoreIndicator !== 'size_high'" class="sp-audience-fb-score-dot" [ngClass]="scoreConfig.get(audienceScoreIndicator)?.dotClass"></span>
    </div>

    <!-- Size good -->
    <div class="sp-audience-fb-score-ladder">
      <span *ngIf="audienceScoreIndicator === 'size_good'" style="font-size: 18px">🙂</span>
      <span *ngIf="audienceScoreIndicator !== 'size_good'" class="sp-audience-fb-score-dot" [ngClass]="scoreConfig.get(audienceScoreIndicator)?.dotClass"></span>
    </div>

    <!-- Size perfect -->
    <div class="sp-audience-fb-score-ladder">
      <span *ngIf="audienceScoreIndicator === 'size_perfect'" style="font-size: 18px">😎</span>
      <span *ngIf="audienceScoreIndicator !== 'size_perfect'" class="sp-audience-fb-score-dot" [ngClass]="scoreConfig.get(audienceScoreIndicator)?.dotClass"></span>
    </div>
  </div>
  <div style="display: flex; align-items: center">
    <span>{{ scoreConfig.get(audienceScoreIndicator)?.msg ?? 'No size information' }}</span>
  </div>
  <div style="display: flex;align-items: center;cursor: pointer">
    <span (click)="getAudienceScoreInformation()" class="material-symbols-outlined" style="color: #EF8251">info</span>
  </div>
</div>
<div *ngIf="useNewIndicator === false" class="row d-flex no-gutters">
  <div>
    <span>Too small</span>
  </div>
  <div [ngClass]="audienceScoreIndicatorLegacy >= 1 ? 'sp-rectangle-color':''" class="m-1 sp-rectangle align-self-center" id="sp-rectangle-1"></div>
  <div [ngClass]="audienceScoreIndicatorLegacy >= 2 ? 'sp-rectangle-color':''" class="m-1  sp-rectangle align-self-center" id="sp-rectangle-2"></div>
  <div [ngClass]="audienceScoreIndicatorLegacy >= 3 ? 'sp-rectangle-color':''" class="m-1 sp-rectangle align-self-center" id="sp-rectangle-3"></div>
  <div [ngClass]="audienceScoreIndicatorLegacy >= 4 ? 'sp-rectangle-color':''" class="m-1 sp-rectangle align-self-center" id="sp-rectangle-4"></div>
  <div [ngClass]="audienceScoreIndicatorLegacy >= 5 ? 'sp-rectangle-color':''" class=" m-1 sp-rectangle align-self-center" id="sp-rectangle-5"></div>
  <div>
    <span>Broad</span>
  </div>
</div>
