import {Component, Inject, OnInit} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {Targeting} from '../../../../classes/targeting/targeting';
import {Subscription} from '../../../../models/subscription';
import {Project} from '../../../../models/project';
import {ApiCompanyService} from '../../../../services/sp-api/sp-api-company/api-company.service';
import {ApiProjectService} from '../../../../services/sp-api/sp-api-project/api-project.service';
import {AuthenticationService} from '../../../../services/sp-authentication/authentication.service';
import {ChartService} from '../../../../services/sp-chart/chart.service';

@Component({
  selector: 'app-sp-modal-project-list-project-details',
  templateUrl: './sp-modal-project-list-project-details.component.html',
  styleUrls: ['./sp-modal-project-list-project-details.component.scss']
})
export class SpModalProjectListProjectDetailsComponent implements OnInit {

  nbProg = 0;
  audienceTargetSpec: Targeting = new Targeting();
  audienceBenchmarkSpec: Targeting = new Targeting();

  companySub: Subscription;

  constructor(
    private dialogRef: MatDialogRef<SpModalProjectListProjectDetailsComponent>,
    private apiCompany: ApiCompanyService,
    private apiProject: ApiProjectService,
    private auth: AuthenticationService,
    @Inject(MAT_DIALOG_DATA) public project: Project) {
    this.audienceTargetSpec = Targeting.fromAudience(project.audience_target);
    this.audienceBenchmarkSpec = Targeting.fromAudience(project.audience_bench);
  }

  ngOnInit() {
    this.apiCompany.getOne(this.auth.session.user.company.id).then(company => {
      this.companySub = company.subscription;
    });


  }

  onNoClick(): void {
    this.dialogRef.close(null);
  }

  selectedUniversesToString() {
    return this.project.audience_target.universes.map(u => u.name).join(", ");
  }

  boldOr(html: string): string {
    return html.replace(/OR/g, "<strong>OR</strong>")
  }

  toNumberFormat(value: number): string {
    return ChartService.separator(value);
  }
}
