import {Component, ElementRef, HostListener, ViewChild} from '@angular/core';
import {SpThemingService} from '../../../services/sp-theming/sp-theming.service';
import {MatDialog} from '@angular/material/dialog';
import {SpModalProjectListShareComponent} from './sp-modal-project-list-share/sp-modal-project-list-share.component';
import {SpModalProjectListAddToFolderComponent} from './sp-modal-project-list-add-to-folder/sp-modal-project-list-add-to-folder.component';
import {Project} from '../../../models/project';
import {AuthenticationService} from '../../../services/sp-authentication/authentication.service';
import {environment} from '../../../../environments/environment';
import {SpModalProjectListFolderCreateComponent} from './sp-modal-project-list-folder-create/sp-modal-project-list-folder-create.component';
import {User} from '../../../models/user';
import {Folder} from '../../../models/folder';
import {SpModalProjectListProjectDetailsComponent} from './sp-modal-project-list-project-details/sp-modal-project-list-project-details.component';
import {SpModalProjectListProjectDeleteComponent} from './sp-modal-project-list-project-delete/sp-modal-project-list-project-delete.component';
import {SpModalProjectListFolderDeleteComponent} from './sp-modal-project-list-folder-delete/sp-modal-project-list-folder-delete.component';
import {ApiProjectService} from '../../../services/sp-api/sp-api-project/api-project.service';
import {ApiFolderService} from '../../../services/sp-api/sp-api-folder/api-folder.service';
import {CrawlTrackerService} from '../../../services/sp-crawl-tracker/crawl-tracker.service';
import {Router} from '@angular/router';
import {ProjectManagerService, ProjectSortType} from '../../../services/sp-project/project-manager.service';
import {SpModalProjectCreditsAddComponent} from './sp-modal-project-credits-add/sp-modal-project-credits-add.component';
import {LoaderService} from '../../../services/sp-loading/loader.service';
import {ChartService} from '../../../services/sp-chart/chart.service';
import {NavigationService} from '../../../services/sp-navigation/navigation.service';
import {AlertService} from '../../../services/sp-alert/alert.service';
import { FacebookLinkService } from '../../../services/sp-facebook/facebook-link.service';
import {debounceTime, switchMap} from 'rxjs/operators';
import {LoggerService} from "../../../services/sp-logger/logger.service";
import {DashboardComponent} from "../../../classes/components/dashboard-component";
import {SocketService} from "../../../services/sp-ws/socket.service";
import {EMPTY, firstValueFrom, Subject} from 'rxjs';
import {ArrayUtils} from "../../../utils/array-utils";
import {ApiDataService} from '../../../services/sp-api/sp-api-data/api-data.service';
import fileSaver from 'file-saver';

@Component({
  selector: 'app-sp-project-list',
  templateUrl: './sp-project-list.component.html',
  styleUrls: ['./sp-project-list.component.scss']
})
export class SpProjectListComponent extends DashboardComponent {
  /// Variable declarations
  // View
  private isViewActive = false;
  private lockScrollListener = false;

  // Projects
  projectReadyCount = 0;
  typeFiltering: string[] = ['project', 'project_shared', 'folder'];
  sortType: ProjectSortType = ProjectSortType.CREATION_DATE;

  // Loader
  hideScrollCard = false;

  // Form/Input
  filterText: string = "";
  titleEditing: HTMLInputElement[] = [];
  isInsideName = false;
  allUsersInProject;
  private openPopup = false;

  // Facebook
  facebookConnected = false;

  // Permissions
  canCreateProject = false;
  hasEnoughCredits = false;
  canAccessDashboard = false;
  canExportToMeta = false;
  canSeeCustomFolder = false;
  private adAccountBypass = false;

  // Subjects
  private projectFilterChangeSubject: Subject<string> = new Subject();

  /**
   *
   * @param theming service - theming
   * @param dialog service - mat dialog
   * @param apiProject service - api project
   * @param apiFolder service - api folder
   * @param apiData
   * @param navigation service - navigation
   * @param crawlTracker service - crawl tracker
   * @param projectManager service - project manager
   * @param auth service - authentication
   * @param loader service - global loader
   * @param alert service - alert
   * @param facebook service - facebook
   * @param logger service - logging
   * @param socket service - websocket
   * @param router angular router
   */
  constructor(
    theming: SpThemingService,
    private dialog: MatDialog,
    private apiProject: ApiProjectService,
    private apiFolder: ApiFolderService,
    private apiData: ApiDataService,
    public navigation: NavigationService,
    public crawlTracker: CrawlTrackerService,
    public projectManager: ProjectManagerService,
    public auth: AuthenticationService,
    public loader: LoaderService,
    private alert: AlertService,
    private facebook: FacebookLinkService,
    private logger: LoggerService,
    private socket: SocketService,
    public router: Router
  ) {
    super(theming, auth)
  }

  private typeFilterChangeSubject: Subject<string[]> = new Subject();
  @ViewChild('title', { static: true }) projectTitles: HTMLInputElement[];

  @ViewChild('scroll', { read: ElementRef }) public scroll: ElementRef;
  /// Constructor.

  // On init.
  async afterInit() {
    this.isViewActive = true;

    await this.permissionCheck();
    await this.facebookConnectionCheck();

    // Check if first project creation status has been changed
    if (!this.auth.session.user.first_project_created && this.projectManager.projectCreatedCount > 0) {
      this.auth.session.user.first_project_created = true;
    }

    // Sending an event for metrics
    if (!this.canCreateProject) this.socket.sendMessageType('project-create-button-unavailable', {});

    // Checking if folders or at least 1 chunk of projects are loaded
    if (this.projectManager.projects.length == 0) {
      this.loader.load('Loading projects');
      this.logger.debug("Project loaded list empty, loading one chunk");
      this.projectManager.loadProjectChunk(this.sortType, this.filterText, this.typeFiltering).then();
    } else {
      this.projectReadyCount = this.projects.length;
      this.hideScrollCard = this.projectCount >= this.projects.length || this.filterText.length > 0;
    }

    if (this.projectManager.folders.length == 0) {
      this.projectManager.loadFolders().then();
    }

    this.registerEvents();
  }

  beforeDestroy() {
    this.isViewActive = false;
  }

  private async permissionCheck() {
    if (this.auth.session.authenticated) {
      this.adAccountBypass = await this.auth.permissionCheck('adaccount.bypass');
      this.canAccessDashboard = await this.auth.permissionCheck('dashboard.access');
      this.canExportToMeta = await this.auth.permissionCheck('targeting.export');
      this.canSeeCustomFolder = await this.auth.permissionCheck('crawl.custom');

      if (this.canBypassProjectCreationRequirements()) this.canCreateProject = true;
      this.hasEnoughCredits = this.auth.session.user.company.subscription.credits > 0 || this.auth.session.user.company.subscription.credits_extra > 0;
    }
  }

  private async facebookConnectionCheck() {
    if (this.canBypassProjectCreationRequirements()) {
      this.canCreateProject = true;
      this.facebookConnected = false;
    } else {
      const co = await this.facebook.isLogged();
      this.facebookConnected = co;
      this.canCreateProject = co && this.auth.session.user.ad_accounts.length > 0;
    }
  }

  private canBypassProjectCreationRequirements() {
    return this.freemium || this.trial || this.adAccountBypass;
  }

  @HostListener('window:scroll')
  loadProjects() {
    // TODO: Change this
    //if (!this.loader.loading && this.projectReadyCount < this.virginProjectLength && !this.openPopup && !this.lockScrollListener) {
    if (this.canLoadProject()) {
      // In chrome and some browser scroll is given to body tag
      const pos = (document.documentElement.scrollTop || document.body.scrollTop) + document.documentElement.offsetHeight;
      const max = document.documentElement.scrollHeight;
      // pos/max will give you the distance between scroll bottom and bottom of screen in percentage.

      if (pos >= (max - 100)) {
        this.lockScrollListener = true;
        this.projectManager.loadProjectChunk(this.sortType, this.filterText, this.typeFiltering).then();
      }
    }
  }

  private onProjectChunkLoaded(projects: Project[]) {
    if (!this.areProjectsComplete(projects)) {
      const checkInterval = setInterval(() => {
        if (this.areProjectsComplete(projects)) {
          clearInterval(checkInterval);
        }
      }, 100);
    }
  }

  private areProjectsComplete(projects: Project[]) {
    const uncompletedProjects = projects.filter(p => !this.projectManager.isProjectCompletelyReady(p));
    const foundUncompletedProject = uncompletedProjects.find(p => !p.audience_target.status || !p.audience_bench.status);
    if (!foundUncompletedProject) {
      if (this.isViewActive) {
        if (this.loader.loading) this.loader.loaded();
        this.hideScrollCard = this.projects.length >= this.projectCount;
      }
      return true;
    } else {
      const loaded = projects.filter(p => p.audience_target.status && p.audience_bench.status).length;
      const notLoaded = projects.filter(p => !p.audience_target.status || !p.audience_bench.status).length;
      this.loader.progress((loaded / notLoaded) * 100);
      return false;
    }
  }

  /**
   * Event - On project search input update
   * @param search
   */
  onProjectSearch(search: string) {
    this.hideScrollCard = false;
    this.projectManager.unloadProjectChunks(true);
    this.projectFilterChangeSubject.next(search);
  }

  /**
   * Execute search filter on projects
   * @param searchText
   * @private
   */
  private onProjectSearchConfirm(searchText: string) {
    this.projectReadyCount = 0;
    this.projectManager.unloadProjectChunks();
    this.projectManager.loadProjectChunk(this.sortType, searchText, this.typeFiltering).then();
  }

  /**
   * Execute type filtering on projects
   * @param types
   * @private
   */
  private onProjectTypeFilterConfirm(types: string[]) {
    if (!ArrayUtils.compareIgnoreOrder(this.typeFiltering, types)) {
      this.typeFiltering = types;
      if (this.typeFiltering.includes('project') || this.typeFiltering.includes('project_shared')) this.hideScrollCard = false;
      this.projectManager.reloadProjectChunks(this.filterText, this.typeFiltering);
      if (types.includes('folder')) this.projectManager.loadFolders().then();
      if (types.includes('folder_custom')) this.projectManager.loadFolders(true).then();
    }
  }

  /**
   * Duplicate a project
   * @param project the project to duplicate
   * @private
   */
  private projectDuplicate(project: Project) {
    this.apiProject.projectDuplicate(project).then(() => {
      const sub = this.auth.session.user.company.subscription;
      if (sub.credits > 0) sub.credits--;
      else sub.credits_extra--;
      if ((sub.credits + sub.credits_extra) <= 0) {
        this.socket.sendMessageType('no_monthly_credits_left', {});
      }
      this.projectManager.reloadProjectChunks(this.filterText, this.typeFiltering);
    });
  }

  recrawlProject(project: Project) {
    this.apiProject.projectCrawl(project).then(() => {
      const sub = this.auth.session.user.company.subscription;
      if (sub.credits > 0) sub.credits--;
      else sub.credits_extra--;
      if ((sub.credits + sub.credits_extra) <= 0) {
        this.socket.sendMessageType('no_monthly_credits_left', {});
      }
      project.audience_target.latest_crawl = null;
      project.audience_target.status = null;
      project.audience_bench.latest_crawl = null;
      project.audience_bench.status = null;
      setTimeout(async () => {
        await this.projectManager.reloadProjectInfo(project);
        this.crawlTracker.addProject(project);
      }, 5000);
      this.socket.sendMessageType('project-refresh', {
        projectName: project.name
      });
    }).catch(error => {
      this.alert.notify('Error', error.error.message, 'error');
    });
  }
  recrawlFolder(folder: Folder) {
    this.apiProject.projectCrawlBatch(folder).then(() => {
      this.alert.notify('Launched!', 'The projects inside this folder has been launched for crawl!', 'info');
    });
  }
  async projectCrawlCancel(project: Project) {
    await this.apiProject.projectCrawlCancel(project);
  }
  criterionAvailable(project: Project) {
    return this.projectManager.isProjectBaseReady(project);
  }

  onTypeFilterChange(newVal: string[]) {
    //this.loader.load();
    this.typeFilterChangeSubject.next(newVal);
  }

  onFolderTitleUpdate(title: string, folder: Folder) {
    if (title !== folder.name) {
      folder.name = title;
      this.apiFolder.updateOne(folder).then(f => {
        folder = f;
      });
    }
  }
  editTitle(title: HTMLInputElement) {
    if (!this.titleEditing.includes(title)) {
      this.titleEditing.push(title);
    }

    setTimeout(() => {
      title.focus();
    }, 10);
  }
  isTitleEditable(title: HTMLInputElement) {
    return this.titleEditing.includes(title);
  }
  onProjectTitleUpdate(title: HTMLInputElement, project: Project) {
    this.titleEditing.splice(this.titleEditing.indexOf(title), 1);

    if (project.name !== title.value) {
      project.name = title.value;
      this.apiProject.updateOne(project).then(p => {
        project = p;
      });
    }
  }
  isProjectCrawling(project: Project) {
    return !this.projectManager.isProjectCompletelyReady(project);
  }
  buttonLoadProject(){
    this.projectManager.loadProjectChunk(this.sortType, this.filterText, this.typeFiltering).then();
  }

  /*filteredFolders(): Folder[] {
    let folders: Folder[];
    folders = this.folders;
    return folders.sort((f1, f2) => {
      switch (this.sortType) {
        case ProjectSortType.ALPHABETIC: return f1.name.localeCompare(f2.name);
        case ProjectSortType.CREATION_DATE: {
          const date1 = new Date(f1.created_at);
          const date2 = new Date(f2.created_at);

          if (date1.getTime() > date2.getTime()) { return 1; } else if (date1.getTime() < date2.getTime()) { return -1; } else { return 0; }
        }
        default: return 0;
      }
    });
  }*/

  changeSortType(sortType: ProjectSortType) {
    this.projectReadyCount = 0;
    this.sortType = sortType;
    this.projectManager.unloadProjectChunks();
    this.projectManager.loadProjectChunk(this.sortType, this.filterText, this.typeFiltering).then();
  }

  selectFolder(folder: Folder) {
    this.filterText = "";
    this.lockScrollListener = true;
    this.hideScrollCard = false;
    this.projectReadyCount = 0;

    this.projectManager.selectFolder(folder);
    this.projectManager.reloadProjectChunks(this.filterText, this.typeFiltering);
    setTimeout(() => {
      this.lockScrollListener = false;
    }, 200);
  }
  openAddToFolderDialog(project: Project): void {
    this.openPopup = true;
    const sub = this.dialog.open(SpModalProjectListAddToFolderComponent, {
      minHeight: '20rem',
      minWidth: '50rem',
      maxHeight: '30rem',
      maxWidth: '60rem',
      data: {project, folders: this.projectManager.folders.filter(f => !f.flag)}
    }).afterClosed().subscribe(() => {
      this.openPopup = false;
      sub.unsubscribe();
    });
  }

  newProjectRedirect(){
    this.router.navigateByUrl('/projects/create/select').then();
  }

  removeProjectFromFolder(project: Project): void {
    const folder = this.projectManager.openedFolder;
    this.apiFolder.projectRemove(folder, project).then(() => {
      folder.projects = folder.projects.filter(p => p.id != project.id);
      this.projectManager.removeLocalProject(project);
    });
  }
  openCreateFolderDialog(): void {
    this.openPopup = true;
    this.filterText = "";
    const dialogRef = this.dialog.open(SpModalProjectListFolderCreateComponent, {
      minHeight: '10rem', minWidth: '30rem', maxHeight: '30rem', maxWidth: '60rem'
    });

    const sub = dialogRef.afterClosed().subscribe(folder => {
      this.openPopup = false;
      if (folder) {
        this.projectManager.folders.push(folder);
      }
      sub.unsubscribe();
    });
  }
  openProjectDetailsDialog(project: Project): void {
    this.openPopup = true;
    const sub = this.dialog.open(SpModalProjectListProjectDetailsComponent, {
      autoFocus: false,
      minHeight: '30rem',
      minWidth: '40rem',
      maxHeight: '60rem',
      maxWidth: '60rem',
      data: project
    }).afterClosed().subscribe(() => {
      this.openPopup = false;
      sub.unsubscribe();
    });
  }
  openDeleteProjectDialog(project: Project): void {
    this.openPopup = true;
    const dialogRef = this.dialog.open(SpModalProjectListProjectDeleteComponent, {
      autoFocus: false,
      minHeight: '10rem',
      minWidth: '30rem',
      maxHeight: '30rem',
      maxWidth: '60rem',
      data: project
    });

    const sub = dialogRef.afterClosed().subscribe(deleted => {
      if (deleted) { this.projectManager.removeLocalProject(project); }
      this.openPopup = false;
      sub.unsubscribe();
    });
  }
  openRefreshProjectCreditsDialog(project: Project): void {
    this.openPopup = true;
    const sub = this.dialog.open(SpModalProjectCreditsAddComponent, {
      autoFocus: false,
      minWidth: '40rem',
      maxHeight: '60rem',
      maxWidth: '60rem',
    }).afterClosed().subscribe(result => {
      this.openPopup = false;
      if (result) {
        this.recrawlProject(project);
      }
      sub.unsubscribe();
    });
  }

  openRefreshFolderDialog(folder: Folder) {
    this.openPopup = true;
    firstValueFrom(this.dialog.open(SpModalProjectCreditsAddComponent, {
      autoFocus: false,
      minWidth: '40rem',
      maxHeight: '60rem',
      maxWidth: '60rem'
    }).afterClosed()).then(result => {
      if (result) {
        this.recrawlFolder(folder);
      }
    }).finally(() => this.openPopup = false);
  }

  openDuplicateProjectCreditsDialog(project: Project): void {
    this.openPopup = true;
    const sub = this.dialog.open(SpModalProjectCreditsAddComponent, {
      autoFocus: false,
      minWidth: '40rem',
      maxHeight: '60rem',
      maxWidth: '60rem',
    }).afterClosed().subscribe(result => {
      this.openPopup = false;
      if (result) { this.projectDuplicate(project); }
      sub.unsubscribe();
    });
  }
  openDeleteFolderDialog(folder: Folder): void {
    this.openPopup = true;
    const sub = this.dialog.open(SpModalProjectListFolderDeleteComponent, {
      autoFocus: false,
      minHeight: '10rem',
      minWidth: '30rem',
      maxHeight: '30rem',
      maxWidth: '60rem',
      data: folder
    }).afterClosed().subscribe(projects => {
      this.openPopup = false;
      if (projects) {
        if (this.projectManager.openedFolder && this.projectManager.openedFolder.id === folder.id) {
          this.projectManager.selectFolder(undefined);
        }
        this.projectManager.removeFolder(folder);
      }
      sub.unsubscribe();
    });
  }
  openShareDialog(project: Project): void {
    this.openPopup = true;
    const dialogRef = this.dialog.open(SpModalProjectListShareComponent, {
      minHeight: '10rem',
      minWidth: '30rem',
      maxHeight: '30rem',
      maxWidth: '60rem',
      data: project});

    const sub = dialogRef.afterClosed().subscribe(users => {
      this.openPopup = false;
      if (users.selected && users.selected.length > 0) {
        project.shared_users = project.shared_users.concat(users.selected);
      }
      if(users.unselected.length > 0) {
        users.unselected.forEach(x => {
          project.shared_users.splice(project.shared_users.indexOf(x),1)
        })
      }

      sub.unsubscribe();
    });
  }

  goToMetaExport(project: Project) {
    const params: any = {
      'name': project.name,
      'targeting': project.audience_target.target_spec,
      'type': 'project'
    };

    if (project.audience_target.type == 'custom' || project.audience_target.type == 'fanpage') {
      const extra = JSON.parse(project.audience_target.data_extra);
      params.adAccountId = extra.accountId;
      params.adAccountName = extra.accountName;
      params.audienceType = project.audience_target.type;
    }

    this.router.navigate(['targeting'], {queryParams: params}).then();
  }
  debugSendProjectCreate(project: Project) {
    this.socket.sendMessageType('user-project-create', {
      projectName: project.name,
      targeting: project.audience_target.target_spec,
      type: project.audience_target.type
    });
  }
  dateToString(date: Date) {
    return new Date(date).toLocaleDateString();
  }
  getProjectUsers(project: Project) {
    const users: User[] = [project.user];
    this.allUsersInProject = users.concat(project.shared_users).slice(3,project.shared_users.length + 1).map(u => u.first_name + " " + u.last_name).join(", ");
    return users.concat(project.shared_users);
  }
  toNumberFormat(value: number) {
    return ChartService.separator(value);
  }
  userInitials(user: User) {
    return user.first_name[0] + user.last_name[0];
  }

  private registerEvents() {
    const projectChunkSub = this.projectManager.projectChunkListener.subscribe(projects => {
      this.lockScrollListener = false;
      this.onProjectChunkLoaded(projects);
    });

    const projectCriteriaDoneSub = this.crawlTracker.crawlCriteriaDoneNotifier.subscribe(() => {
      this.projectReadyCount++;
    });

    const inputSub = this.projectManager.projectListSearchListener.pipe(
      debounceTime(500),
      switchMap(searchText => {
        this.filterText = searchText;
        this.onProjectSearchConfirm(searchText);
        return EMPTY;
      })
    ).subscribe();

    const typeFilterSub = this.typeFilterChangeSubject.pipe(
      debounceTime(500),
      switchMap(types => {
        this.onProjectTypeFilterConfirm(types);
        return EMPTY;
      })
    ).subscribe();

    const typeFilterSub2 = this.projectManager.projectTypeFilterListener.subscribe(types => {
      this.typeFiltering = types;
    });

    this.addSubscription(projectChunkSub, projectCriteriaDoneSub, inputSub, typeFilterSub, typeFilterSub2);
  }

  private canLoadProject() {
    const projectCountCheck = this.projects.length < this.projectCount;
    return !this.loader.loading && projectCountCheck && !this.openPopup && !this.lockScrollListener;
  }

  setProjectMapVersion(project: Project, version: number) {
    this.apiProject.projectMapVersionUpdate(project, version).then(p => {
      this.projectManager.addProject(p);
    });
  }

  clearProjectCache(project: Project) {
    this.apiProject.projectCacheClear(project).then(result => {
      if (result) {
        this.alert.notify("Cache clear", "Project cache cleared!", "success");
        this.clearProjectLocalCache(project);
      }
      else this.alert.notify("Cache clear", "Project cache could not be cleared...", "error");
    }).catch(err => {
      this.alert.notify("Cache clear", "Project cache could not be cleared : " + err.error.message, "error");
    });
  }

  clearProjectLocalCache(project: Project) {
    this.projectManager.clearProjectData(project);
  }

  projectCrawlStatus(project: Project) {
    const target = project.audience_target;
    const bench = project.audience_bench;
    let chosenStatus;

    if (target.status && bench.status) {
      if (target.status.actualStep == 0) chosenStatus = bench.status;
      else if (bench.status.actualStep == 0) chosenStatus = target.status;
      else {
        chosenStatus = target.status.actualStep <= bench.status.actualStep ? target.status : bench.status;
      }
      if (chosenStatus.progression) chosenStatus.loadingStatus = `${chosenStatus.actualStep}/${chosenStatus.steps - 1} - ${chosenStatus.state}`;
      else if (!chosenStatus.loadingStatus) chosenStatus.loadingStatus = chosenStatus.state;
    }

    return chosenStatus;
  }

  exportFolderCsv(folder: Folder) {
    this.apiData.getFolderNormalizedDataCSV(folder).then(data => {
      let blob:any = new Blob([data], { type: 'text/csv; charset=utf-8' });

      fileSaver.saveAs(blob, folder.name + '.csv');
    });
  }

  /// Getters & Setters
  get projects(): Project[] {
    return this.projectManager.projects ? this.projectManager.projects : [];
  }
  get chunkLoading(): boolean {
    return this.projectManager.chunkLoading;
  }
  get projectCount(): number {
    return this.projectManager.projectCount;
  }
  get folders(): Folder[] {
    return this.projectManager.openedFolder ? [] : this.projectManager.folders;
  }

  get ProjectSortType() {
    return ProjectSortType;
  }

  get env() { return environment; }
}
