export class ChatMessage {
  private static nextId: number = 0;

  id: number;
  text: string;
  type: string;
  sender: string;
  avatar: string;
  date: string;
  sending?: boolean;
  resent: boolean = false;
  attachments: string[] = [];

  constructor() {
    ChatMessage.nextId++;

    this.id = ChatMessage.nextId;
  }

  resentCopy() {
    const msg =  new ChatMessage();
    msg.text = this.text;
    msg.type = this.type;
    msg.sender = this.sender;
    msg.avatar = this.avatar;
    msg.date = this.date;
    msg.sending = false;
    msg.resent = true;

    return msg;
  }
}
