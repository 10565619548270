import {Component, Inject, OnInit} from '@angular/core';
import {AuthenticationService} from '../../../../../services/sp-authentication/authentication.service';
import {ProjectCreationManagerService} from '../../../../../services/sp-project/project-creation-manager.service';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {Project} from '../../../../../models/project';
import {ApiProjectService} from '../../../../../services/sp-api/sp-api-project/api-project.service';
import {ProjectManagerService} from '../../../../../services/sp-project/project-manager.service';
import {Router} from '@angular/router';
import {LoggerService} from '../../../../../services/sp-logger/logger.service';
import {AlertService} from '../../../../../services/sp-alert/alert.service';
import {Audience} from '../../../../../models/audience';
import {UntypedFormControl, Validators} from '@angular/forms';
import {SocketService} from "../../../../../services/sp-ws/socket.service";
import {Targeting} from '../../../../../classes/targeting/targeting';

@Component({
  selector: 'app-sp-modal-project-create-audience-modal-name',
  templateUrl: './sp-modal-project-create-audience-modal-name.component.html',
  styleUrls: ['./sp-modal-project-create-audience-modal-name.component.scss']
})
export class SpModalProjectCreateAudienceModalNameComponent implements OnInit {
  nbCredits: number = 0;
  nbCreditsExtra: number = 0;
  projectNameControl: UntypedFormControl = new UntypedFormControl('', Validators.required);

  constructor(
    private auth: AuthenticationService,
    private creationManager: ProjectCreationManagerService,
    private projectManager: ProjectManagerService,
    private dialogRef: MatDialogRef<SpModalProjectCreateAudienceModalNameComponent>,
    private apiProject: ApiProjectService,
    private router: Router,
    private logger: LoggerService,
    private alert: AlertService,
    private socket: SocketService,
    @Inject(MAT_DIALOG_DATA) private data: Project
  ) { }

  ngOnInit() {
    this.auth.getSessionUser().then(user => {
      this.nbCredits = user.company.subscription.credits;
      this.nbCreditsExtra = user.company.subscription.credits_extra;
    });
  }

  onProjectNameChange(name: string) {
    if (name != "") {
      // Trigger Event to false to avoid looping through event subscription
      this.creationManager.changeProjectName(name, false);
      if (this.projectTitleLink) {
        this.audienceTarget.name = name;
        this.audienceBenchmark.name = name + " - Benchmark";
      }
    }
  }

  createProject() {
    if (this.data && this.projectNameControl.valid) {
      this.data.name = this.projectNameControl.value;
      this.apiProject.postOne(this.data).then(project => {
        this.projectManager.projectCreatedCount++;
        const sub = this.auth.session.user.company.subscription;
        if (sub.credits > 0) sub.credits--;
        else sub.credits_extra--;
        if ((sub.credits + sub.credits_extra) <= 0) {
          this.socket.sendMessageType('no_monthly_credits_left', {});
        }
        this.dialogRef.close(project);
        let audienceTargetCriteriaCount = 0;
        const targeting = Targeting.fromJson(project.audience_target.target_spec, project.audience_target.type);
        targeting.flex?.forEach(flex => {
          flex.forEach(arr => {
            audienceTargetCriteriaCount += arr.length;
          })
        });
        this.socket.sendMessageType('user-project-create', {
          projectName: project.name,
          targeting: project.audience_target.target_spec,
          type: project.audience_target.type,
          fromPreset: project.audience_target.from_preset,
          criteriaCount: audienceTargetCriteriaCount,
          fbScore: project.audience_target.fb_score
        });

        this.router.navigate(['/projects']).then()
      }, error => {
        this.logger.logError("Could not create project : " + error.message, 2);
        this.alert.notify("Error", error.error.message, "error");
        this.dialogRef.close(null);
      })
    } else if (!this.data) {
      this.logger.logError("No project data has been sent for modal input", 2);
    }
  }

  close(result: any) {
    this.dialogRef.close(result);
  }

  get projectTitleLink(): boolean { return this.creationManager.projectTitleLink; }
  get audienceTarget(): Audience { return this.creationManager.audienceTarget; }
  get audienceBenchmark(): Audience { return this.creationManager.audienceBenchmark; }

}
