import {AfterViewInit, Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {Targeting} from "../../../../classes/targeting/targeting";
import {ApiCompanyService} from "../../../../services/sp-api/sp-api-company/api-company.service";
import {Subscription} from "../../../../models/subscription";
import {AuthenticationService} from "../../../../services/sp-authentication/authentication.service";
import {Project} from "../../../../models/project";
import {ApiProjectService} from "../../../../services/sp-api/sp-api-project/api-project.service";
import {Router} from "@angular/router";
import {ChartService} from '../../../../services/sp-chart/chart.service';
import {AlertService} from '../../../../services/sp-alert/alert.service';
import {LoggerService} from '../../../../services/sp-logger/logger.service';
import {ProjectManagerService} from '../../../../services/sp-project/project-manager.service';
import {environment} from '../../../../../environments/environment';
import {SocketService} from "../../../../services/sp-ws/socket.service";
import {element} from 'protractor';
import * as console from 'console';

@Component({
  selector: 'app-sp-modal-project-create-details',
  templateUrl: './sp-modal-project-create-details.component.html',
  styleUrls: ['./sp-modal-project-create-details.component.scss']
})
export class SpModalProjectCreateDetailsComponent implements OnInit, AfterViewInit {
  audienceTargetSpec: Targeting = new Targeting();
  audienceBenchmarkSpec: Targeting = new Targeting();

  companySub: Subscription;
  sourceType: string = "default";
  personaMergeMode: string = "include";
  actionPending: boolean = false;
  audienceScoreMessage: string;

  project: Project;

  constructor(
    @Inject(MAT_DIALOG_DATA) public dialogData: {project: Project, useNewIndicator: boolean},
    private dialogRef: MatDialogRef<SpModalProjectCreateDetailsComponent>,
    private apiCompany: ApiCompanyService,
    private apiProject: ApiProjectService,
    private auth: AuthenticationService,
    private router: Router,
    private alert: AlertService,
    private logger: LoggerService,
    private projectManager: ProjectManagerService,
    private socket: SocketService) {
    this.project = this.dialogData.project;
    this.audienceTargetSpec = Targeting.fromAudience(dialogData.project.audience_target);
    this.audienceBenchmarkSpec = Targeting.fromAudience(dialogData.project.audience_bench);
  }

  async ngOnInit() {
    this.apiCompany.getOne(this.auth.session.user.company.id).then(company => {
      this.companySub = company.subscription;
    });

    if (environment.config.dev || !environment.online) {
      try {
        const paramSourceType = await this.apiProject.getParam('source_type');
        this.sourceType = paramSourceType.value;
      } catch (e) {
        this.logger.debug('Could not load source type database parameter');
      }

      try {
        const paramPersonaMergeMode = await this.apiProject.getParam('persona_merge_mode');
        this.personaMergeMode = paramPersonaMergeMode.value;
      } catch (e) {
        this.logger.debug('Could not load persona merge mode database parameter');
      }
    }
  }
  ngAfterViewInit(): void {
    this.defineAudienceScore();
  }
  onNoClick(): void {
    this.dialogRef.close(null);
  }
  selectedUniversesToString() {
    return this.project.audience_target.universes.map(u => u.name).join(", ");
  }

  createProject() {
    if (!this.actionPending) {
      this.actionPending = true;
      try {
        this.actionPending = true;
        if (this.project) {
          if ((!environment.online || environment.config.dev) && environment.config.enableExperimentalFeatures && this.sourceType != "default") this.project.source_type = this.sourceType;
          if ((!environment.online || environment.config.dev) && environment.config.enableExperimentalFeatures) this.project.persona_merge_mode = this.personaMergeMode;
          this.apiProject.postOne(this.project).then(project => {
            this.projectManager.projectCreatedCount++;
            const sub = this.auth.session.user.company.subscription;
            if (sub.credits > 0) sub.credits--;
            else sub.credits_extra--;
            if ((sub.credits + sub.credits_extra) <= 0) {
              this.socket.sendMessageType('no_monthly_credits_left', {});
            }
            this.dialogRef.close(project);
            let audienceTargetCriteriaCount = 0;
            const targeting = Targeting.fromJson(project.audience_target.target_spec, project.audience_target.type);
            targeting.flex?.forEach(flex => {
              flex.forEach(arr => {
                audienceTargetCriteriaCount += arr.length;
              })
            });
            this.socket.sendMessageType('user-project-create', {
              projectName: project.name,
              targeting: project.audience_target.target_spec,
              type: project.audience_target.type,
              fromPreset: project.audience_target.from_preset,
              criteriaCount: audienceTargetCriteriaCount
            });
            this.router.navigate(['/projects']).then()
          }, error => {
            this.logger.logError("Could not create project : " + error.message, 2);
            this.alert.notify("Error", error.error.message, "error");
            this.dialogRef.close();
          }).finally(() => this.actionPending = false);
        }
      } catch (err) {
        this.actionPending = false;
        this.logger.logError('Unexpected error while creating project, this has been reported. You can empty your browser cache and try again.', 3, err);
      }
    }
  }

  toNumberFormat(value: number) {
    return ChartService.separator(value);
  }

  boldOr(html: string) {
    return html.replace(/OR/g, "<strong>OR</strong>")
  }

  defineAudienceScore(){
    const scoreConfigs = [
      { message: "Size says too low", borderColor: "#E49EA3", backgroundColor: "#F8E8EA", dotColor: "#C62F38" },
      { message: "Size says low", borderColor: "#F7D8A3", backgroundColor: "#FDF6E8", dotColor: "#EDAA3B" },
      { message: "Size says it's good", borderColor: "#A2C5FB", backgroundColor: "#E8F3FE", dotColor: "#3981F7" },
      { message: "Size says it's perfect", borderColor: "#B1DFC6", backgroundColor: "#F5FFFB", dotColor: "#4CB076" },
      { message: "Size says high", borderColor: "#F7D8A3", backgroundColor: "#FDF6E8", dotColor: "#EDAA3B" },
      { message: "Size says too high", borderColor: "#E49EA3", backgroundColor: "#F8E8EA", dotColor: "#C62F38" }
    ];

    const fbScore = this.project.audience_target.fb_score;

    if (fbScore < 0 || fbScore >= scoreConfigs.length) return;

    const config = scoreConfigs[fbScore];
    this.audienceScoreMessage = config.message;

    document.querySelectorAll<HTMLElement>('.sp-audience-fb-score').forEach(dot => {
      dot.style.backgroundColor = config.backgroundColor;
      dot.style.borderColor = config.borderColor;
    });

    document.querySelectorAll<HTMLElement>('.sp-facebook-audience-fb-score-dot').forEach(dot => {
      dot.style.backgroundColor = config.dotColor;
    });

  }

  get environment() { return environment; }


}
