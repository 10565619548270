import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Project} from '../../../models/project';
import {StatItem} from '../../../models/stats/stat-item';
import {StatHandler} from '../../../classes/data/stat-handler';
import {Audience} from '../../../models/audience';
import {CrawlStatus} from '../../../models/crawl-status';
import {environment} from '../../../../environments/environment';
import {BaseStatFilter} from '../../../classes/filter/base-stat-filter';
import {MapDataHandler} from '../../../classes/data/map-data-handler';
import {MapData} from '../../../models/stats/map-data';
import {MapGeoData, MapLevel} from '../../sp-chart/chart.service';
import {timeout} from 'rxjs/operators';
import {TreeNode} from '../../../classes/data/tree-node';
import {PathEntry} from '../../../classes/data/path-entry';
import {firstValueFrom} from 'rxjs';
import {Folder} from '../../../models/folder';

@Injectable({
  providedIn: 'root'
})
export class ApiDataService {
  private scalaApiUrl = environment.config.api.statsUrl;
  private coreApiUrl = environment.config.api.baseUrl;

  constructor(private httpClient: HttpClient) { }

  getMapJson(country: string, version: number): Promise<MapGeoData> {
    return new Promise<MapGeoData>((resolve, reject) => {
      const url = `${this.coreApiUrl}maps/${country}/v/${version}`;
      this.httpClient.get<any>(url).subscribe(mapData => {
        if (version < 2) {
          const children = mapData.children;

          const regions = mapData.regions ? mapData.regions : undefined;
          const provinces = mapData.provinces ? mapData.provinces : undefined;
          const districts = mapData.districts ? mapData.districts : undefined;
          const cities = mapData.cities ? mapData.cities : undefined;

          let levelCount = 0;
          if (regions) levelCount = 1;
          if (provinces) levelCount = 2;
          if (districts) levelCount = 3;
          if (cities) levelCount = 4;

          const geo = new MapGeoData(children, levelCount, 1);

          if (regions) geo.setLevel(MapLevel.LEVEL_1, regions);
          if (provinces) geo.setLevel(MapLevel.LEVEL_2, provinces);
          if (districts) geo.setLevel(MapLevel.LEVEL_3, districts);
          if (cities) geo.setLevel(MapLevel.LEVEL_4, cities);

          resolve(geo);
        } else {
          const level1 = mapData.level1 ? mapData.level1 : undefined;
          const level2 = mapData.level2 ? mapData.level2 : undefined;
          const level3 = mapData.level3 ? mapData.level3 : undefined;
          const level4 = mapData.level4 ? mapData.level4 : undefined;

          let levelCount = 0;
          if (level1) levelCount = 1;
          if (level2) levelCount = 2;
          if (level3) levelCount = 3;
          if (level4) levelCount = 4;

          const geo = new MapGeoData(undefined, levelCount, 2);

          if (level1) geo.setLevel(MapLevel.LEVEL_1, level1);
          if (level2) geo.setLevel(MapLevel.LEVEL_2, level2);
          if (level3) geo.setLevel(MapLevel.LEVEL_3, level3);
          if (level4) geo.setLevel(MapLevel.LEVEL_4, level4);

          resolve(geo);
        }
      }, error => reject(error));
    });
  }

  getMapData(project: Project, version: number): Promise<MapDataHandler> {
    return new Promise<MapDataHandler>((resolve, reject) => {
      this.httpClient.get<MapData[]>(`${this.scalaApiUrl}/map_data?target=${project.audience_target.id}&benchmark=${project.audience_bench.id}&version=${version}`)
        .subscribe(data => {
          resolve(new MapDataHandler(data));
        }, error => reject(error))
    });
  }

  getDataExcel(project: Project, normalized: boolean = true): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.httpClient.get<any>(`${this.scalaApiUrl}/audiences/excel/${normalized ? 'normalized' : 'raw'}/${project.audience_target.id}/${project.audience_bench.id}`, { responseType: 'blob' as 'json'})
        .pipe(timeout(120000))
        .subscribe(excel => {
          resolve(excel)
        }, error => reject(error))
    });
  }

  getDataCSV(project: Project, normalized: boolean = true): Promise<any> {
    return firstValueFrom(this.httpClient.get<any>(`${this.scalaApiUrl}/audiences/csv/${normalized ? 'normalized' : 'raw'}/${project.audience_target.id}/${project.audience_bench.id}`, { responseType: 'blob' as 'json'}));
  }

  getFolderNormalizedDataCSV(folder: Folder): Promise<any> {
    return firstValueFrom(this.httpClient.get<any>(`${this.scalaApiUrl}/audiences/batch/csv/normalized/${folder.id}`, { responseType: 'blob' as 'json' }));
  }

  getCrawledCriterion(target: Audience, benchmark: Audience, filtered: boolean = false, normalized: boolean = true, seed?: number): Promise<StatHandler> {
    return new Promise<StatHandler>((resolve, reject) => {
      //if (environment.config.dev || !environment.online) normalized = false;
      this.httpClient.get<Array<StatItem|any>>(`${this.scalaApiUrl}/audiences/${normalized ? 'normalized' : 'raw'}/${target.id}/${benchmark.id}/criterion?filtered=${filtered}${seed ? '&seed=' + seed : ''}`)
        .subscribe(data => {
          data.forEach(d => {
            //d.id = parseInt(d.id);
            if (d.type == "tags") {
              d.id = Math.abs(d.id) // Reverse negative ids to positive for compatibility
            }

            //d.audience1 = parseInt(d.audience1);
            //d.audience2 = parseInt(d.audience2);
            //d.size1 = parseInt(d.size1);
            //d.size2 = parseInt(d.size2);
            //d.percent1 = parseFloat(d.percent1);
            //d.percent2 = parseFloat(d.percent2);
            //d.selectivity = parseFloat(d.selectivity);
            //d.differential = Math.abs(d.percent1 - d.percent2);
            d.scorable = d.selectivity;
            //d.name = d.name.charAt(0).toUpperCase() + d.name.substr(1, d.name.length);
          });

          const criterionData = data.filter(d => d.type !== "tags" && d.type !== "personae");
          const tagsData = data.filter(d => d.type == "tags");
          const personaeData = data.filter(d => d.type == "personae");

          let statHandler = new StatHandler();

          statHandler.add("tags", tagsData);
          statHandler.add("criterion", criterionData);
          statHandler.add("personae", personaeData);
          statHandler.filtered = filtered;
          resolve(statHandler)
        }, error => reject(error));
    });
  }

  getNormalizedPersonae(project: Project, stat: StatHandler, universeId?: number, businessRule: string = "BR1"): Promise<StatHandler> {
    const params: any = {
      "br": businessRule
    }

    if (universeId) params["universeId"] = universeId.toString();
    return new Promise<StatHandler>((resolve, reject) => {
      this.httpClient.get<StatItem[]>(`${this.scalaApiUrl}/audiences/normalized/${project.audience_target.id}/${project.audience_bench.id}/personae`, {
        params: params
      })
        .subscribe(data => {
          data.forEach((d, idx) => {
            d.size1 = parseInt(d.size1.toString());
            d.size2 = parseInt(d.size2.toString());
            d.differential = Math.abs(d.percent1 - d.percent2);
            d.scorable = d.selectivity;
            d.score = idx + 1;
            //d.name = d.name.charAt(0).toUpperCase() + d.name.substr(1, d.name.length);
          });

          stat.add("personae", data);
          resolve(stat);
        }, error => reject(error));
    });
  }

  getSunburstData(project: Project, categoryFilter?: string): Promise<TreeNode<PathEntry>[]> {
    if (!categoryFilter) return this.httpClient.get<TreeNode<PathEntry>[]>(this.scalaApiUrl + "/audiences/sunburst/" + project.audience_target.id).toPromise();
    else return this.httpClient.get<TreeNode<PathEntry>[]>(this.scalaApiUrl + "/audiences/sunburst/" + project.audience_target.id, {
      params: {
        'filter': categoryFilter
      }
    }).toPromise();
  }

  filterProjectData(project: Project, filter: BaseStatFilter): Promise<boolean> {
    return new Promise<boolean>((resolve, reject) => {
      this.httpClient
        .post(this.coreApiUrl + "crawlers/" + project.id + "/filter", filter)
        .subscribe(() => resolve(true), error => reject(error));
    });
  }

  getAudienceStatus(audience: Audience, partition: string = "base"): Promise<CrawlStatus> {
    return new Promise<CrawlStatus>((resolve, reject) => {
      this.httpClient
        .get<CrawlStatus>(this.coreApiUrl + `crawlers/${audience.id}/${partition}/status`)
        .subscribe(status => resolve(status), error => reject(error));
    });
  }
}
