<div class="sp-dialog">
  <div class="mb-5 sp-dialog-body">
    <h3>Are you sure you want to delete this user ?</h3>
  </div>

  <div class="pb-3 sp-dialog-footer">
    <button mat-button class="btn btn-secondary m-1 text-uppercase sp-btn sp-btn-secondary" (click)="onNoClick()">Cancel</button>
    <button mat-button class="btn btn-primary m-1 text-uppercase sp-btn sp-btn-primary" [disabled]="!canDelete()" (click)="deleteUser()">Delete</button>
  </div>

</div>

