<div class="sp-dialog">
  <div class="sp-dialog-header">
    <h5> Add your project to your folder</h5>
  </div>
  <div class="sp-dialog-body">
    <mat-form-field class="w-100 sp-form-field" appearance="outline">
      <mat-label class="sp-input-label">Folders</mat-label>
      <input (change)="selected = null" [value]="selected ? selected.name : ''" [formControl]="myControl"
              [matAutocomplete]="auto" class="sp-input-content" matInput placeholder="Type folder’s name..."/>
      <mat-autocomplete (optionSelected)="selected = $event.option.value" #auto="matAutocomplete">
      <!--<mat-select (selectionChange)="onFolderSelect($event.value)" disableOptionCentering panelClass="sp-select-option-icon" class="sp-input-content" placeholder="Select your folder or create a new one"> -->
          <mat-option *ngFor="let folder of filteredFolders()" [value]="folder" >
            {{ folder.name }}
          </mat-option>
     <!-- </mat-select>-->
      </mat-autocomplete>
    </mat-form-field>
    <div class="pb-3 sp-dialog-footer">
      <button mat-button class="btn btn-secondary m-1 text-uppercase sp-btn sp-btn-secondary" (click)="onNoClick()" >Cancel</button>
      <button *ngIf="!selected" mat-button (click)="createFolder()" class="btn btn-primary m-1 text-uppercase sp-btn sp-btn-primary">Create</button>
      <button *ngIf="selected" mat-button (click)="addToFolder(selected)" class="btn btn-primary m-1 text-uppercase sp-btn sp-btn-primary">Select</button>
    </div>
  </div>
</div>
