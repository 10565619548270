import {TargetingUtils} from '../../utils/targeting/targeting-utils';
import {environment} from '../../../environments/environment';
import {
  FbCriteria,
  FbGeolocation, FbGeolocationGroup,
  FbLocale,
  FlexSpec, GeoCity,
  GeoCountry,
  GeoCountryGroup,
  Geolocation, GeoMediumGeoArea, GeoNeighborhood,
  GeoRegion, GeoSubCity, GeoZip
} from '../../types/facebook-types';
import {Targeting} from './targeting';

export interface BuildOptions {
  buildGeolocation?: boolean;
  buildCriterion?: boolean;
  buildSociodemographic?: boolean;
  buildPlatforms?: boolean;
  detailedGeolocation?: boolean;
}

export class TargetingWrapper {
  // Builder attributes
  includedCriterion: FbCriteria[][];
  excludedCriterion: FbCriteria[];
  includedGeolocations: FbGeolocation[];
  includedGeolocationGroups: FbGeolocationGroup[];
  excludedGeolocations: FbGeolocation[];
  includedLocales?: FbLocale[]; // Can be empty

  // Optionals attributes to copy
  ageMin?: number;
  ageMax?: number;
  genders?: number[];
  platforms?: string[];
}

export class TargetingBuilder {
  constructor(private targetingWrapper: TargetingWrapper) {}

  public generateTargeting(options: BuildOptions, targetRef?: Targeting): Targeting {
    const targeting = new Targeting();

    const buildCriterion = options.buildCriterion;
    const buildGeolocation = options.buildGeolocation;
    const buildSociodemographic = options.buildSociodemographic;
    const buildPlatforms = options.buildPlatforms;
    const detailedGeolocation = options.detailedGeolocation;

    const includedCriterion = this.targetingWrapper.includedCriterion.filter(c => c.length > 0);
    const excludedCriterion = this.targetingWrapper.excludedCriterion;

    const includedGeolocations = this.targetingWrapper.includedGeolocations;
    const includedGeolocationGroups = this.targetingWrapper.includedGeolocationGroups;
    const excludedGeolocations = this.targetingWrapper.excludedGeolocations;

    const includedLocales = this.targetingWrapper.includedLocales;

    const ageMin = this.targetingWrapper.ageMin;
    const ageMax = this.targetingWrapper.ageMax;

    const genders = this.targetingWrapper.genders;
    const platforms = this.targetingWrapper.platforms;

    if (buildCriterion) {
      if (includedCriterion.length > 0) {
        targeting.flex = [];
        includedCriterion.forEach(array => {
          if (environment.config.customFlexTypesAsRigid) {
            const filteredArray = array.filter(crit => !TargetingUtils.NUMBER_FLEX_TYPES.includes(crit.type));
            const rigidSpecs = array.filter(crit => TargetingUtils.NUMBER_FLEX_TYPES.includes(crit.type));
            targeting.flex.push(this.flexSpecs(filteredArray));
            this.rigidSpecs(targeting, rigidSpecs);
          } else {
            targeting.flex.push(this.flexSpecs(array));
          }
        });
      }

      if (excludedCriterion.length > 0) {
        targeting.exclusions = this.flexSpecs(excludedCriterion);
      }
    } else if (targetRef) {
      targeting.flex = targetRef.flex;
      targeting.exclusions = targetRef.exclusions;
    }

    if (buildGeolocation) {
      if (includedGeolocations.length > 0 || includedGeolocationGroups.length > 0) {
        targeting.geolocations = this.geolocSpecs(true, detailedGeolocation);
      }

      if (excludedGeolocations.length > 0) {
        targeting.excludedGeolocations = this.geolocSpecs(false, detailedGeolocation);
      }
    } else if (targetRef) {
      targeting.geolocations = targetRef.geolocations;
      targeting.excludedGeolocations = targetRef.excludedGeolocations;
    }

    if (buildSociodemographic) {
      if (includedLocales && includedLocales.length > 0) {
        targeting.locales = includedLocales.map(l => parseInt(l.key));
      }

      if (ageMin) targeting.ageMin = ageMin;
      if (ageMax) targeting.ageMax = ageMax;

      if (genders) targeting.genders = Array.from(genders);
    } else if (targetRef) {
      targeting.locales = targetRef.locales;
      targeting.ageMin = targetRef.ageMin;
      targeting.ageMax = targetRef.ageMax;
      targeting.genders = targetRef.genders;
    }

    if (buildPlatforms && platforms) targeting.platforms = Array.from(platforms);
    else if (targetRef) targeting.platforms = targetRef.platforms;

    if (targetRef) {
      targeting.customAudiences = targetRef.customAudiences;
      targeting.connections = targetRef.connections;
      targeting.extra = targetRef.extra;
    }

    return targeting;
  }

  private flexSpecs(criterion: FbCriteria[]): FlexSpec {
    const flex: FlexSpec = new FlexSpec();

    criterion.forEach(c => {
      if (!flex.has(c.type)) flex.set(c.type, []);

      if (TargetingUtils.NUMBER_FLEX_TYPES.includes(c.type)) {
        flex.get(c.type).push(parseInt(c.id));
      } else {
        flex.get(c.type).push(new Map([
          ["id", c.id],
          ["name", c.name]
        ]));
      }
    });

    return flex;
  }

  private rigidSpecs(targeting: Targeting, criterion: FbCriteria[]) {
    const rigid: FlexSpec = targeting.rigid;

    criterion.forEach(c => {
      if (!rigid.has(c.type)) rigid.set(c.type, []);

      rigid.get(c.type).push(parseInt(c.id));
    });
  }

  geolocSpecs(inclusion: boolean, detailed: boolean): Geolocation {
    const geolocation: Geolocation = new Geolocation();

    const countryGroups: GeoCountryGroup[] = [];
    const countries: GeoCountry[] = [];
    const regions: GeoRegion[] = [];
    const mediumGeoAreas: GeoMediumGeoArea[] = [];
    const cities: GeoCity[] = [];
    const zips: GeoZip[] = [];
    const subCities: GeoSubCity[] = [];
    const neighborhoods: GeoNeighborhood[] = [];

    const geolocations = inclusion ? this.targetingWrapper.includedGeolocations : this.targetingWrapper.excludedGeolocations;
    const geolocationGroups = inclusion ? this.targetingWrapper.includedGeolocationGroups : [];

    const generateForEntries = (geolocations: FbGeolocation[], details: boolean) => {
      geolocations.forEach(g => {
        switch (g.type) {
          case "country_group":
            countryGroups.push(g.key);
            break;
          case "country":
            countries.push(g.key);
            break;
          case "region":
            const region = details && g.country_code ? {key: g.key, name: g.name, country_code: g.country_code} : {key: g.key, name: g.name}
            regions.push(region);
            break;
          case "medium_geo_area":
            // Facebook does not allow adding country_code to this one
            const mediumGeoArea = {key: g.key, name: g.name}
            mediumGeoAreas.push(mediumGeoArea);
            break;
          case "city":
            const city = details && g.country_code ? {key: g.key, name: g.name, country_code: g.country_code} : {key: g.key, name: g.name}
            cities.push(city);
            break;
          case "zip":
            const zip = details && g.country_code ? {key: g.key, name: g.name, country_code: g.country_code} : {key: g.key, name: g.name}
            zips.push(zip);
            break;
          case "subcity":
            // Facebook does not allow adding country_code to this one
            const subCity = {key: g.key, name: g.name}
            subCities.push(subCity);
            break;
          case "neighborhood":
            // Facebook does not allow adding country_code to this one
            const neighborhood = {key: g.key, name: g.name};
            neighborhoods.push(neighborhood);
            break;
        }
      });
    }

    generateForEntries(geolocations, true);

    geolocationGroups.forEach(group => {
      generateForEntries(group.geolocations, true);
    });

    if (countryGroups.length > 0) geolocation.country_groups = countryGroups;
    if (countries.length > 0) geolocation.countries = countries;
    if (regions.length > 0) geolocation.regions = regions;
    if (mediumGeoAreas.length > 0) geolocation.medium_geo_areas = mediumGeoAreas;
    if (cities.length > 0) geolocation.cities = cities;
    if (zips.length > 0) geolocation.zips = zips;
    if (subCities.length > 0) geolocation.subcities = subCities;
    if (neighborhoods.length > 0) geolocation.neighborhoods = neighborhoods;

    return geolocation;
  }

  static buildFbGeolocationFromCountry(key: GeoCountry): FbGeolocation {
    const fbGeoloc = new FbGeolocation();
    fbGeoloc.key = key;
    fbGeoloc.country_code = key;
    fbGeoloc.type = "country";
    fbGeoloc.country_name = key;
    fbGeoloc.name = key;

    return fbGeoloc;
  }

  static buildFbGeolocationFromCountryGroup(key: GeoCountryGroup): FbGeolocation {
    const fbGeoloc = new FbGeolocation();
    fbGeoloc.key = key;
    fbGeoloc.country_code = key;
    fbGeoloc.type = "country_group";
    fbGeoloc.country_name = key;
    fbGeoloc.name = key;

    return fbGeoloc;
  }

}
