import {Injectable} from '@angular/core';
import {User} from "../../models/user";
import {firstValueFrom, Subject} from "rxjs";
import {Session} from "../../models/session";
import {ErrorCode} from "../../utils/error/error-code";
import {ApiService} from "../sp-api/api.service";
import {CookieService} from "ngx-cookie-service";
import {environment, environment as env} from "../../../environments/environment";
import {LoggerService} from '../sp-logger/logger.service';
import {map, take} from 'rxjs/operators';
import {SocketService} from "../sp-ws/socket.service";
import {Analytics} from '@segment/analytics-node';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {
  public static readonly authInfoCookie: string = 'sp-creds';
  private _session: Session = new Session();
  private _sessionListener: Subject<Session> = new Subject();
  private analytics: Analytics = new Analytics({writeKey: 'CZMrKZHIV4YEY6Kd7bQr1QhDRPmocR3L'});

  constructor(private cookieService: CookieService,
              private api: ApiService,
              private logger: LoggerService,
              private socket: SocketService) { }

  /**
   * API Authentication
   * Send token request to the API with provided email and password
   * @param email User email
   * @param password User password
   * @param newToken New token request
   */
  async login(email: string, password: string, newToken: boolean = false) {
    try {
      const login = await firstValueFrom(this.api.post<any>("auth", { email, password }));
      const token = login.token;
      const socketToken = login.socket;
      this.tokenStore(token, socketToken);
      this.socket.close();
      await this.userInit();
    } catch(error) {
      this._session = new Session();

      this.session.errorCode = ErrorCode.httpToErrorCode(error.status);
      this.session.error = error;

      this.sessionListener.next(this.session);
    }
  }

  /**
   * One-Time Password login by giving proof token
   * @param token
   */
  loginOtp(token: string): Promise<boolean> {
    return new Promise<boolean>((resolve, reject) => {
      this.api.post<any>("auth/otp", { token }).subscribe(loginOtp => {
        const token = loginOtp.token;
        const socketToken = loginOtp.socket;
        this.logger.debug("Received token from OTP : " + token);
        this.tokenStore(token, socketToken);

        this.userInit().then();
        resolve(true);
      }, error => {
        reject(error);
        this._session = new Session();

        this.session.errorCode = ErrorCode.httpToErrorCode(error.status);

        this.sessionListener.next(this.session);
      })
    });
  }

  loginImpersonate(user: User|{id: number}): Promise<boolean> {
    return new Promise<boolean>((resolve, reject) => {
      this.api.post<any>("auth/impersonate/" + user.id).subscribe(response => {
        const token = response.token;
        this.tokenStore(token, null, true);
        this.userInit().then();
        resolve(true);
      }, error => {
        reject(error);
      });
    });
  }

  tokenRefresh() {
    return this.api.post<{token: string}>('token/refresh').pipe(
      map(response => {
        const socket = this.socketTokenRetrieve();
        this.tokenStore(response.token, socket);
        return response;
      })
    );
  }

  register(user: User) {
    return firstValueFrom(this.api.post<User>("register", user));
  }

  registerFromInvite(token: string, user: User) {
    let openUser: any = user;
    openUser.token = token;

    return this.api.post('register/validate', openUser);
  }

  registerConfirm(token: string) {
    return this.api.post('register/confirm', {token});
  }

  requestPasswordReset(email: string, captcha: string): Promise<boolean> {
    return new Promise<boolean>((resolve, reject) => {
      this.api.post<any>("password/forgotten", {email, captcha})
        .subscribe(() => resolve(true), error => reject(error));
    });
  }

  getSessionUser(): Promise<User> {
    return new Promise<User>(resolve => {
      if (!this.session.initialized) {
        this.sessionListener.pipe(take(1)).subscribe(session => {
          resolve(session.failed ? undefined : session.user);
        })
      } else {
        resolve(this.session.failed ? undefined : this.session.user);
      }
    });
  }

  /**
   * User initialization client-side by asking API information
   */
  private async userInit() {
    try {
      const user = await firstValueFrom(this.api.get<User>("user"));
      this._session = new Session(user);
      this.session.initialize();
      this.logger.logInfo("Session user : " + user.first_name + " " + user.last_name + " [ID: " + user.id + ", FC: " + user.first_connection + "]")
      if (environment.config.production) {
        const subPlan = user.company.subscription.plan;
        const subPlanName = (subPlan.custom ? "Custom plan" : subPlan.name).toLowerCase().replace(" ", "_");
        this.analytics.identify({
          userId: user.id.toString(),
          traits: {
            first_name: user.first_name,
            last_name: user.last_name,
            email: user.email,
            soprism_plans: subPlanName          }
        });
      }
      this.sessionListener.next(this.session);
      return user;
    } catch(error) {
      this._session = new Session();
      this._session.initialize();
      this.session.errorCode = ErrorCode.ERROR_CODES.ERR_AUTH_FAILURE;
      this.sessionListener.next(this.session);
      if(error.status === 401) {
        this.logger.logWarning("Bad token : Token is wrong, expired or deleted.");
        this.sessionClearStorage();
      }
      return undefined;
    }
  }

  /**
   * Session loading
   * Load a stored session by retrieving stored token if any
   * If no token is provided, the user will be redirected to login page
   */
  public async sessionLoad() {
    const token = this.tokenRetrieve();
    this.logger.logInfo("Loading session...");

    // Token verification
    if(token !== null) {
      this.logger.logInfo("Token detected, getting user info...");
      await this.userInit();
    } else {
      this.logger.logInfo("No token detected, initializing as guest");
      this.session.initialize();
      this._sessionListener.next(this.session);
    }
  }

  /**
   * Session clear
   * Clear the session in both memory and storage
   */
  public sessionClear() {
    if(this.session !== null) {
      this._session = new Session();
      this.sessionListener.next(undefined);
      this.sessionClearStorage();
    }
  }

  /**
   * Session storage clear
   * Clear the session storage
   */
  private sessionClearStorage() {
    this.cookieService.delete(AuthenticationService.authInfoCookie, '/');
    // For compatibility sake
    this.cookieService.delete(AuthenticationService.authInfoCookie, '/', environment.online ? '.soprism.com' : undefined);
    localStorage.removeItem(AuthenticationService.authInfoCookie);
  }

  /**
   * Token storage
   * Store the token in a cookie
   * @param token
   * @param socketToken
   * @param impersonate
   */
  private tokenStore(token: string, socketToken: string, impersonate: boolean = false) {
    const secure: boolean = env.online;

    const data = {token, socketToken, impersonate};

    this.cookieService.set(AuthenticationService.authInfoCookie, JSON.stringify(data), 0, '/', undefined, secure, 'Strict');
    if (!this.cookieService.check(AuthenticationService.authInfoCookie)) {
      localStorage.setItem(AuthenticationService.authInfoCookie, JSON.stringify(data));
    }
  }

  /**
   * Token retrieving
   * Retrieve the token from the storage
   */
  public tokenRetrieve(): string|null {
    if (this.cookieService.check(AuthenticationService.authInfoCookie)) {
      const raw = this.cookieService.get(AuthenticationService.authInfoCookie);
      if (raw) {
        return JSON.parse(raw).token;
      } else return null;
    }
    else {
      const raw = localStorage.getItem(AuthenticationService.authInfoCookie)
      if (raw) {
        return JSON.parse(raw).token;
      } else return null;
    }
  }

  public socketTokenRetrieve(): string|null {
    if (this.cookieService.check(AuthenticationService.authInfoCookie)) {
      const raw = this.cookieService.get(AuthenticationService.authInfoCookie);
      if (raw) {
        return JSON.parse(raw).socketToken;
      } else return null;
    }
    else {
      const raw = localStorage.getItem(AuthenticationService.authInfoCookie)
      if (raw) {
        return JSON.parse(raw).socketToken;
      } else return null;
    }
  }

  /**
   * Session retrieving
   * Retrieve the session from the storage
   */
  public sessionRetrieve(): {token: string, socketToken?: string, impersonate: boolean}|null {
    if (this.cookieService.check(AuthenticationService.authInfoCookie)) {
      const raw = this.cookieService.get(AuthenticationService.authInfoCookie);
      if (raw) {
        return JSON.parse(raw);
      } else return null;
    }
    else {
      const raw = localStorage.getItem(AuthenticationService.authInfoCookie)
      if (raw) {
        return JSON.parse(raw);
      } else return null;
    }
  }

  /**
   * Permission checking
   * Check permission from API permission system
   * @param permission
   */
  public permissionCheck(permission: string): Promise<boolean> {
    return new Promise<boolean>(resolve => {
      const checkPerm = (session: Session) => {
        if(!environment.config.permCheck) resolve(true);
        if (!session.authenticated || !session.user) resolve(false);

        let permissions = session.user.permissions;
        let keys = permissions.map(p => p.key);

        if(!keys.includes(permission)) {
          resolve(keys.includes('*'));
        } else {
          let basePerm = permissions.find(perm => perm.key == permission);
          resolve(basePerm.access);
        }
      };

      if (this.session.initialized) {
        checkPerm(this.session);
      } else {
        const sub = this.sessionListener.subscribe(session => {
          if (!session.failed) {
            checkPerm(session);
          } else {
            this.logger.logError("Permission check with failed session");
            resolve(false);
          }

          sub.unsubscribe();
        })
      }
    });
  }

  get session(): Session {
    return this._session;
  }

  get sessionListener(): Subject<Session> {
    return this._sessionListener;
  }
}
