import {Component, HostListener, OnDestroy, OnInit} from '@angular/core';
import {Audience} from '../../../../../models/audience';
import {
  AudienceCreationMode,
  AudienceInfo,
  AudienceType,
  ProjectCreationManagerService
} from '../../../../../services/sp-project/project-creation-manager.service';
import {AuthenticationService} from '../../../../../services/sp-authentication/authentication.service';
import {MatDialog} from '@angular/material/dialog';
import {
  SpModalProjectCreateAudienceModalNameComponent
} from '../sp-modal-project-create-audience-modal-name/sp-modal-project-create-audience-modal-name.component';
import {Project} from '../../../../../models/project';
import {SpModalProjectCreateDetailsComponent} from '../../sp-modal-project-create-details/sp-modal-project-create-details.component';
import {ProjectManagerService} from '../../../../../services/sp-project/project-manager.service';
import {first} from 'rxjs/operators';
import {RoleUtils} from '../../../../../utils/role/role-utils';
import {environment} from '../../../../../../environments/environment';
import {MatCheckboxChange} from '@angular/material/checkbox';
import {Targeting} from '../../../../../classes/targeting/targeting';
import {ApiProjectService} from '../../../../../services/sp-api/sp-api-project/api-project.service';
import {ApiUniverseService} from '../../../../../services/sp-api/sp-api-universe/api-universe.service';
import {Router} from '@angular/router';
import {Criteria} from '../../../../../models/criteria';
import {Logger} from '../../../../../classes/util/logger';
import {DebugService} from '../../../../../services/sp-debug/sp-debug.service';
import {Subscription} from 'rxjs';

@Component({
  selector: 'app-sp-project-create-header',
  templateUrl: './sp-project-create-header.component.html',
  styleUrls: ['./sp-project-create-header.component.scss']
})
export class SpProjectCreateHeaderComponent implements OnInit, OnDestroy {
  isInsideName: boolean;
  isSelected: boolean;
  pageScrolled: boolean;
  hideScroll: boolean;
  menuOpen: boolean = false;

  titleEditing: HTMLInputElement[] = [];
  freemium = false;

  useNewAudienceScoreIndicator?: boolean;

  // Permission
  canCustomCrawl: boolean = false;

  // Subscription
  private featureFlagSubscription?: Subscription;

  constructor(
    private creationManager: ProjectCreationManagerService,
    private auth: AuthenticationService,
    private dialog: MatDialog,
    private router: Router,
    private debug: DebugService,
    private projectManager: ProjectManagerService,
    private apiProject: ApiProjectService,
    private apiUniverse: ApiUniverseService
  ) {
    this.isInsideName = false;
    this.isSelected = false;
    this.pageScrolled = false;
    this.hideScroll = false;
  }

  async ngOnInit() {
    if (this.auth.session.authenticated) {
      this.freemium = this.auth.session.user.role.level <= RoleUtils.freemiumLevel;
      this.canCustomCrawl = await this.auth.permissionCheck('crawl.custom');
      try {
        const newAudienceScoreIndicatorParam = await this.apiProject.getParam("ff_new_aud_score");
        this.useNewAudienceScoreIndicator = newAudienceScoreIndicatorParam.value === 'true';
      } catch (e) {
        Logger.logWarning('Failed to retrieve new audience score indicator parameter');
        this.useNewAudienceScoreIndicator = false;
      } finally {
        this.debug.updateFeatureFlag('ff_new_aud_score', this.useNewAudienceScoreIndicator);
        this.featureFlagSubscription = this.debug.featureFlagChanged.subscribe(feature => {
          if (feature.flag === 'ff_new_aud_score') {
            this.useNewAudienceScoreIndicator = feature.active;
          }
        });
      }
    }
  }

  ngOnDestroy() {
    this.featureFlagSubscription?.unsubscribe();
  }

  onAudienceTitleUpdate(title: HTMLInputElement, audience: Audience) {
    this.titleEditing.splice(this.titleEditing.indexOf(title), 1);
    if (title.value !== "" && title.value !== audience.name) {
      audience.name = title.value;
      this.projectTitleLink = false;
    }
  }

  onFlagBoxChange(event: MatCheckboxChange) {
    this.creationManager.flag = event.checked ? 'custom' : undefined;
  }

  editTitle(title: HTMLInputElement) {
    if (!this.titleEditing.includes(title)) {
      this.titleEditing.push(title);
    }

    setTimeout(() => {
      title.focus();
    }, 10);
  }

  isTitleEditable(title: HTMLInputElement): boolean {
    return this.titleEditing.includes(title);
  }

  /**
   * Switching type of audience
   * The current selection is changed based on the type
   * @param audienceType
   */
  switchAudienceType(audienceType: AudienceType) {
    this.creationManager.switchAudienceType(audienceType);
    this.isSelected = audienceType !== AudienceType.target;
  }

  async confirmProject() {
    if (this.creationManager.isProjectComplete(this.freemium)) {
      const audienceTarget = this.audienceTarget;
      const audienceBenchmark = this.audienceBenchmark;

      const audienceTargetSpec = audienceTarget.targeting;
      const audienceBenchmarkSpec = audienceBenchmark.targeting;

      audienceTarget.target_spec = audienceTargetSpec.toFbJsonString();
      audienceBenchmark.target_spec = audienceBenchmarkSpec.toFbJsonString();

      const customParamSelected = this.creationManager.cicCountriesSelected ||
        this.creationManager.topicUniverseSelected ||
        this.creationManager.politicalUniverseSelected;

      if (this.creationManager.flag && customParamSelected) {
        const projects: Project[] = [];
        const audienceCouples: {name: string, target: Audience, benchmark: Audience}[] = [];

        let ticpic: Criteria[] = [];
        if (this.creationManager.topicUniverseSelected && this.creationManager.politicalUniverseSelected) {
          const topics = await this.apiUniverse.getCriterionFromUniverse(this.creationManager.topicUniverseSelected);
          const politics = await this.apiUniverse.getCriterionFromUniverse(this.creationManager.politicalUniverseSelected);

          for (const topic of topics) {
            for (const politic of politics) {
              const topicTarget = Targeting.fromJson(topic.target_spec, 'sociodemo');
              const politicTarget = Targeting.fromJson(politic.target_spec, 'sociodemo');
              const combination = topicTarget.mergeWith(politicTarget);

              const criteria = new Criteria(topic.name + ' x ' + politic.name, 'ticpic', combination.toFbJsonString());
              ticpic.push(criteria);
            }
          }
        } else {
          if (this.creationManager.topicUniverseSelected) {
            ticpic = await this.apiUniverse.getCriterionFromUniverse(this.creationManager.topicUniverseSelected);
          } else if (this.creationManager.politicalUniverseSelected) {
            ticpic = await this.apiUniverse.getCriterionFromUniverse(this.creationManager.politicalUniverseSelected);
          }
        }

        if (ticpic.length > 0) {
          for (let c of ticpic) {
            const targeting = Targeting.fromJson(c.target_spec, audienceTarget.type);
            const targetTargeting = Targeting.fromJson(audienceTarget.target_spec, audienceTarget.type);
            const benchmarkTargeting = Targeting.fromJson(audienceBenchmark.target_spec, audienceBenchmark.type);

            const mergedTarget = targetTargeting.mergeWith(targeting);
            //const mergedBenchmark = benchmarkTargeting.mergeWith(targeting);

            const fullProjectName = c.name + ' - ' + this.creationManager.projectName;
            const target = mergedTarget.toAudience(fullProjectName, 'base', 'en_US', audienceTarget.type);
            const benchmark =  audienceBenchmark;

            audienceCouples.push({name: fullProjectName, target, benchmark});
          }
        } else {
          audienceCouples.push({name: this.creationManager.projectName, target: audienceTarget, benchmark: audienceBenchmark});
        }

        if (this.creationManager.cicCountriesSelected) {
          this.creationManager.cicCountriesSelected.forEach(country => {
            for (let couple of audienceCouples) {
              const targetSpecCopy = Targeting.fromJson(couple.target.target_spec, couple.target.type);
              targetSpecCopy.geolocations = {countries: [country.code]};
              const benchmarkSpecCopy = Targeting.fromJson(couple.benchmark.target_spec, couple.benchmark.type);
              benchmarkSpecCopy.geolocations = {countries: [country.code]};

              const fullProjectName = country.name + ' - ' + couple.name;
              const project = new Project(fullProjectName);
              project.audience_target = targetSpecCopy.toAudience(fullProjectName, 'base', 'en_US', audienceTarget.type);
              project.audience_bench = benchmarkSpecCopy.toAudience(fullProjectName + ' - Benchmark', 'base', 'en_US', audienceBenchmark.type);
              projects.push(project);
            }
          });
        }

        if (projects.length > 0) {
          this.apiProject.projectBatch({
            name: this.creationManager.projectName,
            projects: projects,
            universes: audienceTarget.universes
          }).then(folder => {
            this.projectManager.addFolder(folder, true);
            this.router.navigate(['projects', 'list']).then();
          }).catch(err => {
            console.error(err);
          });
        }
      } else {
        let project = new Project(this.creationManager.projectName);
        project.audience_target = audienceTarget;
        project.audience_bench = audienceBenchmark;
        project.flag = this.creationManager.flag;

        this.projectManager.selectFolder(undefined);
        const handleProjectConfirm = (project: Project) => {
          if (project) { // Project creation successful
            if (project.name) { // Project or API message ?
              this.auth.session.user.projects.unshift(project);
            } else {
              //this.projectManager.preloadProjects().then();
            }
          } else { // Project creation cancelled or error
            //this.projectManager.preloadProjects().then();
          }
        }
        if (this.freemium) {
          this.dialog.open(SpModalProjectCreateAudienceModalNameComponent,{
            minHeight:'25rem',
            minWidth:'40rem',
            maxHeight:'25rem',
            maxWidth:'40rem',
            data: project
          }).afterClosed().pipe(first()).subscribe(handleProjectConfirm)
        } else {
          this.dialog.open(SpModalProjectCreateDetailsComponent, {
            autoFocus: false,
            minHeight: '30rem',
            maxHeight: '60rem',
            minWidth: '40rem',
            data: {project, useNewIndicator: this.useNewAudienceScoreIndicator}
          }).afterClosed().pipe(first()).subscribe(handleProjectConfirm);
        }
      }
    }
  }

  @HostListener("window:scroll", []) onWindowScroll() {
    if(this.hideScroll == false){
      this.scrollFunction();
    }
  }
  // When the user scrolls down 20px from the top of the document, show the button
  scrollFunction() {
    const targetCardHeight = document.getElementById("card-body-target").clientHeight;
    const benchmarkCardHeight = document.getElementById("card-body-benchmark").clientHeight;

    const clientHeight = document.body.clientHeight;
    const scrollHeight = document.body.scrollHeight;
    const cardBodyHeight = targetCardHeight > benchmarkCardHeight ? targetCardHeight : benchmarkCardHeight;

    const scrollDelta = (clientHeight + cardBodyHeight) - scrollHeight;
    var scrollTop = document.documentElement.scrollTop || document.body.scrollTop;
    if (scrollTop > 20) {
      this.pageScrolled = true;

      document.getElementById("card-body-target").style.display = "none";
      document.getElementById("card-body-benchmark").style.display = "none";
      if(document.getElementById("sp-image-facebook-import-interest")) {
        document.getElementById("sp-image-facebook-import-interest").style.height = "5rem";
      } else if (document.getElementById("sp-image-facebook-import-import")) {
        document.getElementById("sp-image-facebook-import-import").style.height = "5rem";
      } else if ( document.getElementById("sp-image-facebook-import-facebookPage")) {
        document.getElementById("sp-image-facebook-import-facebookPage").style.height = "5rem";
      }
    } else if(document.documentElement.scrollTop == 0) {
      this.pageScrolled = false;

      document.getElementById("card-body-target").style.display = "block";
      document.getElementById("card-target").style.minHeight = "3rem";
      document.getElementById("card-body-benchmark").style.display = "block";
      document.getElementById("card-benchmark").style.minHeight = "3rem";
      if(document.getElementById("sp-image-facebook-import-interest")) {
        document.getElementById("sp-image-facebook-import-interest").style.height = "auto";
      } else if (document.getElementById("sp-image-facebook-import-import")) {
        document.getElementById("sp-image-facebook-import-import").style.height = "auto";
      } else if ( document.getElementById("sp-image-facebook-import-facebookPage")) {
        document.getElementById("sp-image-facebook-import-facebookPage").style.height = "auto";
      }
    }
  }

  get audienceTarget(): Audience {
    return this.creationManager.audienceTarget;
  }

  get audienceTargetInfo(): AudienceInfo { return this.creationManager.audiencesInfo.get(AudienceType.target) }
  get audienceBenchmarkInfo(): AudienceInfo { return this.creationManager.audiencesInfo.get(AudienceType.benchmark) }


  get audienceBenchmark(): Audience { return this.creationManager.audienceBenchmark; }
  get audienceType(): AudienceType { return this.creationManager.audienceType; }
  get audienceMode(): AudienceCreationMode { return this.creationManager.audienceCreationMode; }
  get AudienceType(): typeof AudienceType { return AudienceType; }
  get AudienceCreationMode(): typeof AudienceCreationMode { return AudienceCreationMode; }

  set projectTitleLink(bool: boolean) { bool ? this.creationManager.enableProjectTitleLink() : this.creationManager.disableProjectTitleLink(); }

  protected readonly environment = environment;
}
