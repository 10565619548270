<div class="w-100 container">
  <div class="sp-table-div">
  <div class="row d-flex no-gutters sp-table-header">
    <div class="col-5 align-self-center">
      <mat-form-field appearance="outline" class="w-100 ml-3 mt-3 mb-1 sp-form-field">
        <mat-label class="sp-input-label">Search</mat-label>
        <mat-icon class="sp-input-search-icon"  matPrefix svgIcon="icon_sp_search"></mat-icon>
        <input #searchData (keyup)="applyFilter($event)" class=" sp-input-search-content " matInput placeholder="Search for a company...">
        <button (click)="dataSource.filter=''; searchData.value=''" *ngIf="dataSource.filter" aria-label="Clear" mat-icon-button matSuffix>
          <mat-icon>close</mat-icon>
        </button>
      </mat-form-field>
    </div>
    <div class="col-2 ml-3 mt-4 mb-1 align-self-center">
      <p class="pl-3 sp-table-number">
        {{ dataSource.data.length }} companies
      </p>
    </div>

    <div class="col-5 text-right">
      <button type="button" class="btn btn-primary rounded-circle sp-btn sp-btn-xl sp-btn-primary sp-btn-circle" routerLink="/admin/companies/create" routerLinkActive="active"  matTooltip="Create a new company" matTooltipHideDelay="10">
        <span class="sp-icon">
          <i class="icon-sp-companies-add-button"> </i>
        </span>
      </button>
    </div>
  </div>

  <table mat-table [dataSource]="dataSource" class="mat-elevation-z8 sp-table" matSort>

    <!-- Position Column -->
    <ng-container matColumnDef="image">
      <th mat-header-cell *matHeaderCellDef></th>
      <td mat-cell *matCellDef="let element" (click)="navigateToCompany(element)">
        <img class="sp-avatar sp-avatar-md " *ngIf="element.image !== null" [src]="element.image !== null ? env.config.api.storageUrl + element.image : env.config.noPpImage">
        <span *ngIf="element.image == null" class="avatar-rounded">
          <span class="avatar-company-name text-uppercase">{{element.name.substr(0,2)}}</span>
        </span>
      </td>
    </ng-container>

    <!-- Position Column -->
    <ng-container matColumnDef="name">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Company Name </th>
      <td mat-cell *matCellDef="let element" (click)="navigateToCompany(element)"> {{ element.name }} </td>
    </ng-container>

    <!-- Name Column -->
    <ng-container matColumnDef="userCount">
      <th *matHeaderCellDef mat-header-cell mat-sort-header> Users </th>
      <td (click)="navigateToCompany(element)" *matCellDef="let element" mat-cell> {{ element.userCount }} </td>
    </ng-container>

    <!-- Name Column -->
    <ng-container matColumnDef="payment_frequency">
      <th *matHeaderCellDef mat-header-cell  mat-sort-header> Payment </th>
      <td (click)="navigateToCompany(element)" *matCellDef="let element" mat-cell> {{ companyHasSubscription(element) ? element.payment_frequency : "N/D" }} </td>
    </ng-container>

    <!-- Weight Column -->
    <ng-container matColumnDef="planName">
      <th *matHeaderCellDef mat-header-cell  mat-sort-header> Subscription </th>
      <td mat-cell *matCellDef="let element" (click)="navigateToCompany(element)">
        {{ companyHasPlan(element) ? element.planName : "Custom plan" }}
      </td>
    </ng-container>

    <!-- Weight Column -->
    <ng-container matColumnDef="credits">
      <th *matHeaderCellDef mat-header-cell mat-sort-header> Credits </th>
      <td mat-cell *matCellDef="let element" (click)="navigateToCompany(element)">
        {{ companyHasPlan(element) ? element.credits: "N/D" }}
      </td>
    </ng-container>

    <!-- Weight Column -->
    <ng-container matColumnDef="subscription_contract_start_at">
      <th *matHeaderCellDef mat-header-cell mat-sort-header> Start </th>
      <td mat-cell *matCellDef="let element" (click)="navigateToCompany(element)">
        {{ companyHasSubscription(element) ? element.subscription_contract_start_at : "N/D" }}
      </td>
    </ng-container>

    <!-- Weight Column -->
    <ng-container matColumnDef="subscription_contract_end_at">
      <th *matHeaderCellDef mat-header-cell mat-sort-header> End </th>
      <td mat-cell *matCellDef="let element" (click)="navigateToCompany(element)">
        {{ companyHasSubscription(element) ? element.subscription_contract_end_at : "N/D" }}
      </td>
    </ng-container>

    <!-- Weight Column -->
    <ng-container matColumnDef="credits_left">
      <th *matHeaderCellDef mat-header-cell mat-sort-header> Credit left </th>
      <td mat-cell *matCellDef="let element" (click)="navigateToCompany(element)">
        {{ companyHasSubscription(element) ?  element.credits_left : "N/D" }}
      </td>
    </ng-container>

    <!-- Symbol Column -->
    <ng-container matColumnDef="actions">
      <th mat-header-cell *matHeaderCellDef> Actions </th>
      <td mat-cell *matCellDef="let element">
        <i class="icon-sp-edit-white" (click)="navigateToCompany(element)"> </i>
        <i class="icon-sp-delete" (click)="openDeleteDialog(element)"> </i>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>
  </div>
  <mat-paginator class="sp-paginator" [ngStyle]="{'display': dataSource.data.length > 10  ? 'block' :  'none'}" [pageSize]="10" hidePageSize showFirstLastButtons></mat-paginator>

</div>
