import {AfterViewInit, Component, Inject, OnDestroy, OnInit} from '@angular/core';
import { SpThemingService } from './services/sp-theming/sp-theming.service';
import {AuthenticationService} from "./services/sp-authentication/authentication.service";
import {MatIconRegistry} from "@angular/material/icon";
import {DomSanitizer} from "@angular/platform-browser";
import {ApiService} from "./services/sp-api/api.service";
import {environment} from '../environments/environment';
import {LoaderService} from './services/sp-loading/loader.service';
import * as introJs from 'intro.js/intro.js';
import {FacebookLinkService} from './services/sp-facebook/facebook-link.service';
import { DebugService } from './services/sp-debug/sp-debug.service';
import {SocketService} from "./services/sp-ws/socket.service";
import Rollbar from 'rollbar';
import {RollbarService} from './rollbar';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, AfterViewInit, OnDestroy {
  env = environment;
  introJS = introJs();

  // Theme name.
  public spThemeName: string;
// Constructor.
  constructor(private _spTheming: SpThemingService,
              private matIconRegistry: MatIconRegistry,
              private domSanitizer: DomSanitizer,
              private auth: AuthenticationService,
              public loader: LoaderService,
              private api: ApiService,
              private debug: DebugService,
              private socket: SocketService,
              @Inject(RollbarService) rollbar: Rollbar)
  {
    loader.loadGlobal();

    this.matIconRegistry.addSvgIcon(
      `icon_sp_folder`,
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/images/icons/soprism/sp-folder.svg')
    );
    this.matIconRegistry.addSvgIcon(
      `icon_sp_home`,
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/images/icons/soprism/sp-home.svg')
    );
    this.matIconRegistry.addSvgIcon(
      `icon_sp_search`,
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/images/icons/soprism/sp-search-new.svg')
    );
    this.matIconRegistry.addSvgIcon(
      `icon_sp_email`,
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/images/icons/soprism/sp-email.svg')
    );
    this.matIconRegistry.addSvgIcon(
      `icon_sp_password`,
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/images/icons/soprism/sp-password.svg')
    );
    this.matIconRegistry.addSvgIcon(
      `icon_sp_first_name`,
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/images/icons/soprism/sp-first-name.svg')
    );
    this.matIconRegistry.addSvgIcon(
      `icon_sp_last_name`,
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/images/icons/soprism/sp-last-name.svg')
    );
    this.matIconRegistry.addSvgIcon(
      `icon_sp_company_name`,
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/images/icons/soprism/sp-company-name.svg')
    );
    this.matIconRegistry.addSvgIcon(
      `icon_sp_company_name_2`,
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/images/icons/soprism/sp-company-name-2.svg')
    );
    this.matIconRegistry.addSvgIcon(
      `icon_sp_calendar`,
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/images/icons/soprism/sp-calendar.svg')
    );
    this.matIconRegistry.addSvgIcon(
      `icon_sp_contract`,
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/images/icons/soprism/sp-contract.svg')
    );
    this.matIconRegistry.addSvgIcon(
      `icon_sp_schedule`,
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/images/icons/soprism/sp-schedule.svg')
    );
    this.matIconRegistry.addSvgIcon(
      `icon_sp_user`,
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/images/icons/soprism/sp-user.svg')
    );
    this.matIconRegistry.addSvgIcon(
      `icon_sp_text_author`,
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/images/icons/soprism/sp-text-author.svg')
    );
    this.matIconRegistry.addSvgIcon(
      `icon_sp_name_tag`,
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/images/icons/soprism/sp-name-tag.svg')
    );
    this.matIconRegistry.addSvgIcon(
      `icon_sp_tag`,
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/images/icons/soprism/sp-tag.svg')
    );
    this.matIconRegistry.addSvgIcon(
      `icon_sp_world`,
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/images/icons/soprism/sp-world.svg')
    );
    this.matIconRegistry.addSvgIcon(
      `icon_sp_import`,
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/images/icons/soprism/sp-import.svg')
    );
    this.matIconRegistry.addSvgIcon(
      `icon_sp_label`,
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/images/icons/soprism/sp-label.svg')
    );
    this.matIconRegistry.addSvgIcon(
      `icon_sp_arrow_down`,
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/images/icons/soprism/sp-arrow-down.svg')
    );
    this.matIconRegistry.addSvgIcon(
      `icon_sp_folder_add`,
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/images/icons/soprism/sp-folder-add.svg')
    );
    this.matIconRegistry.addSvgIcon(
      `icon_sp_users`,
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/images/icons/soprism/sp-users.svg')
    );
    this.matIconRegistry.addSvgIcon(
      `icon_sp_delete_cross`,
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/images/icons/soprism/sp-delete-cross.svg')
    );
    this.matIconRegistry.addSvgIcon(
      `icon_sp_x`,
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/images/icons/soprism/sp-x.svg')
    );

    this.matIconRegistry.addSvgIcon(
      `icon_sp_insights`,
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/images/icons/soprism/sp-insight.svg')
    );
    this.matIconRegistry.addSvgIcon(
      `icon_sp_tag-sidebar`,
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/images/icons/soprism/sp-tag-sidebar.svg')
    );
    this.matIconRegistry.addSvgIcon(
      `icon_sp_data_source`,
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/images/icons/soprism/sp-datasource.svg')
    );
    this.matIconRegistry.addSvgIcon(
      `icon_sp_refresh`,
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/images/icons/soprism/sp-back.svg')
    );
    this.matIconRegistry.addSvgIcon(
      `icon_sp_logout`,
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/images/icons/soprism/sp-logout.svg')
    );
    this.matIconRegistry.addSvgIcon(
      `icon_sp_my_admin`,
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/images/icons/soprism/sp-my-admin.svg')
    );
    this.matIconRegistry.addSvgIcon(
      `icon_sp_my_profile`,
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/images/icons/soprism/sp-my-profile.svg')
    );
    this.matIconRegistry.addSvgIcon(
      `icon_sp_facebook`,
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/images/icons/soprism/sp-facebook.svg')
    );
    this.matIconRegistry.addSvgIcon(
      `icon_sp_language`,
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/images/icons/soprism/sp-language.svg')
    );
    this.matIconRegistry.addSvgIcon(
      `icon_sp_brand`,
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/images/icons/soprism/sp-brand.svg')
    );
    this.matIconRegistry.addSvgIcon(
      `icon_sp_question`,
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/images/icons/soprism/sp-question-mark.svg')
    );
    this.matIconRegistry.addSvgIcon(
      `icon_sp_resize`,
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/images/icons/soprism/sp-resize.svg')
    );
    this.matIconRegistry.addSvgIcon(
      `icon_sp_localization`,
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/images/icons/soprism/sp-localization.svg')
    );
    this.matIconRegistry.addSvgIcon(
      `icon_sp_edit`,
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/images/icons/soprism/sp-edit.svg')
    );
    this.matIconRegistry.addSvgIcon(
      `icon_sp_scroll`,
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/images/icons/soprism/icon-scroll.svg')
    );
    this.matIconRegistry.addSvgIcon(
      `icon_sp_accurate`,
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/images/freemium/page/AccurateInterest.svg')
    );
    this.matIconRegistry.addSvgIcon(
      `icon_sp_lifestyle`,
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/images/freemium/page/Lifestyle.svg')
    );
    this.matIconRegistry.addSvgIcon(
      `icon_sp_thematic`,
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/images/freemium/page/MainThematic.svg')
    );
    this.matIconRegistry.addSvgIcon(
      `icon_sp_map1`,
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/images/freemium/page/Map1Where.svg')
    );
    this.matIconRegistry.addSvgIcon(
      `icon_sp_map2`,
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/images/freemium/page/Map2Competitors.svg')
    );
    this.matIconRegistry.addSvgIcon(
      `icon_sp_map3`,
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/images/freemium/page/Map3Opportunities.svg')
    );
    this.matIconRegistry.addSvgIcon(
      `icon_sp_segmentation1`,
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/images/freemium/page/Segmentation1Personas.svg')
    );
    this.matIconRegistry.addSvgIcon(
      `icon_sp_segmentation2`,
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/images/freemium/page/Segmentation2Beconnected.svg')
    );
    this.matIconRegistry.addSvgIcon(
      `icon_sp_segmentation3`,
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/images/freemium/page/Segmentation3Proprietary.svg')
    );
    this.matIconRegistry.addSvgIcon(
      `icon_sp_preset`,
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/images/icons/soprism/sp-preset.svg')
    );

    // Only configuring users for prod environment to avoid ID conflict
    if (environment.online && environment.config.production) {
      auth.sessionListener.subscribe(s => {
        if (s && s.authenticated && rollbar.options.payload?.person?.id !== s.user.id) {
          rollbar.configure({
            payload: {
              person: {
                id: s.user.id,
                email: s.user.email,
                username: s.user.first_name + ' ' + s.user.last_name
              }
            }
          });
        } else if (!s) {
          rollbar.configure({
            payload: {
              person: undefined
            }
          });
        }
      });
    }

    ///Audiense icon implementation to angular material
    // Path : src/assets/images/icons/audiense
  }

  // On init.
  ngOnInit() {
    this.introJS.start();

    // Api live check
    this.api.live();

    // Theme selection listener
    this._spTheming.themeSubject.subscribe(
      (theme: any) => {
        this.spThemeName = theme;
      }
    );

    onload = () => {
      this.loader.loadedGlobal()
    }
  }

  ngAfterViewInit() {
    // Session loading
    this.auth.sessionListener.subscribe(s => {
      const socketInit = () => {
        this.socket.init().then();
      };
      socketInit();
    });
    this.auth.sessionLoad().then();
  }

  ngOnDestroy() {
    this.socket.close();
  }

  isDebugBoxVisible(): boolean {
    return this.debug.debugBoxOpen;
  }

  toggleDebugBox() {
    this.debug.toggleDebugBox();
  }

  updateFeatureFlag(flag: string, active: boolean) {
    this.debug.updateFeatureFlag(flag, active);
  }

  get debugOutput() {
    return this.debug.debugOutput;
  }

  get debugFeatureFlags() {
    return this.debug.featureFlags;
  }
}
