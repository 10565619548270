import { Injectable } from '@angular/core';
import {Logger} from '../../classes/util/logger';
import {ApiErrorService} from '../sp-api/sp-api-error/api-error.service';
import {environment} from '../../../environments/environment';
import {DebugService} from "../sp-debug/sp-debug.service";
import {SocketService} from "../sp-ws/socket.service";
import {Project} from "../../models/project";
import {ErrorUtils} from '../../utils/error/error-utils';

@Injectable({
  providedIn: 'root'
})
export class LoggerService {
  constructor(private apiError: ApiErrorService,
              private debugService: DebugService,
              private socket: SocketService) { }

  debug(...message: any) {
    Logger.logDebug(...message);
  }

  logInfo(message: any, priority: number = 1) {
    Logger.logInfo(message, priority);
  }

  logWarning(message: any, priority: number = 1) {
    Logger.logWarning(message, priority);
  }

  /**
   * Logging of error
   * Set priority to 3 or more to send an error report (only working for production environment)
   * @param message
   * @param severity
   * @param error
   * @param project
   */
  logError(message: any, severity: number = 1, error?: Error, project?: Project) {
    if (!environment.online && error) console.error(error);
    if (severity >= 2) {
      this.socket.sendMessageType('user-error', {error: ErrorUtils.stacktraceToStringList(error), errorMessage: message, project: project, route: window.location.pathname});
    }
    if (severity >= 3 && (environment.config.production || !environment.online)) {
      this.debug("Log error severity of " + severity + " => sending error report");
      this.apiError.sendErrorReport(message, error);
    }
    Logger.logError(message, severity);
  }
}
