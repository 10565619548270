import {Component, Inject, OnInit} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {DialogData} from '../../../sp-admin-testing/sp-admin-testing-theming/sp-admin-testing-theming.component';
import {ApiUserService} from '../../../../../services/sp-api/sp-api-user/api-user.service';
import {User} from "../../../../../models/user";
import {ApiCompanyService} from "../../../../../services/sp-api/sp-api-company/api-company.service";
import {Company} from "../../../../../models/company";
import {AuthenticationService} from "../../../../../services/sp-authentication/authentication.service";
import {RoleUtils} from '../../../../../utils/role/role-utils';
import {environment} from '../../../../../../environments/environment';
import {AlertService} from '../../../../../services/sp-alert/alert.service';

@Component({
  selector: 'app-sp-modal-company-remove-users',
  templateUrl: './sp-modal-company-remove-users.component.html',
  styleUrls: ['./sp-modal-company-remove-users.component.scss']
})
export class SpModalCompanyRemoveUsersComponent implements OnInit {
  constructor(
    public dialogRef: MatDialogRef<SpModalCompanyRemoveUsersComponent>,
    private apiCompany: ApiCompanyService,
    private auth: AuthenticationService,
    private alert: AlertService,
    @Inject(MAT_DIALOG_DATA) public data: {company: Company, user: User}) {}

  onNoClick(): void {
    this.dialogRef.close(null)
  }

  canDelete() {
    let user = this.data.user;

    if(user.id === this.auth.session.user.id) return false;

    if (user.role.level >= RoleUtils.teamAdminLevel) {
      let adminCount = this.data.company.users.filter(u => u.role.level >= RoleUtils.teamAdminLevel).length;

      return adminCount > 1;
    }

    return true;
  }

  deleteUser() {
    this.apiCompany.deleteUser(this.data.company, this.data.user)
      .then(company => this.dialogRef.close(company))
      .catch(error => {
        if(environment.config.showErrorModal) this.alert.notify("Error", error.error.message, "error");
        this.dialogRef.close(null)
      });
  }

  ngOnInit() {
  }
}
