import {ChangeDetectorRef, Component, Input} from '@angular/core';
import {
  SpModalAudienceScoreInformationComponent
} from '../sp-modal-audience-score-information/sp-modal-audience-score-information.component';
import {MatDialog} from '@angular/material/dialog';
import {Audience} from '../../../../../models/audience';

@Component({
  // Ensures audienceScore & useNewIndicator are required fields
  // Using Angular "official way"
  selector: 'app-sp-audience-score[audienceTarget][useNewIndicator]',
  templateUrl: './sp-audience-score.component.html',
  styleUrls: ['./sp-audience-score.component.scss']
})
export class SpAudienceScoreComponent {
  protected readonly scoreConfig = new Map<string, {msg: string, containerClass: string, dotClass: string}>(
    [
      ['size_too_low', {msg: "Size says too low", containerClass: 'sp-audience-fb-score-too-low', dotClass: 'sp-audience-fb-score-dot-too-low'}],
      ['size_low', {msg: "Size says low", containerClass: 'sp-audience-fb-score-low', dotClass: 'sp-audience-fb-score-dot-low'}],
      ['size_good', {msg: "Size says it's good", containerClass: 'sp-audience-fb-score-good', dotClass: 'sp-audience-fb-score-dot-good'}],
      ['size_perfect', {msg: "Size says it's perfect", containerClass: 'sp-audience-fb-score-perfect', dotClass: 'sp-audience-fb-score-dot-perfect'}],
      ['size_high', {msg: "Size says high", containerClass: 'sp-audience-fb-score-high', dotClass: 'sp-audience-fb-score-dot-high'}],
      ['size_too_high', {msg: "Size says too high", containerClass: 'sp-audience-fb-score-too-high', dotClass: 'sp-audience-fb-score-dot-too-high'}]
    ]
  )

  @Input() useNewIndicator!: boolean;
  @Input() audienceTarget!: Audience

  constructor(private dialog: MatDialog) {}

  getAudienceScoreInformation() {
    this.dialog.open(SpModalAudienceScoreInformationComponent,{autoFocus: false,
      minHeight:'496px',
      width:'422px',
    });
  }

  get audienceScoreIndicator(): string {
    return this.audienceTarget.fb_score_v2;
  }

  get audienceScoreIndicatorLegacy(): number {
    return this.audienceTarget.fb_score;
  }
}
